const formatBytes = (bytes: any): string | null => {
  if (typeof bytes === 'string') {
    try {
      bytes = parseInt(bytes, 10);
    } catch {
      return null;
    }
    // eslint-disable-next-line no-empty
  } else if (typeof bytes === 'number') {
  } else {
    return null;
  }
  if (bytes === 0) return '0b';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(
    bytes / Math.pow(k, i),
  )} ${sizes[i]}`;
};

export default formatBytes;
