import * as React from 'react';
import { FC } from 'react';
import { Stack, styled, CircularProgress } from '@mui/material';

const Container = styled(Stack)(
  () => `
    flex: 1;
    background-color: #F8F8FA;
    align-items: center;
    justify-content: center;
`,
);
const CircularProgressStyled = styled(CircularProgress)<{ mode: string }>(
  ({ mode }) => `
    background-color: ${mode === 'dark' ? '#544E4E' : '#838A97'};
`,
);

interface LoadingProps {
  mode?: 'dark' | 'light';
}

const Loading: FC<LoadingProps> = ({ mode = 'dark' }) => {
  return (
    <Container>
      <CircularProgressStyled mode={mode} />
    </Container>
  );
};

export default Loading;
