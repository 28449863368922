import { print } from 'graphql';
import * as Url from 'url';
const SERVICE = 'appsync';
import { Signer } from 'aws-appsync-auth-link';

export const USER_AGENT_HEADER = 'x-amz-user-agent';
export const USER_AGENT = `aws-amplify/3.0.7 react-native`;

const removeTemporaryVariables = (variables: any) =>
  Object.keys(variables)
    .filter(key => !key.startsWith('@@'))
    .reduce((acc, key) => {
      // @ts-ignore
      acc[key] = variables[key];
      return acc;
    }, {});

// @ts-ignore
const formatAsRequest = ({ operationName, variables, query }, options) => {
  const body = {
    operationName,
    variables: removeTemporaryVariables(variables),
    query: print(query),
  };

  return {
    body: JSON.stringify(body),
    method: 'POST',
    ...options,
    headers: {
      accept: '*/*',
      'content-type': 'application/json; charset=UTF-8',
      ...options.headers,
    },
  };
};

export const getHeadersForIamAuth = async (
  // @ts-ignore
  { credentials, region, url },
  // @ts-ignore
  operation,
) => {
  const service = SERVICE;

  const creds =
    typeof credentials === 'function' ? credentials.call() : credentials || {};

  if (creds && typeof creds.getPromise === 'function') {
    await creds.getPromise();
  }

  const { accessKeyId, secretAccessKey, sessionToken } = await creds;

  const { host, path } = Url.parse(url);

  const formatted = {
    ...formatAsRequest(operation, {}),
    service,
    region,
    url,
    host,
    path,
  };

  const { headers } = Signer.sign(formatted, {
    access_key: accessKeyId,
    secret_key: secretAccessKey,
    session_token: sessionToken,
  });

  return {
    ...headers,
    [USER_AGENT_HEADER]: USER_AGENT,
  };
};
