export const COOKIE_KEY = {
  ACCEPTED_COOKIES: 'ACCEPTED_COOKIES',
  ONBOARDING_FIRSTAPPSTART: 'OnBoarding_FirstAppStart',
  ONBOARDING_NOTIFICATIONPERMISSION_REQUESTED:
    'OnBoarding_NotificationPermission_Requested',
  CACHEAPPVERSION: 'CacheAppVersion',
  SELECTEDPHONECOUNTRYCODE: 'SelectedPhoneCountryCode',
  BONUSMODAL_IDVERIFICATION_SHOWN: 'BonusModal_IdVerification_shown',
  COUNTBET: 'CountBet',
  HASLAUNCHEDSUCCESSFULLY: 'HasLaunchedSuccessfully',
  CHECKNOTIPOPUPSTATUS: 'CheckNotiPopupStatus',
  CACHELOCALIZE: 'CacheLocalize',

  ONBOARDINGFLOW_STEP: 'OnBoardingFlow_Step',
  ONBOARDINGFLOW_COMPLETED: 'OnBoardingFlow_Completed',
  AUTHENTICATION_LASTCONFIRMEDTIME: 'Authentication_LastConfirmedTime',
};
