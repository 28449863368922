export interface SignInParams {
  countryCode: string;
  phone: string;
  acceptTerms: boolean;
}

export interface SignInReauthParams {
  countryCode: string;
  phone: string;
  naturalPhoneNumber: string;
}

export interface SignUpParams {
  phone: string;
  countryCode: string;
  referralCode?: string;
  acceptTerms: boolean;
}

export const validateRegisterParams = (
  params: RegisterParams,
): params is Required<RegisterParams> => {
  return (
    !!params.firstName &&
    !!params.lastName &&
    !!params.dob &&
    !!params.email &&
    !!params.postalCode &&
    !!params.addressLine1 &&
    !!params.city
  );
};

export interface RegisterParams {
  referralCode?: string | undefined;
  firstName?: string;
  lastName?: string;
  nickName?: string;
  dob?: string;
  email?: string;
  countryCode?: string;
  postalCode?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  subscribeNewsletter: boolean;
  phoneNumber?: string;
  naturalPhoneNumber?: string;
}
