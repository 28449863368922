import * as React from 'react';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import {
  Maybe,
  MeFieldsFragment,
  TermAndConditionFieldsFragment,
  useGetMeV2LazyQuery,
  useGetTermAndConditionEnabledLazyQuery,
  UserCountryCodeIso1Alpha2,
} from 'graphql/__generated__/graphqlApi';
import isEmpty from 'lodash/isEmpty';
import { sessionExpireTimeChecks } from '../utils/datetime/realityChecksTimer';

interface ModalContextType {
  matchDetailId: string;
  setMatchDetailId: (matchDetailId: string) => void;
  matchLastBetId: string;
  setMatchLastBetId: (matchLastBetId: string) => void;
  flagInMatch: boolean;
  setFlagInMatch: (flagInMatch: boolean) => void;
  termAndCondition: TermAndConditionFieldsFragment | null;
  isTermAndConditionVisible: boolean;
  setIsTermAndConditionVisible: (isVisible: boolean) => void;
  isCodeVerified?: Maybe<boolean>;
  statusType?: string;
  refetchGetMeV2: () => void;
  setStatusLogin: (statusLogin: StatusLogin) => void;
  timerRealityChecks: number;
  isStartCount: boolean;
  setIsStartCount: (isStartCount: boolean) => void;
  setPauseCountTimeLimitation: (isStartCount: boolean) => void;
  onStartTimerRealityChecks: () => void;
  onLeaveMatchScreen: () => void;
  onResetDataRealityCheck: () => void;
  isSignInAgain: boolean;
  setIsSignInAgain: (isSignInAgain: boolean) => void;
  isVerifyVisible: boolean;
  setIsVerifyVisible: (isVerifyVisible: boolean) => void;
  isContinueVisible: boolean;
  setIsContinueVisible: (isContinueVisible: boolean) => void;
  isWinOrLoseVisible: boolean;
  setIsWinOrLoseVisible: (isWinOrLoseVisible: boolean) => void;
}

const ModalContext = createContext<ModalContextType>({
  matchDetailId: '',
  setMatchDetailId: noop,
  matchLastBetId: '',
  setMatchLastBetId: noop,
  flagInMatch: false,
  setFlagInMatch: noop,
  termAndCondition: null,
  isTermAndConditionVisible: false,
  setIsTermAndConditionVisible: noop,
  isCodeVerified: undefined,
  statusType: undefined,
  setStatusLogin: noop,
  refetchGetMeV2: noop,
  timerRealityChecks: 0,
  isStartCount: false,
  setIsStartCount: noop,
  setPauseCountTimeLimitation: noop,
  onStartTimerRealityChecks: noop,
  onLeaveMatchScreen: noop,
  onResetDataRealityCheck: noop,
  isSignInAgain: false,
  setIsSignInAgain: noop,
  isVerifyVisible: false,
  setIsVerifyVisible: noop,
  isContinueVisible: false,
  setIsContinueVisible: noop,
  isWinOrLoseVisible: false,
  setIsWinOrLoseVisible: noop,
});

export enum StatusLogin {
  NotLogin = 'NotLogin',
  Logged = 'Logged',
}

interface ModalContextProviderProps {
  children: React.ReactElement | React.ReactElement[];
}

let intervalId: NodeJS.Timeout | null = null;

const ModalContextProvider: FC<ModalContextProviderProps> = ({ children }) => {
  const [isTermAndConditionVisible, setIsTermAndConditionVisible] =
    useState(false);
  const [meData, setMeData] = useState<MeFieldsFragment | null>(null);
  const [termAndCondition, setTermAndCondition] =
    useState<TermAndConditionFieldsFragment | null>(null);
  const [statusType, setStatusType] = useState<string | undefined>();
  const [statusLogin, setStatusLogin] = useState<StatusLogin | null>(null);
  const timerRealityChecks = useRef<number>(0);
  const [isStartCount, setIsStartCount] = useState(false);
  const [pauseCountTimeLimitation, setPauseCountTimeLimitation] =
    useState(false);
  const [enableRealityCheck, setEnableRealityCheck] = useState<boolean>(false);
  const [flagInMatch, setFlagInMatch] = useState<boolean>(false);
  const [matchLastBetId, setMatchLastBetId] = useState<string>('');
  const [matchDetailId, setMatchDetailId] = useState<string>('');
  const timerOutMatchScreen = useRef<DateTime | null>(null);
  const [isVerifyVisible, setIsVerifyVisible] = useState(false);
  const [isContinueVisible, setIsContinueVisible] = useState(false);
  const [isSignInAgain, setIsSignInAgain] = useState<boolean>(false);
  const [isWinOrLoseVisible, setIsWinOrLoseVisible] = useState<boolean>(false);

  const handleResetDataRealityCheck = useCallback(() => {
    timerRealityChecks.current = 0;
    timerOutMatchScreen.current = null;
    setEnableRealityCheck(false);
    setIsStartCount(false);
    setPauseCountTimeLimitation(false);
    intervalId && clearInterval(intervalId);
  }, []);

  const handleStartTimerRealityChecks = useCallback(() => {
    setEnableRealityCheck(true);
    setIsStartCount(true);
    setPauseCountTimeLimitation(false);
    if (!timerOutMatchScreen.current) {
      return;
    }
    const isSessionExpired =
      DateTime.now().toSeconds() - timerOutMatchScreen.current.toSeconds() >=
      sessionExpireTimeChecks;
    if (isSessionExpired) {
      timerRealityChecks.current = 0;
    }
    timerOutMatchScreen.current = null;
  }, []);

  const handleLeaveMatchScreen = useCallback(() => {
    if (!timerOutMatchScreen.current) {
      timerOutMatchScreen.current = DateTime.now();
    }
    setIsStartCount(false);
    setPauseCountTimeLimitation(true);
    intervalId && clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!enableRealityCheck || !isStartCount || pauseCountTimeLimitation) {
      return;
    }
    intervalId = setInterval(() => {
      timerRealityChecks.current += 1;
    }, 1000);
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [enableRealityCheck, isStartCount, pauseCountTimeLimitation]);

  const [getMeV2] = useGetMeV2LazyQuery({
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  });
  const [getTermAndConditionEnabled] = useGetTermAndConditionEnabledLazyQuery();

  const refetchGetMeV2 = useCallback(async () => {
    const dataMeV2Response = await getMeV2();
    setMeData(dataMeV2Response.data?.meV2 ?? null);
  }, [getMeV2]);

  const handleReset = useCallback(() => {
    setIsTermAndConditionVisible(false);
    setMeData(null);
    setTermAndCondition(null);
    setStatusType(undefined);
  }, []);

  useEffect(() => {
    if (statusLogin === StatusLogin.NotLogin) {
      handleReset();
      return;
    }
    (async () => {
      const dataMeV2 = await getMeV2();
      const dataTermAndConditionEnabled = await getTermAndConditionEnabled({
        variables: {
          params: {
            country:
              dataMeV2.data?.meV2?.country ?? UserCountryCodeIso1Alpha2.Gb,
          },
        },
      });
      setMeData(dataMeV2.data?.meV2 ?? null);
      setTermAndCondition(
        dataTermAndConditionEnabled.data?.getTermAndConditionEnabled
          ?.termAndCondition ?? null,
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusLogin]);

  useEffect(() => {
    if (!meData?.agreedTermsAndConditions || isEmpty(termAndCondition)) {
      setIsTermAndConditionVisible(false);
      return;
    }
    const isAgreed = meData.agreedTermsAndConditions.some(
      item => item === termAndCondition?.id,
    );
    setIsTermAndConditionVisible(!isAgreed);
  }, [meData?.agreedTermsAndConditions, termAndCondition]);

  const value = useMemo(() => {
    return {
      matchLastBetId,
      setMatchLastBetId,
      matchDetailId,
      setMatchDetailId,
      flagInMatch,
      setFlagInMatch,
      termAndCondition,
      isTermAndConditionVisible,
      setIsTermAndConditionVisible,
      statusType,
      refetchGetMeV2,
      setStatusLogin,
      timerRealityChecks: timerRealityChecks.current,
      isStartCount,
      setIsStartCount,
      setPauseCountTimeLimitation,
      onStartTimerRealityChecks: handleStartTimerRealityChecks,
      onLeaveMatchScreen: handleLeaveMatchScreen,
      onResetDataRealityCheck: handleResetDataRealityCheck,
      isSignInAgain,
      setIsSignInAgain,
      isVerifyVisible,
      setIsVerifyVisible,
      isContinueVisible,
      setIsContinueVisible,
      isWinOrLoseVisible,
      setIsWinOrLoseVisible,
    };
  }, [
    matchLastBetId,
    matchDetailId,
    flagInMatch,
    termAndCondition,
    isTermAndConditionVisible,
    statusType,
    refetchGetMeV2,
    isStartCount,
    handleStartTimerRealityChecks,
    handleLeaveMatchScreen,
    handleResetDataRealityCheck,
    isSignInAgain,
    isVerifyVisible,
    isContinueVisible,
    isWinOrLoseVisible,
  ]);

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);

export default ModalContextProvider;
