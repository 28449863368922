import * as React from 'react';
import { FC, ReactElement, useCallback, useContext, useState } from 'react';
import I18nInstance from '../../i18nConfig';
import { I18NextLanguageCode, LangType, LanguageCode } from '../../types/lang';
import { useTranslation } from 'react-i18next';
import noop from '../noopAsync';
import { TOptions } from 'i18next';
import AsyncStorageManager from '../../services/AsyncStorageManager';

export interface ApplicableLocalizedStrings {
  en_GB: string | null;
}

export type Translator = (
  localizedStrings: ApplicableLocalizedStrings,
) => string;

export type LangValue = {
  lang: LangType;
  localizedStringsKey: keyof ApplicableLocalizedStrings;
  languageCode: LanguageCode;
  languageAreaCode: LangType;
  i18NextLanguageCode: I18NextLanguageCode;
};

export interface LangContext {
  langValue: LangValue;
  changeLanguage: (langValue: LangValue, isNotSaveStorage?: boolean) => void;
}

const enLang: LangValue = {
  lang: LangType.en_GB,
  localizedStringsKey: LangType.en_GB,
  languageCode: LanguageCode.en,
  languageAreaCode: LangType.en_DEFAULT,
  i18NextLanguageCode: I18NextLanguageCode.en_GB,
};

export type TCustomFunction = (key: string, options?: TOptions) => string;

const Context = React.createContext<LangContext>({
  langValue: enLang,
  changeLanguage: noop,
});

const Provider: FC<{ children: ReactElement }> = ({ children }) => {
  const [value, setValue] = useState<LangValue>(enLang);

  const handleChangeLanguage = useCallback(
    async (langValue: LangValue, isNotSaveStorage?: boolean) => {
      if (!isNotSaveStorage) {
        await AsyncStorageManager.setItem(
          'CacheLocalize',
          JSON.stringify(langValue),
        );
      }
      setValue(langValue);
      I18nInstance.changeLanguage(langValue.i18NextLanguageCode).catch(e =>
        console.error('ChangeLanguage error', e),
      );
    },
    [],
  );

  return (
    <Context.Provider
      value={{ langValue: value, changeLanguage: handleChangeLanguage }}
    >
      {children}
    </Context.Provider>
  );
};

export const useLang = () => {
  const langContext = useContext(Context);
  const {
    lang,
    languageCode,
    i18NextLanguageCode,
    languageAreaCode,
    localizedStringsKey,
  } = langContext.langValue;
  const { t } = useTranslation('Common');
  const localizedStringTranslator = (
    localizedStrings: ApplicableLocalizedStrings | null,
  ) => {
    if (!localizedStrings) return '';
    if (!localizedStrings[localizedStringsKey]) {
      return localizedStrings[LangType.en_GB] ?? '';
    }
    return localizedStrings[localizedStringsKey] ?? '';
  };
  const customT = useCallback(
    (key: string, options?: TOptions) => {
      if (t(key, options) === '-') {
        return '';
      }
      return t(key, options);
    },
    [t],
  );

  return {
    t: customT,
    isHideComponent:
      languageAreaCode !== LangType.en_DEFAULT &&
      languageAreaCode !== LangType.en_GB,
    translate: localizedStringTranslator,
    lang,
    languageCode,
    i18NextLanguageCode,
    languageAreaCode,
    localizedStringsKey,
    changeLanguage: langContext.changeLanguage,
  };
};

export default { Provider };
