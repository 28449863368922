import * as React from 'react';
import { FC, useState } from 'react';
import noop from '../utils/noopAsync';

type TitleContextType = {
  title: string;
  setTitle: (title: string) => void;
};

const TitleContext = React.createContext<TitleContextType>({
  title: '',
  setTitle: noop,
});
export const useTitle = () => React.useContext(TitleContext);

interface TitleProviderProps {
  children: React.ReactElement | React.ReactElement[];
}

const TitleProvider: FC<TitleProviderProps> = ({ children }) => {
  const [title, setTitle] = useState('');

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  );
};
export default TitleProvider;
