import * as React from 'react';
import { createContext, FC, useContext, useEffect, useState } from 'react';

interface AppActivatedType {
  isAppActivated: boolean;
}

const AppActivated = createContext<AppActivatedType>({ isAppActivated: false });

interface AppActivatedProviderProps {
  children: React.ReactElement | React.ReactElement[];
}

const AppActivatedProvider: FC<AppActivatedProviderProps> = ({ children }) => {
  const [isAppActivated, setIsAppActivated] = useState(false);
  useEffect(() => {
    window.onfocus = () => {
      setIsAppActivated(true);
    };
    window.onblur = () => {
      setIsAppActivated(false);
    };
  }, []);
  return (
    <AppActivated.Provider value={{ isAppActivated: isAppActivated }}>
      {children}
    </AppActivated.Provider>
  );
};

export const useAppActivated = () => useContext(AppActivated);

export default AppActivatedProvider;
