import { WatchQueryFetchPolicy } from '@apollo/client';
import {
  BonusConfig,
  useGetGlobalSettingLazyQuery,
  UserCountryCodeIso1Alpha2,
} from '../../__generated__/graphqlApi';
import { useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import Config from 'config/getEnvConfig';
import { RegisterParams } from '../../../types/formParams';
import { countryCode, getAvailableCountries } from '../../../utils/signup';

export type TGlobalSetting = {
  maxBetUU: number;
  maxDeposit: number;
  minDeposit: number;
  maxWithDraw: number;
  minWithDraw: number;
  maxBetLimitPerBet: number;
  minimumBetWager: number;
  maxBetLimitPerMarket: number;
  isDataEmpty: boolean;
  latestVersion: string;
  bonusConfig: BonusConfig | null;
};

const useGetGlobalSettingLazyOperation = (params?: {
  fetchPolicy?: WatchQueryFetchPolicy;
  country?: UserCountryCodeIso1Alpha2 | null;
}): TGlobalSetting => {
  const { fetchPolicy = 'cache-and-network', country } = params ?? {};

  const [getGlobalSettingLazy, { data: dataLazy }] =
    useGetGlobalSettingLazyQuery();

  useEffect(() => {
    const registerParams = sessionStorage.getItem('Register_Params');
    const registerParamsParse: RegisterParams | null = registerParams
      ? JSON.parse(registerParams)
      : null;
    const countryRegistered = getAvailableCountries(
      registerParamsParse?.countryCode ?? countryCode.UnitedKingdom,
    );
    getGlobalSettingLazy({
      fetchPolicy,
      variables: {
        params: {
          os: 'web',
          appConfiguration: {
            appBuildConfiguration: Config.appBuildConfiguration,
            bundleId: Config.applicationId,
          },
          version: null,
          country: country ?? countryRegistered,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, fetchPolicy]);

  return useMemo(() => {
    return {
      maxBetUU: dataLazy?.globalSettings?.maxBetUU ?? 0,
      maxDeposit: dataLazy?.globalSettings?.maxDeposit ?? 0,
      minDeposit: dataLazy?.globalSettings?.minDeposit ?? 0,
      maxWithDraw: dataLazy?.globalSettings?.maxWithDraw ?? 0,
      minWithDraw: dataLazy?.globalSettings?.minWithDraw ?? 0,
      maxBetLimitPerBet: dataLazy?.globalSettings?.maxBetLimitPerBet ?? 0,
      minimumBetWager: dataLazy?.globalSettings?.minimumBetWager ?? 0,
      maxBetLimitPerMarket: dataLazy?.globalSettings?.maxBetLimitPerMarket ?? 0,
      isDataEmpty: isEmpty(dataLazy?.globalSettings),
      latestVersion: dataLazy?.globalSettings?.latestVersion ?? '',
      bonusConfig: dataLazy?.globalSettings?.bonusConfig ?? null,
    };
  }, [dataLazy?.globalSettings]);
};

export default useGetGlobalSettingLazyOperation;
