import React, { FC } from 'react';
import { ButtonSecondary, PurpleButton } from '../../atoms/buttons/Button';
import { useLang } from '../../../utils/context/LangContext';
import { Dialog, Typography, useTheme, Stack, styled } from '@mui/material';

const Message = styled(Typography)`
  text-align: center;
  line-height: 20px;
`;
const Title = styled(Typography)`
  text-align: center;
  line-height: 26px;
  margin-vertical: 8px;
`;
const ButtonGround = styled(Stack)`
  flex-direction: row;
  margin-top: 32px;
`;
const DialogStyled = styled(Dialog)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  '& .MuiDialog-paper': {
    maxWidth: '400px',
    borderRadius: 16,
    padding: 24,
  },
}));

interface AcceptVersionModalProps {
  title?: string;
  message?: string;
  isVisible: boolean;
  onReview?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onAccept?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const AcceptVersionModal: FC<AcceptVersionModalProps> = ({
  title,
  message,
  isVisible,
  onReview,
  onAccept,
}) => {
  const { t } = useLang();
  const theme = useTheme();
  return (
    <DialogStyled fullWidth={true} open={isVisible}>
      <Title variant={'h5'} fontWeight={'bold'}>
        {title}
      </Title>
      <Message color={theme.colors.graphite.base}>{message}</Message>
      <ButtonGround>
        {onReview && (
          <ButtonSecondary
            borderColor={theme.colors.gray.darkness3}
            bordered
            corner={'largeRounded'}
            height={48}
            onPress={onReview}
          >
            {t('acceptVersionButtonReview')}
          </ButtonSecondary>
        )}
        {onAccept && onReview && <Stack sx={{ width: 9, height: 9 }} />}
        {onAccept && (
          <PurpleButton corner={'largeRounded'} height={48} onPress={onAccept}>
            {t('acceptVersionButtonAccept')}
          </PurpleButton>
        )}
      </ButtonGround>
    </DialogStyled>
  );
};

export default AcceptVersionModal;
