import { SeparatorType } from '../types/separator';

export const separator = {
  decimalSeparator: '.',
  groupingSeparator: ',',
};

const formatPond = (num: number, withoutFraction = false): string => {
  if (num === 0) {
    return withoutFraction ? '0' : `0${separator.decimalSeparator}00`;
  }
  const formattedNumber = Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: withoutFraction ? 0 : 2,
  }).format(Math.ceil(num * 100) / 10000);

  if (
    separator.groupingSeparator === SeparatorType.Dot &&
    separator.decimalSeparator === SeparatorType.Comma
  ) {
    return formattedNumber
      .replace(/,/g, 'temp')
      .replace(/\./g, SeparatorType.Comma)
      .replace(/temp/g, SeparatorType.Dot);
  }
  if (
    (separator.groupingSeparator === SeparatorType.Space ||
      separator.groupingSeparator === SeparatorType.NonBreakingSpace) &&
    separator.decimalSeparator === SeparatorType.Comma
  ) {
    return formattedNumber
      .replace(/,/g, separator.groupingSeparator)
      .replace(/\./g, separator.decimalSeparator);
  }
  return formattedNumber;
};

export default formatPond;
