import React, { FC, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from '@mui/material';
import { useLang } from 'utils/context/LangContext';

const DialogStyled = styled(Dialog)(
  ({ theme }) => `
  z-index: ${theme.zIndex.modal};
`,
);

interface AlertDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string | undefined;
  labelCancel?: string;
  labelAgree?: string;
  onCancel?: () => void;
  onAgree?: () => void;
  hideAction?: boolean;
}
const AlertDialog: FC<AlertDialogProps> = ({
  isOpen,
  onClose,
  title,
  message,
  labelCancel,
  labelAgree,
  onCancel,
  onAgree,
  hideAction,
}) => {
  const { t } = useLang();
  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    onClose();
  }, [onCancel, onClose]);
  const handleAgree = useCallback(() => {
    if (onAgree) {
      onAgree();
    }
    onClose();
  }, [onAgree, onClose]);

  return (
    <DialogStyled
      open={isOpen}
      fullWidth={true}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {message || ''}
        </DialogContentText>
      </DialogContent>
      {!hideAction && (
        <DialogActions>
          {onCancel && (
            <Button size={'large'} color={'error'} onClick={handleCancel}>
              {labelCancel ?? t('alertButtonsCancel')}
            </Button>
          )}
          <Button size={'large'} onClick={handleAgree}>
            {labelAgree ?? t('buttonOk')}
          </Button>
        </DialogActions>
      )}
    </DialogStyled>
  );
};
export default AlertDialog;
