import { ApolloError } from '@apollo/client';
import { extractJungleAppError, JungleAppErrorCode } from './JungleAppError';

const createLogger = () => {
  return {
    error: (
      error: ApolloError | Error | string,
      optionalParams?: Record<string, any>,
    ) => {
      if (
        extractJungleAppError(error)?.errorType ===
        JungleAppErrorCode.API__AUTH__UNAUTHENTICATED
      ) {
        console.info('[LOGGER]', 'unauthenticated');
        return;
      }
      console.error(error, optionalParams, 'error');
    },
    warn: (
      error: ApolloError | Error | string,
      optionalParams?: { [key: string]: any },
    ) => {
      if (
        extractJungleAppError(error)?.errorType ===
        JungleAppErrorCode.API__AUTH__UNAUTHENTICATED
      ) {
        console.info('[LOGGER]', 'unauthenticated');
        return;
      }
      console.warn('[LOGGER]', error, optionalParams);
    },
  };
};

const Logger = createLogger();

export default Logger;
