import * as React from 'react';
import { createContext, FC, useCallback, useContext, useState } from 'react';
import noop from '../utils/noopAsync';
import AlertDialog from '../components/atoms/common/AlertDialog';

interface AlertOption {
  hideAction?: boolean;
  labelCancel?: string;
  labelAgree?: string;
  onCancel?: () => void;
  onAgree?: () => void;
}

interface AlertProps {
  title: string;
  message: string;
  options?: AlertOption;
}

interface AlertContextType {
  show: (title: string, message: string, options?: AlertOption) => void;
}

const AlertContext = createContext<AlertContextType>({ show: noop });

interface AlertContextProviderProps {
  children: React.ReactElement | React.ReactElement[];
}

const AlertContextProvider: FC<AlertContextProviderProps> = ({ children }) => {
  const [alert, setAlert] = useState<AlertProps | null>(null);

  const handleClose = useCallback(() => {
    setAlert(null);
  }, []);

  const handleShow = useCallback(
    (title: string, message: string, options?: AlertOption) => {
      const params = {
        title,
        message,
        options,
      };
      setAlert(params);
    },
    [],
  );

  return (
    <AlertContext.Provider value={{ show: handleShow }}>
      {children}
      <AlertDialog
        isOpen={!!alert}
        onClose={handleClose}
        title={alert?.title ?? ''}
        message={alert?.message ?? ''}
        labelCancel={alert?.options?.labelCancel}
        labelAgree={alert?.options?.labelAgree}
        onCancel={alert?.options?.onCancel && alert.options.onCancel}
        onAgree={() => {
          alert?.options?.onAgree?.();
        }}
        hideAction={alert?.options?.hideAction}
      />
    </AlertContext.Provider>
  );
};

export const useAlertContext = () => useContext(AlertContext);

export default AlertContextProvider;
