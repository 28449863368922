export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    MatchSummary: ['BasketBallMatchSummary', 'FootballMatchSummary'],
    Profile: [
      'LeagueProfile',
      'ManagerProfile',
      'PlayerProfile',
      'TeamProfile',
    ],
    StreamingContent: ['LiveStreamingContent'],
    WalletHistory: ['BalanceWalletHistory', 'MatchWalletHistory'],
  },
};
export default result;
