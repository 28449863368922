import { useApolloClient } from '@apollo/client';
import cognitoSignOut from 'api/cognito/cognitoSignOut';
import Logger from 'utils/core/Logger';
import { useCallback } from 'react';
import cognitoUserPool from 'api/cognito/cognitoUserPool';
import useGetMeOperation from 'graphql/operations/queries/useGetMeOperation';
import { useIntercom } from 'react-use-intercom';
import { RouterPathName } from 'containers/Router';
import { useCookies } from 'react-cookie';
import { COOKIE_KEY } from 'constants/Cookie';
import AsyncStorageManager from '../services/AsyncStorageManager';

const useSignOut = () => {
  const apolloClient = useApolloClient();

  return useCallback(
    (isSignupRegister?: boolean) => {
      if (!isSignupRegister) {
        localStorage.removeItem(COOKIE_KEY.ONBOARDING_FIRSTAPPSTART);
        cognitoSignOut().catch(e => Logger.error(e));
      }
      apolloClient.resetStore().catch(e => Logger.error(e));
    },
    [apolloClient],
  );
};

export const useSignOutAsync = () => {
  const { shutdown } = useIntercom();
  const apolloClient = useApolloClient();
  const { refreshMe } = useGetMeOperation();

  return useCallback(
    async (isCloseAccount?: boolean) => {
      localStorage.removeItem(COOKIE_KEY.ONBOARDING_FIRSTAPPSTART);
      localStorage.removeItem(COOKIE_KEY.AUTHENTICATION_LASTCONFIRMEDTIME);
      sessionStorage.removeItem('Register_Params');
      sessionStorage.removeItem('Unauthenticated_LastConfirmedTime');
      await AsyncStorageManager.removeItem('UserInfo');
      await AsyncStorageManager.removeItem('Verification');
      await apolloClient.resetStore().catch(e => Logger.error(e));
      await cognitoSignOut();
      await refreshMe();
      if (!isCloseAccount) {
        await shutdown();
      }
      window.location.replace(RouterPathName.signUp);
    },
    [apolloClient, refreshMe, shutdown],
  );
};

export const useReauthAsync = () => {
  const { shutdown } = useIntercom();
  const apolloClient = useApolloClient();
  const { refreshMe } = useGetMeOperation();

  return useCallback(async () => {
    await cognitoSignOut();
    await refreshMe();
    await shutdown();
    await AsyncStorageManager.removeItem('Verification');
    await apolloClient.resetStore().catch(e => Logger.error(e));
  }, [apolloClient, refreshMe, shutdown]);
};

export const useIsSignedIn = () => {
  const [cookies] = useCookies();
  return useCallback(async () => {
    return (
      cognitoUserPool.getCurrentUser() != null &&
      cookies?.[COOKIE_KEY.ONBOARDING_FIRSTAPPSTART]
    );
  }, [cookies]);
};

export default useSignOut;
