import {
  CognitoUserPool,
  ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';
import Config from 'config/getEnvConfig';

export const dummyPassword = 'P@ssw0rd';

const poolData: ICognitoUserPoolData = {
  UserPoolId: Config.aws.cognito.userPoolId,
  ClientId: Config.aws.cognito.userPoolWebClientId,
};

const userPool = new CognitoUserPool(poolData);

export default userPool;
