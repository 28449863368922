export enum LangType {
  en_DEFAULT = 'en-DEFAULT',
  en_GB = 'en_GB',
  en_IE = 'en_IE',
  ja_JP = 'ja_JP',
  en_US = 'en_US',
}
export enum LanguageCode {
  en = 'en',
  ja = 'ja',
}
export enum I18NextLanguageCode {
  en_DEFAULT = 'en-DEFAULT',
  en_GB = 'en-GB',
  en_IE = 'en-IE',
  ja_JP = 'ja-JP',
}
