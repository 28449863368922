import { initializeApp } from 'firebase/app';
import { getAnalytics } from '@firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyD3m8FP1fJDhxqK0LAmhP6YiODLRh76FRU',
  authDomain: 'jungle-bet-app-dev-3.firebaseapp.com',
  projectId: 'jungle-bet-app-dev-3',
  storageBucket: 'jungle-bet-app-dev-3.appspot.com',
  messagingSenderId: '729122957611',
  appId: '1:729122957611:web:4229bc59719873ddcc7d6e',
  measurementId: 'G-98LTFL8FFS',
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
