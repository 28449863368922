import * as React from 'react';
import { FC, useMemo, useState } from 'react';
import noop from '../utils/noopAsync';
import { CognitoUser } from 'amazon-cognito-identity-js';

type CognitoUserContextType = {
  cognitoUser: CognitoUser | null;
  setCognitoUser: (cognitoUser: CognitoUser) => void;
  isSignUp: boolean;
  setIsSignUp: (isSignUp: boolean) => void;
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  naturalPhoneNumber: string;
  setNaturalPhoneNumber: (naturalPhoneNumber: string) => void;
  countryCode: string;
  setCountryCode: (countryCode: string) => void;
  mfaRequired: boolean;
  setMfaRequired: (mfaRequired: boolean) => void;
};

const CognitoUserContext = React.createContext<CognitoUserContextType>({
  cognitoUser: null,
  setCognitoUser: noop,
  isSignUp: false,
  setIsSignUp: noop,
  phoneNumber: '',
  setPhoneNumber: noop,
  naturalPhoneNumber: '',
  setNaturalPhoneNumber: noop,
  countryCode: '',
  setCountryCode: noop,
  mfaRequired: false,
  setMfaRequired: noop,
});
export const useCognitoUserContext = () => React.useContext(CognitoUserContext);

interface CognitoUserProviderProps {
  children: React.ReactElement | React.ReactElement[];
}

const CognitoUserProvider: FC<CognitoUserProviderProps> = ({ children }) => {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [naturalPhoneNumber, setNaturalPhoneNumber] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');
  const [mfaRequired, setMfaRequired] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      cognitoUser,
      setCognitoUser,
      isSignUp,
      setIsSignUp,
      phoneNumber,
      setPhoneNumber,
      naturalPhoneNumber,
      setNaturalPhoneNumber,
      countryCode,
      setCountryCode,
      mfaRequired,
      setMfaRequired,
    }),
    [
      cognitoUser,
      countryCode,
      isSignUp,
      mfaRequired,
      naturalPhoneNumber,
      phoneNumber,
    ],
  );

  return (
    <CognitoUserContext.Provider value={value}>
      {children}
    </CognitoUserContext.Provider>
  );
};
export default CognitoUserProvider;
