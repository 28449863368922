import { useAcceptTermAndConditionMutation } from '../../__generated__/graphqlApi';
import { useCallback } from 'react';

const useAcceptTermAndConditionOperation = () => {
  const [mutate] = useAcceptTermAndConditionMutation();

  return useCallback(
    async (termAndConditionId: string): Promise<void> => {
      await mutate({ variables: { input: { termAndConditionId } } });
    },
    [mutate],
  );
};

export default useAcceptTermAndConditionOperation;
