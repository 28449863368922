import { MeFieldsFragment } from '../../graphql/__generated__/graphqlApi';
import useGetMeOperation from '../../graphql/operations/queries/useGetMeOperation';

function useSelectMeProp<Key extends keyof MeFieldsFragment>(
  key: Key,
): MeFieldsFragment[Key] | undefined {
  const { me } = useGetMeOperation();
  return me ? me[key] : undefined;
}

export default useSelectMeProp;
