import { useCallback, useEffect } from 'react';
import useGetGlobalSettingOperation from '../graphql/operations/queries/useGetGlobalSettingOperation';
import AsyncStorageManager from '../services/AsyncStorageManager';
import { WEB_VERSION } from '../constants/WebVersion';

const pollInterval = 60000;

export const useClearCacheAndReload = () => {
  const {
    latestVersion,
    polling: { startPolling, stopPolling },
  } = useGetGlobalSettingOperation({ pollInterval });
  useEffect(() => {
    startPolling(pollInterval);
    return () => {
      stopPolling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVersion = useCallback(async () => {
    if (!latestVersion) return;
    const appVersionStorage = await AsyncStorageManager.getItem('Web_Version');
    const isVisitWeb = await AsyncStorageManager.getItem('isVisitWeb');
    const appVersion = appVersionStorage ?? WEB_VERSION;

    if (
      isVisitWeb !== '1' ||
      !appVersionStorage ||
      !compareVersions(latestVersion, appVersion)
    ) {
      return await AsyncStorageManager.setItem('isVisitWeb', '1');
    }
    await AsyncStorageManager.setItem('Web_Version', latestVersion);
    if (caches) {
      caches.keys().then(names => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    window.location.reload();
  }, [latestVersion]);

  useEffect(() => {
    getVersion();
  }, [getVersion]);

  const compareVersions = (versionA: string, versionB: string) => {
    const splitA = versionA.split('.').map(Number);
    const splitB = versionB.split('.').map(Number);
    const maxLength = Math.max(splitA.length, splitB.length);

    for (let i = 0; i < maxLength; i++) {
      const numA = i < splitA.length ? splitA[i] : 0;
      const numB = i < splitB.length ? splitB[i] : 0;

      if (numA > numB) return true;
      if (numA < numB) return false;
    }

    return false;
  };

  return {};
};
