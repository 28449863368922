import * as React from 'react';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import noop from '../utils/noopAsync';

type BetDetails = {
  marketId: string;
  matchId: string;
  betAmount: number;
  odds: number;
  optionId: string;
  isCash: boolean;
  minBet: number;
};

interface TrialMatchContextType {
  betTrial: string[] | null;
  showResultTrial?: boolean;
  cashTrialFromContext: number;
  cashTrialFromContextCurrent: number;
  betParamsTrial: BetDetails | null;
  setShowResultTrial: Dispatch<SetStateAction<boolean>>;
  setBetTrial: Dispatch<SetStateAction<string[] | null>>;
  setCashTrialFromContext: Dispatch<SetStateAction<number>>;
  setCashTrialFromContextCurrent: Dispatch<SetStateAction<number>>;
  setBetParamsTrial: Dispatch<SetStateAction<BetDetails | null>>;
  resetState: () => void;
}

const TrialMatchContext = createContext<TrialMatchContextType>({
  betTrial: null,
  betParamsTrial: null,
  showResultTrial: false,
  cashTrialFromContext: 10000,
  cashTrialFromContextCurrent: 10000,
  setBetTrial: noop,
  setBetParamsTrial: noop,
  setShowResultTrial: noop,
  setCashTrialFromContext: noop,
  setCashTrialFromContextCurrent: noop,
  resetState: noop,
});

interface TrialMatchContextProviderProps {
  children: React.ReactElement | React.ReactElement[];
}

const TrialMatchProvider: FC<TrialMatchContextProviderProps> = ({
  children,
}) => {
  const [showResultTrial, setShowResultTrial] = useState(false);
  const [betTrial, setBetTrial] = useState<string[] | null>(null);
  const [betParamsTrial, setBetParamsTrial] = useState<BetDetails | null>(null);
  const [cashTrialFromContext, setCashTrialFromContext] =
    useState<number>(10000);
  const [cashTrialFromContextCurrent, setCashTrialFromContextCurrent] =
    useState<number>(10000);

  const resetState = useCallback(() => {
    setShowResultTrial(false);
    setBetTrial(null);
    setBetParamsTrial(null);
    setCashTrialFromContext(10000);
    setCashTrialFromContextCurrent(10000);
  }, []);

  return (
    <TrialMatchContext.Provider
      value={{
        betTrial,
        betParamsTrial,
        showResultTrial,
        cashTrialFromContext,
        cashTrialFromContextCurrent,
        setBetTrial,
        setBetParamsTrial,
        setShowResultTrial,
        setCashTrialFromContext,
        setCashTrialFromContextCurrent,
        resetState,
      }}
    >
      {children}
    </TrialMatchContext.Provider>
  );
};

export const useTrialMatchContext = () => useContext(TrialMatchContext);

export default TrialMatchProvider;
