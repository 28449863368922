import { FieldMergeFunction } from '@apollo/client';
import { GetStreamingContentChatMessagesOutput } from '../../__generated__/graphqlApi';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

const getStreamingContentChatMessagesMergeFunction: FieldMergeFunction<
  GetStreamingContentChatMessagesOutput,
  GetStreamingContentChatMessagesOutput
> = (existing, incoming, { readField, args }) => {
  const sinceKey = get(args, ['params', 'sinceKey']);
  const untilKey = get(args, ['params', 'untilKey']);
  if (sinceKey == null && untilKey == null) {
    return { ...incoming, nextSinceKey: null, nextUntilKey: null };
  }
  if (incoming.messages.length > 0) {
    const combinedList =
      sinceKey != null
        ? [...(existing?.messages || []), ...incoming.messages]
        : [...incoming.messages, ...(existing?.messages || [])];
    const messages = uniqBy(combinedList, m => readField('id', m));
    return {
      __typename: 'GetStreamingContentChatMessagesOutput',
      messages,
      nextSinceKey: null,
      nextUntilKey: null,
    };
  }
  return existing
    ? {
        ...existing,
        nextSinceKey: null,
        nextUntilKey: null,
      }
    : {
        __typename: 'GetStreamingContentChatMessagesOutput',
        messages: [],
        nextSinceKey: null,
        nextUntilKey: null,
      };
};

export default getStreamingContentChatMessagesMergeFunction;
