import { useCallback, useEffect } from 'react';
import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import {
  extractJungleAppError,
  JungleAppErrorCode,
} from 'utils/core/JungleAppError';
import cognitoUserPool from 'api/cognito/cognitoUserPool';
import {
  GetMeV2Query,
  IdVerificationStatus,
  useGetMeAsStreamerQuery,
  useGetMeV2Query,
  useGetMyGamstopStatusQuery,
  useGetMySettingsQuery,
  useGetMyVerificationStatusV2Query,
} from '../../__generated__/graphqlApi';
import useSignOut from 'hooks/useSignOut';
import { hasValidUnAuth } from '../../../components/molecules/profile/top/WelcomeBanner';

const debugLoggingOnError = (error: any) => {
  console.warn(JSON.stringify(error));
};

const useSignOutAndResetCacheOnApolloError = () => {
  const signOut = useSignOut();
  const securedUnAuth = hasValidUnAuth();
  return useCallback(
    (error: ApolloError) => {
      const apiError = extractJungleAppError(error);
      const registerParamRes = sessionStorage.getItem('Register_Params');
      if (
        apiError?.errorType === JungleAppErrorCode.API__AUTH__UNAUTHENTICATED ||
        apiError?.errorType === 'Unauthorized'
      ) {
        if (cognitoUserPool.getCurrentUser()?.getSignInUserSession()) {
          signOut(!!(registerParamRes && securedUnAuth));
        }
        return;
      }
      if (error.networkError) {
        console.error('Network error', error);
        // toasted.show(networkError.message);
      }
      if (error.graphQLErrors) {
        console.error('GraphQL error', error.graphQLErrors);
        // for (let err of graphQLErrors) {
        //   toasted.show(err.message);
        // }
      }
    },
    [securedUnAuth, signOut],
  );
};

const useGetMeOperation = (options?: {
  fetchPolicy?: WatchQueryFetchPolicy;
}) => {
  const { fetchPolicy = 'cache-first' } = options ?? {};
  const signOutOnError = useSignOutAndResetCacheOnApolloError();

  const {
    data: meData,
    refetch: refetchMe,
    error: meError,
    loading,
  } = useGetMeV2Query({
    fetchPolicy,
    errorPolicy: 'ignore',
    onError: signOutOnError,
  });
  const pollInterval = 15000;
  const {
    data: verificationStatusData,
    refetch: refetchVerificationStatus,
    startPolling,
    stopPolling,
  } = useGetMyVerificationStatusV2Query({
    fetchPolicy,
    skip: meData?.meV2 == null,
    errorPolicy: 'ignore',
    pollInterval,
    onError: debugLoggingOnError,
  });

  const { data: meAsStreamerData } = useGetMeAsStreamerQuery({
    fetchPolicy,
    skip: meData?.meV2 == null,
    errorPolicy: 'ignore',
    onError: debugLoggingOnError,
  });

  const { data: mySettingsData, refetch: refetchMySettings } =
    useGetMySettingsQuery({
      fetchPolicy,
      skip: meData?.meV2 == null,
      errorPolicy: 'ignore',
      onError: debugLoggingOnError,
    });

  const { data: myGamstopStatus } = useGetMyGamstopStatusQuery({
    fetchPolicy,
    skip: meData?.meV2 == null,
    errorPolicy: 'ignore',
    onError: debugLoggingOnError,
  });

  useEffect(() => {
    startPolling(pollInterval);
    if (
      verificationStatusData?.myIdVerificationStatusV2?.status !==
      IdVerificationStatus.Waiting
    ) {
      stopPolling();
    }
    return () => {
      stopPolling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationStatusData?.myIdVerificationStatusV2?.status]);

  return {
    loading,
    me: meError != null ? null : meData?.meV2 ?? null,
    verificationStatus:
      meData?.meV2 == null
        ? null
        : verificationStatusData?.myIdVerificationStatusV2 ?? null,
    mySettings: !meData?.meV2 ? null : mySettingsData?.mySettings ?? null,
    isMonoChrome: !meData?.meV2?.postInteractionLevel
      ? false
      : meData.meV2.postInteractionLevel > 3,
    isHideHeroSection: !meData?.meV2?.postInteractionLevel
      ? false
      : meData?.meV2?.postInteractionLevel >= 3,
    gamstopStatus: !meData?.meV2
      ? null
      : myGamstopStatus?.myGamstopStatus ?? null,
    meAsStreamer: !meData?.meV2 ? null : meAsStreamerData?.meAsStreamer ?? null,
    refreshMe: useCallback(async (): Promise<{
      data: GetMeV2Query | undefined;
    }> => {
      const fetchedData = await refetchMe().catch(e => {
        signOutOnError(e);
        return null;
      });
      if (fetchedData?.data.meV2) {
        await refetchVerificationStatus();
      }
      return { data: fetchedData?.data };
    }, [signOutOnError, refetchMe, refetchVerificationStatus]),
    refreshMySettings: useCallback(
      async () => refetchMySettings(),
      [refetchMySettings],
    ),
  };
};

export default useGetMeOperation;
