import * as React from 'react';
import { FC, ReactElement, useEffect, useState } from 'react';
import Loading from '../components/organisms/Loading';
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import makeApolloClient from '../graphql/client/makeApolloClient';

interface AppStartServiceProps {
  children: ReactElement;
}

const AppStartService: FC<AppStartServiceProps> = ({ children }) => {
  const [apolloCli, setApolloCli] =
    useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    makeApolloClient
      .then(cli => {
        setApolloCli(cli);
      })
      .catch(e => console.error('makeClient error', e));
  }, []);
  return apolloCli != null ? (
    <ApolloProvider client={apolloCli}>{children}</ApolloProvider>
  ) : (
    <Loading />
  );
};

export default AppStartService;
