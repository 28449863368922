import React, { ReactElement, useEffect, useMemo } from 'react';
import { ThemeProvider as ThemMuiProvider } from '@mui/system';
import { getDesignTokens, themeOptions } from 'config/theme';
import 'styles/globals.css';
import Router, { RouterPathName } from 'containers/Router';
import AppStartService from 'services/AppStartService';
import './ReactotronConfig';
import LangContext from 'utils/context/LangContext';
import useGetCountryLanguagesOperation from 'graphql/operations/queries/useGetCountryLanguagesOperation';
import I18nInstance from 'i18nConfig';
import TitleProvider from './providers/TitleProvider';
import AlertContextProvider from './providers/AlertContextProvider';
import { IntercomProvider } from 'react-use-intercom';
import Config from 'config/getEnvConfig';
import useRegisterIntercomUser from './thirdparty/useRegisterIntercomUser';
import ModalContextProvider from './providers/ModalContextProvider';
import ModalWarningCookies from 'components/atoms/common/ModalWarningCookies';
import { CookiesProvider } from 'react-cookie';
import PublicHead from './components/atoms/common/PublicHead';
import { Toaster } from 'react-hot-toast';
import { createTheme, useTheme } from '@mui/material';
import CognitoUserProvider from './providers/CongitoUserProvider';
import { useClearCacheAndReload } from './hooks/useClearCacheAndReload';
import { BrowserRouter } from 'react-router-dom';
import { COOKIE_KEY } from './constants/Cookie';
import cognitoSignOut from './api/cognito/cognitoSignOut';
import useGetMeOperation from './graphql/operations/queries/useGetMeOperation';
import AppActivatedProvider from './providers/AppActivatedProvider';
import TrialMatchProvider from './providers/TrialMatchProvider';

function Component() {
  useEffect(() => {
    (async () => {
      const res = localStorage.getItem(COOKIE_KEY.ONBOARDING_FIRSTAPPSTART);
      if (!res) {
        cognitoSignOut().catch(e => console.error('signout', e));
      }
    })();
  }, []);

  const theme = useTheme();
  useRegisterIntercomUser();
  useClearCacheAndReload();
  const { countryLanguage } = useGetCountryLanguagesOperation();
  I18nInstance.addResources(
    'en-DEFAULT',
    'Common',
    countryLanguage?.countryLanguage?.en_DEFAULT,
  );
  I18nInstance.addResources(
    'en-IE',
    'Common',
    countryLanguage?.countryLanguage?.en_IE,
  );
  I18nInstance.addResources(
    'en-US',
    'Common',
    countryLanguage?.countryLanguage?.en_US,
  );
  I18nInstance.addResources(
    'ja-JP',
    'Common',
    countryLanguage?.countryLanguage?.ja_JP,
  );
  I18nInstance.addResources(
    'en-GB',
    'Common',
    countryLanguage?.countryLanguage?.en_GB,
  );
  return (
    <TitleProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      <Toaster
        position={'bottom-center'}
        toastOptions={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: theme.colors.white.base,
          },
        }}
      />
      <ModalWarningCookies />
    </TitleProvider>
  );
}

const ThemeProvider = ({ children }: { children: ReactElement }) => {
  const { isMonoChrome } = useGetMeOperation({
    fetchPolicy: 'cache-and-network',
  });
  const theme = useMemo(
    () =>
      createTheme(themeOptions, {
        colors: getDesignTokens(isMonoChrome),
      }),
    [isMonoChrome],
  );
  return <ThemMuiProvider theme={theme}>{children}</ThemMuiProvider>;
};

const App = () => {
  const hasNoindexTag = useMemo(
    () =>
      window.location.pathname.includes(RouterPathName.match) ||
      window.location.pathname.includes(RouterPathName.accountTop),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.pathname],
  );
  return (
    <div>
      <PublicHead hasNoindexTag={hasNoindexTag} />
      <IntercomProvider appId={Config.intercomAppId}>
        <LangContext.Provider>
          <AppStartService>
            <ThemeProvider>
              <CookiesProvider>
                <AlertContextProvider>
                  <CognitoUserProvider>
                    <ModalContextProvider>
                      <AppActivatedProvider>
                        <TrialMatchProvider>
                          <Component />
                        </TrialMatchProvider>
                      </AppActivatedProvider>
                    </ModalContextProvider>
                  </CognitoUserProvider>
                </AlertContextProvider>
              </CookiesProvider>
            </ThemeProvider>
          </AppStartService>
        </LangContext.Provider>
      </IntercomProvider>
    </div>
  );
};
export default App;
