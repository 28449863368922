import { isApolloError } from '@apollo/client';

export enum JungleAppErrorCode {
  /* General Error like 500 */
  GENERAL__USER_IS_UNDER_GAMBLING_AGES = 'GENERAL/USER_IS_UNDER_GAMBLING_AGES',
  GENERAL__UNEXPECTED = 'GENERAL/UNEXPECTED',
  BET_ERROR = 'BETERROR',
  //
  API__AUTH__UNAUTHENTICATED = 'AUTH/UNAUTHENTICATED',
  API__AUTH__USER_ACCOUNT_PAUSED = 'AUTH/USER_ACCOUNT_PAUSED',
  API__AUTH__INVALID_PINCODE = 'AUTH/INVALID_PINCODE',
  API__AUTH__INVALID_REFERRAL_CODE = 'AUTH/INVALID_REFERRAL_CODE',
  API__AUTH__INVALID_REFERRAL_CODE_DIFFERENT_COUNTRY = 'AUTH/INVALID_REFERRAL_CODE_DIFFERENT_COUNTRY',
  API__AUTH__ACCOUNT_HAS_ALREADY_BEEN_CREATED = 'AUTH/ACCOUNT_HAS_ALREADY_BEEN_CREATED',
  API__BET_VALIDATION__BET_ALREADY_EXISTS = 'BET_VALIDATION/BET_ALREADY_EXISTS',
  API__BET_VALIDATION__INVALID_ODDS = 'BET_VALIDATION/INVALID_ODDS',
  API__BET_VALIDATION__INVALID_OPTION = 'BET_VALIDATION/INVALID_OPTION',
  API__BET_VALIDATION__INVALID_BET_AMOUNT = 'BET_VALIDATION/INVALID_BET_AMOUNT',
  API__BET_VALIDATION__INVALID_BET_COUNT = 'BET_VALIDATION/INVALID_BET_COUNT',
  API__BET_VALIDATION__USER_VERIFICATION_INCOMPLETE = 'BET_VALIDATION/USER_VERIFICATION_INCOMPLETE',
  API__BET_VALIDATION__MARKET_IS_BEING_LOCKED = 'BET_VALIDATION/MARKET_IS_BEING_LOCKED',
  API__LIMITATION__EXCEED_ALLOWED_AMOUNT = 'LIMITATION/EXCEED_ALLOWED_AMOUNT',
  API__TRANSACTION__NEED_FULL_AMOUNT = 'TRANSACTION/NEED_FULL_AMOUNT',
  API__TRANSACTION__MINIMUM_WITHDRAWAL = 'TRANSACTION/MINIMUM_WITHDRAWAL',
  API__LIMITATION__MONTHLY_BONUS = 'LIMITATION/MONTHLY_BONUS_LIMIT',
  API__LIMITATION__EXCEED_LIMITATION_VALUE = 'LIMITATION/EXCEED_LIMITATION_VALUE',
  API__LIMITATION__EXCEED_DEPOSIT_LIMITATION_VALUE = 'LIMITATION/EXCEED_DEPOSIT_LIMITATION_VALUE',
  API__LIMITATION__UNDER_TIMEOUT = 'LIMITATION/UNDER_TIMEOUT',
  API__LIMITATION__UNDER_TIMEOUT_PERIOD = 'LIMITATION/UNDER_TIMEOUT_PERIOD',
  API__LIMITATION__UNDER_SELF_EXCLUSION = 'LIMITATION/UNDER_SELF_EXCLUSION',
  API__LIMITATION__UNDER_SELF_EXCLUSION_PERIOD = 'LIMITATION/UNDER_SELF_EXCLUSION_PERIOD',
  API__LIMITATION__BEFORE_SELF_EXCLUSION_PERIOD = 'LIMITATION/BEFORE_SELF_EXCLUSION_PERIOD',
  API__LIMITATION__AFTER_SELF_EXCLUSION_PERIOD = 'LIMITATION/AFTER_SELF_EXCLUSION_PERIOD',
  API__LIMITATION__UNDER_GAMSTOP_EXCLUSION_PERIOD = 'LIMITATION/GAMSTOP_EXCLUDED',
  API__RISK_VALIDATION__EXCEED_MARKET_WAGER_LIMIT = 'RISK_VALIDATION/EXCEED_MARKET_WAGER_LIMIT',
  API__RISK_VALIDATION__EXCEED_PERSONAL_WAGER_LIMIT = 'RISK_VALIDATION/EXCEED_PERSONAL_WAGER_LIMIT',
  API__RISK_VALIDATION__MINIMUM_BET_AMOUNT = 'BET_VALIDATION/MINIMUM_BET_AMOUNT',
  API__TRANSACTION__EXCEED_PERSONAL_DEPOSIT_LIMIT = 'TRANSACTION/EXCEED_PERSONAL_DEPOSIT_LIMIT',
  API__TRANSACTION__EXCEED_PERSONAL_WITHDRAW_LIMIT = 'TRANSACTION/EXCEED_PERSONAL_WITHDRAW_LIMIT',
  API__TRANSACTION__INSUFFICIENT_BALANCE = 'TRANSACTION/INSUFFICIENT_BALANCE',
  API__TRANSACTION__USER_VERIFICATION_INCOMPLETE = 'TRANSACTION/USER_VERIFICATION_INCOMPLETE',
  API__TRANSACTION__INVALID_CURRENCY = 'TRANSACTION/INVALID_CURRENCY',
  API__TRANSACTION__MINIMUM_DEPOSIT = 'TRANSACTION/MINIMUM_DEPOSIT',
  API__TRANSACTION__INVALID_WITHDRAWAL_SOURCE = 'TRANSACTION/INVALID_WITHDRAWAL_SOURCE',
  //
  AUTH__EXISTS_PHONE_NO = 'AUTH/EXISTS_PHONE_NO',
  AUTH__ACCOUNT__SUSPEND = 'AUTH/USER_ACCOUNT_SUSPENDED',
  AUTH__ACCOUNT__CLOSE = 'AUTH/USER_ACCOUNT_CLOSED',
  AUTH__ACCOUNT__GAMSTOP = 'is under GAMSTOP exclusion period',
  AUTH__ACCOUNT__SELFEXCLUSION = 'PostAuthentication failed with error Before self exclusion period',
  API__AUTH__UNABLE_TO_SIGN_UP = 'PreSignUp failed with error We are temporarily suspending new user registration. Please wait for a while until the suspension is lifted..',
  //
  API__MESSAGE__NEGATIVE_MESSAGE = 'MESSAGE/NEGATIVE_MESSAGE',
  //
  API__UNLOCK_PLAYER_STAT_VALIDATION_INSUFFICIENT_BONUS = 'UNLOCK_PLAYER_STAT_VALIDATION/INSUFFICIENT_BONUS',
  API__UNLOCK_MARKETS_VALIDATION_INSUFFICIENT_BONUS = 'UNLOCK_MARKETS_VALIDATION/INSUFFICIENT_BONUS',
  API__UNLOCK_PAST_VIDEO_VALIDATION_INSUFFICIENT_BONUS = 'UNLOCK_PAST_VIDEO_VALIDATION/INSUFFICIENT_BONUS',
  //
  API__VALIDATION_ACTIVATION__NOT_INVITED_YET = 'ACTIVATION_CODE_VALIDATION/USER_NOT_INVITED_YET',
  API__VALIDATION_ACTIVATION__NOT_MATCH = 'ACTIVATION_CODE_VALIDATION/ACTIVATION_CODE_NOT_MATCH',
  API__VALIDATION_ACTIVATION__EXISTED_IN_WAITING_LIST = 'ACTIVATION_CODE_VALIDATION/USER_EXISTED_IN_WAITING_LIST',
  API__VALIDATION_ACTIVATION__VERIFICATION_INCOMPLETE = 'USER_VERIFICATION/USER_ACTIVATION_CODE_VERIFICATION_INCOMPLETE',
  API__VALIDATION_ACTIVATION__USER_ALREADY_SUBMITTED = 'ACTIVATION_CODE_VALIDATION/USER_ALREADY_SUBMITTED_SUCCESS_FULLY',

  API__ONFIDO_MISSING_ADDRESS_INFORMATION = 'ONFIDO/MISSING_ADDRESS_INFORMATION',
  API__ONFIDO_INVALID_CREDENTIAL = 'ONFIDO/INVALID_CREDENTIAL',
  API__UNHANDLED_BETS_CASH = 'GENERAL/UNHANDLED_BETS_CASH',
  API__BLOCKED_VPN = 'Lambda:Unhandled',
  API__INVALID_ACCESS = 'SECURITY_ACCESS/INVALID_ACCESS',
}

class JungleAppError extends Error {
  errorType: string;
  constructor(errorType: JungleAppErrorCode) {
    super();
    this.errorType = errorType;
  }
}

export const isJungleAppError = (error: any): error is JungleAppError => {
  return typeof error === 'object' && typeof error.errorType === 'string';
};

export const extractJungleAppError = (error: any): JungleAppError | null => {
  if (isJungleAppError(error)) {
    return error;
  }
  if (!error || typeof error !== 'object') {
    return null;
  }
  if (!isApolloError(error)) {
    return null;
  }
  return error.graphQLErrors.reduce<JungleAppError | null>((acc, next) => {
    if (acc == null && isJungleAppError(next)) {
      return next;
    }
    return acc;
  }, null);
};

export default JungleAppError;
