import { useMemo } from 'react';
import { useGetCountryLanguagesQuery } from '../../__generated__/graphqlApi';
import { WatchQueryFetchPolicy } from '@apollo/client';

type CountryLanguage = {
  en_DEFAULT: string;
  en_IE: string;
  en_US: string;
  ja_JP: string;
  en_GB: string;
};

interface MyObj {
  countryLanguage: CountryLanguage;
}

const useGetCountryLanguagesOperation = (options?: {
  fetchPolicy?: WatchQueryFetchPolicy;
}): {
  countryLanguage: MyObj;
} => {
  const { fetchPolicy } = options ?? {};
  const { data } = useGetCountryLanguagesQuery({
    fetchPolicy,
  });
  const countryLanguage = useMemo(() => {
    if (!data?.getCountryLanguages?.countryLanguage) {
      return null;
    }
    return JSON.parse(data.getCountryLanguages.countryLanguage);
  }, [data?.getCountryLanguages?.countryLanguage]);
  return {
    countryLanguage,
  };
};

export default useGetCountryLanguagesOperation;
