import React from 'react';

import { useLang } from 'utils/context/LangContext';
import AlertDialog from './AlertDialog';

const ModalWarningCookies = () => {
  const { t } = useLang();
  const cookieEnabled = navigator.cookieEnabled;

  if (cookieEnabled) return null;

  return (
    <AlertDialog
      isOpen={true}
      onClose={() => {}}
      title={t('checkCookiesTitle')}
      message={t('checkCookieeContent')}
      hideAction={true}
    />
  );
};

export default ModalWarningCookies;
