import { WatchQueryFetchPolicy } from '@apollo/client';
import { useGetGlobalSettingQuery } from '../../__generated__/graphqlApi';
import { useMemo } from 'react';
import Config from 'config/getEnvConfig';

const useGetGlobalSettingOperation = (params?: {
  fetchPolicy?: WatchQueryFetchPolicy;
  pollInterval?: number;
}) => {
  const { fetchPolicy = 'cache-and-network', pollInterval } = params || {};
  const { loading, data, startPolling, stopPolling } = useGetGlobalSettingQuery(
    {
      fetchPolicy,
      pollInterval,
      variables: {
        params: {
          os: 'web',
          appConfiguration: {
            appBuildConfiguration: Config.appBuildConfiguration,
            bundleId: Config.applicationId,
          },
          version: null,
          country: null,
        },
      },
    },
  );
  return useMemo(() => {
    return {
      availableCountryAlpha2Codes:
        data?.globalSettings?.availableCountries || [],
      countryCallingCodes: data?.globalSettings?.countryCodes || [],
      bonusConfig: data?.globalSettings?.bonusConfig ?? null,
      latestVersion: data?.globalSettings?.latestVersion ?? '',
      myCountryCode: data?.globalSettings?.myCountryCode ?? null,
      loading,
      polling: {
        startPolling,
        stopPolling,
      },
    };
  }, [
    data?.globalSettings?.myCountryCode,
    data?.globalSettings?.availableCountries,
    data?.globalSettings?.bonusConfig,
    data?.globalSettings?.countryCodes,
    data?.globalSettings?.latestVersion,
    loading,
    startPolling,
    stopPolling,
  ]);
};

export default useGetGlobalSettingOperation;
