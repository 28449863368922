import {
  GetMyVerificationStatusV2Document,
  useOnVerificationStatusUpdatedSubscription,
} from '../../__generated__/graphqlApi';
import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

const useSubscribeVerificationStatusUpdate = (userId?: string) => {
  const apolloCache = useApolloClient().cache;

  const updateApolloCache = useCallback(
    subscription => {
      apolloCache.updateQuery(
        { query: GetMyVerificationStatusV2Document },
        data => ({
          myIdVerificationStatusV2: {
            ...data.myIdVerificationStatusV2,
            status:
              subscription?.verificationStatusUpdated?.status ??
              data.myIdVerificationStatusV2?.status,
          },
        }),
      );
    },
    [apolloCache],
  );

  const { data: nextData } = useOnVerificationStatusUpdatedSubscription({
    variables: { id: userId || '' },
    onSubscriptionData: useCallback(
      async ({ subscriptionData: { data } }) => {
        updateApolloCache(data);
      },
      [updateApolloCache],
    ),
    skip: !!userId,
  });

  return {
    verificationStatus: nextData?.verificationStatusUpdated ?? null,
  };
};

export default useSubscribeVerificationStatusUpdate;
