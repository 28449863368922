import { envName } from './config/envName';

export enum Env {
  Dev = 'dev',
  Demo = 'demo',
  Prod = 'prod',
  Inhouse = 'inhouse',
  EnergyRelease = 'energyRelease',
  Staging = 'staging',
}

const enabledFor = (envArray: Env[]): boolean => {
  return envArray.includes(envName as Env);
};

const ReleaseConfig = {
  /**
   * Show DebugInfo tag in the right-top of the screen
   */
  DebugInfoTagEnabled: enabledFor([Env.Prod, Env.Dev, Env.Staging]),
  /**
   * Show playing in-play matches in the home.
   */
  PlayingInPlayEnabled: enabledFor([]),
  /**
   * Enable ID verification feature.
   */
  IdVerificationEnabled: enabledFor([Env.Prod, Env.Dev, Env.Staging]),
  /**
   * Enable listen feature
   */
  ListenEnabled: enabledFor([Env.Prod, Env.Dev, Env.Staging]),
  /**
   * Enable re-authenticate feature
   */
  ReAuth: enabledFor([Env.Prod, Env.Dev, Env.Staging]),
};

export default ReleaseConfig;
