import { InMemoryCache } from '@apollo/client';
import generatedPossibleTypes from '../__generated__/possibleTypes';
import { AsyncStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import { TypedTypePolicies } from '../__generated__/apolloHelpers';
import getStreamingContentChatMessagesMergeFunction from './mergeFuncstions/getStreamingContentChatMessagesMergeFunction';

const { possibleTypes } = generatedPossibleTypes;

const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      getStreamingContentChatMessages: {
        keyArgs: false,
        merge: getStreamingContentChatMessagesMergeFunction,
      },
    },
  },
};

export const cache = new InMemoryCache({
  possibleTypes,
  typePolicies,
});

export const createCache = async (invalidateCache?: boolean) => {
  const persistor = new CachePersistor({
    cache,
    storage: new AsyncStorageWrapper(localStorage),
    debug: true,
    trigger: 'write',
  });
  console.log(
    '%c[Apollo3Cache]%c ============ Invalidate cache ============',
    'font-weight: bold;',
    '',
  );
  // await persistor.purge();
  if (invalidateCache) {
    console.log(
      '%c[Apollo3Cache]%c ============ Invalidate cache ============',
      'font-weight: bold;',
      '',
    );
    await persistor.purge();
  } else {
    console.log(
      '%c[Apollo3Cache]%c ============ Restore cache ============',
      'font-weight: bold;',
      '',
    );
    await persistor.restore();
  }
  return cache;
};
