import * as React from 'react';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import Config from 'config/getEnvConfig';
import LogoHeader from 'assets/images/common/logo_header.png';
import { envName } from '../../../config/envName';

interface PublicHeadProps {
  title?: string;
  description?: string;
  keywords?: string;
  ogTitle?: string;
  ogType?: string;
  ogImage?: string;
  ogSiteName?: string;
  ogDescription?: string;
  ogLocale?: string;
  hasNoindexTag?: boolean;
}

const PublicHead: FC<PublicHeadProps> = ({
  title,
  description,
  keywords,
  ogTitle,
  ogType,
  ogImage,
  ogSiteName,
  ogDescription,
  ogLocale,
  hasNoindexTag = false,
}) => {
  return (
    <Helmet>
      <title>{title ?? 'JUNGLEbet - Jungle It.'}</title>
      <link rel='shortcut icon' href={'/favicon.ico'} />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1, maximum-scale=5, shrink-to-fit=no'
      />
      <meta
        name='description'
        content={
          description ??
          'Jungle It. JUNGLEbet is the sole provider of ‘Modern Sports’ betting, offering simple and casual modern sports that deliver results in just a few minutes.'
        }
        key='description'
      />
      <meta
        name='keywords'
        content={
          keywords ??
          'jungle bet, junglebet, jungle betting, sports betting, inplay betting'
        }
        key='keywords'
      />
      <meta
        property='og:title'
        content={ogTitle ?? 'JUNGLEbet - Jungle it.'}
        key='og:title'
      />
      <meta property='og:type' content={ogType ?? 'website'} />
      <meta property='og:url' content={'jungle.bet'} />
      <meta
        property='og:image'
        content={ogImage ? ogImage : `https://${Config.web.host}${LogoHeader}`}
        key='og:image'
      />
      <meta
        property='og:site_name'
        content={ogSiteName ?? 'JUNGLEbet'}
        key='og:site_name'
      />
      <meta
        property='og:description'
        content={
          ogDescription ??
          `JUNGLEbet presents 'Modern Sports,' simple and quick games concluding within minutes.`
        }
        key='og:description'
      />
      <meta
        property='og:locale'
        content={ogLocale ?? 'en_GB'}
        key='og:locale'
      />
      {(envName !== 'prod' || hasNoindexTag) && (
        <meta name='robot' content='noindex' />
      )}
    </Helmet>
  );
};

export default PublicHead;
