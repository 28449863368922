import Reactotron from 'reactotron-react-js';
export const GraphQLLogBlacklist: string[] = [];

// @ts-ignore
const reactotron =
  process.env.NODE_ENV !== 'production'
    ? Reactotron.configure({
        name: 'JungleBet',
        host: 'localhost',
      }) // we can use plugins here -- more on this later
        .connect()
    : undefined; // let's connect!

export default reactotron;
