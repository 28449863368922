import useGetMeOperation from '../graphql/operations/queries/useGetMeOperation';
import { useEffect } from 'react';
import { analytics } from '../firebase-messaging-sw';
import { setUserId } from '@firebase/analytics';

export const useSetUserId = () => {
  const { me } = useGetMeOperation();

  useEffect(() => {
    if (!me?.id) return;
    setUserId(analytics, me.id);
  }, [me?.id]);
};
