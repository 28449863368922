import useGetMeOperation from '../graphql/operations/queries/useGetMeOperation';
import { useCallback, useEffect, useState } from 'react';
import Logger from '../utils/core/Logger';
import { DateTime } from 'luxon';
import {
  IntercomPlatform,
  useGetMyIntercomUserHashLazyQuery,
} from '../graphql/__generated__/graphqlApi';
import { useIntercom } from 'react-use-intercom';

const useRegisterIntercomUser = () => {
  const { me, gamstopStatus, verificationStatus } = useGetMeOperation();
  const { boot } = useIntercom();
  const [getMyIntercomUserHash] = useGetMyIntercomUserHashLazyQuery({
    variables: { platform: IntercomPlatform.Web },
  });
  const [currentUserIntercom, setCurrentUserIntercom] = useState<{
    userId: string;
    email: string;
  } | null>(null);

  const { mySettings } = useGetMeOperation({
    fetchPolicy: 'cache-and-network',
  });

  const selfExclusionEndsAt = mySettings?.selfExclusion?.selfExclusionEndsAt
    ? DateTime.fromISO(mySettings.selfExclusion.selfExclusionEndsAt).toSeconds()
    : undefined;

  const timeoutEndsAt = mySettings?.timeout?.timeoutEndsAt
    ? DateTime.fromISO(mySettings.timeout.timeoutEndsAt).toSeconds()
    : undefined;

  useEffect(() => {
    if (!me?.id) {
      setCurrentUserIntercom(null);
      return;
    }
    setCurrentUserIntercom({
      userId: me?.id,
      email: me?.properties?.email,
    });
  }, [me?.id, me?.properties?.email]);

  const handleGetMyIntercomUserHash = useCallback(async () => {
    const { data, error } = await getMyIntercomUserHash();
    if (error) {
      Logger.error(error);
      return;
    }
    if (data?.myIntercomUserHash != null) {
      boot({
        userHash: data.myIntercomUserHash,
        userId: me?.id,
        email: me?.properties.email,
      });
    }
  }, [getMyIntercomUserHash, me?.id, me?.properties.email, boot]);

  useEffect(() => {
    if (!me) {
      boot();
      return;
    }
    const isUserIntercomExist =
      currentUserIntercom === null ||
      currentUserIntercom?.userId !== me.id ||
      currentUserIntercom?.email !== me.properties.email;
    if (isUserIntercomExist) {
      handleGetMyIntercomUserHash()
        .then()
        .catch(e => Logger.error(e));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    me?.id,
    me?.properties.email,
    me?.referralCode,
    currentUserIntercom,
    gamstopStatus,
    handleGetMyIntercomUserHash,
    selfExclusionEndsAt,
    timeoutEndsAt,
    verificationStatus?.status,
  ]);
};

export default useRegisterIntercomUser;
