import { envName } from './envName';
import { Env } from '../ReleaseConfig';
export interface ConfigType {
  aws: {
    region: string;
    cognito: {
      userPoolId: string;
      userPoolWebClientId: string;
    };
    appsync: {
      endpoint: string;
      websocketEndpoint: string;
      iamId: string;
      iamSecret: string;
    };
  };
  agora: {
    appId: string;
  };
  web: {
    appServiceUrl: string;
    host: string;
    domain: string;
  };
  dynamicLinks: {
    domainUriPrefix: string;
    appStoreUrl: string;
    playStore: string;
  };
  appBuildConfiguration: string;
  applicationId: string;
  intercomAppId: string;
}
export enum BuildConfiguration {
  InhouseRelease = 'InhouseRelease',
  InhouseDebug = 'InhouseDebug',
  Debug = 'Debug',
  Release = 'Release',
  DevDebug = 'DevDebug',
  DevRelease = 'DevRelease',
  EnergyDebug = 'EnergyDebug',
  EnergyRelease = 'EnergyRelease',
  EnergyDevDebug = 'EnergyDevDebug',
  EnergyDevRelease = 'EnergyDevRelease',
  DemoDebug = 'DemoDebug',
  DemoRelease = 'DemoRelease',
  StagingDebug = 'StagingDebug',
  StagingRelease = 'StagingRelease',
  ProdDebug = 'ProdDebug',
  ProdRelease = 'ProdRelease',
}

const ProdConfig: ConfigType = {
  aws: {
    region: 'eu-west-2',
    cognito: {
      userPoolId: 'eu-west-2_AlwLNOKFL',
      userPoolWebClientId: '3kbn42s22cnudtk72d17al5um1',
    },
    appsync: {
      endpoint: 'https://api-prod.jungleuk.xyz/graphql',
      websocketEndpoint: 'wss://api-prod.jungleuk.xyz/graphql/realtime',
      iamId: process.env.REACT_APP_IAM_ID ?? '',
      iamSecret: process.env.REACT_APP_IAM_SECRET ?? '',
    },
  },
  agora: {
    appId: '4a6b7e7de2cf45c7a7f29aad75ce3bc2',
  },
  web: {
    appServiceUrl: '',
    host: 'd34v6vse3prxf6.cloudfront.net',
    domain: 'https://www.jungle.bet/',
  },
  dynamicLinks: {
    domainUriPrefix: (() => {
      switch (envName) {
        case Env.Prod:
          return 'https://jungleuk2.page.link';
        default:
          return 'https://junglebet2.page.link';
      }
    })(),
    appStoreUrl:
      'https://apps.apple.com/us/app/jungle-bet-in-play-betting/id1606617248',
    playStore:
      'https://play.google.com/store/apps/details?id=xyz.jungleuk.junglebet&pli=1',
  },
  appBuildConfiguration: (() => {
    switch (envName) {
      case Env.Prod:
        return BuildConfiguration.Release;
      case Env.Inhouse:
        return BuildConfiguration.InhouseRelease;
      default:
        return BuildConfiguration.EnergyRelease;
    }
  })(),
  applicationId: (() => {
    switch (envName) {
      case Env.EnergyRelease:
        return 'web.jungle.bet.energy';
      default:
        return 'web.jungle.bet';
    }
  })(),
  intercomAppId: 'b3o2la2z',
};

const DevConfig: ConfigType = {
  aws: {
    region: 'ap-northeast-1',
    cognito: {
      userPoolId: 'ap-northeast-1_DaBiEPFai',
      userPoolWebClientId: '5m44pu3lorq99fj4mo6460h7e5',
    },
    appsync: {
      endpoint: 'https://api-dev.jungleuk.xyz/graphql',
      websocketEndpoint: 'wss://api-dev.jungleuk.xyz/graphql/realtime',
      iamId: process.env.REACT_APP_IAM_ID ?? '',
      iamSecret: process.env.REACT_APP_IAM_SECRET ?? '',
    },
  },
  agora: {
    appId: 'dcbb97deb2a2491e85a94e596c2d0aa2',
  },
  web: {
    appServiceUrl: '',
    host: 'dev.jungle.bet',
    domain: 'https://dev.jungle.bet/',
  },
  dynamicLinks: {
    domainUriPrefix: 'https://junglebetdev2.page.link',
    appStoreUrl:
      'https://apps.apple.com/us/app/jungle-bet-in-play-betting/id1606617248',
    playStore:
      'https://play.google.com/store/apps/details?id=xyz.jungleuk.junglebet&utm_source=web&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  },
  appBuildConfiguration: BuildConfiguration.DevRelease,
  applicationId: 'web.jungle.bet.dev',
  intercomAppId: 'o8dxuqhq',
};

const StagingConfig: ConfigType = {
  aws: {
    region: 'eu-west-2',
    cognito: {
      userPoolId: 'eu-west-2_k8w30cvgZ',
      userPoolWebClientId: 'rut3dmrs3pa1g3l0e3drckqeo',
    },
    appsync: {
      endpoint: 'https://api-stg.jungleuk.xyz/graphql',
      websocketEndpoint: 'wss://api-stg.jungleuk.xyz/graphql/realtime',
      iamId: process.env.REACT_APP_IAM_ID ?? '',
      iamSecret: process.env.REACT_APP_IAM_SECRET ?? '',
    },
  },
  agora: {
    appId: 'dcbb97deb2a2491e85a94e596c2d0aa2',
  },
  web: {
    appServiceUrl: '',
    host: 'staging.jungle.bet',
    domain: 'https://staging.jungle.bet/',
  },
  dynamicLinks: {
    domainUriPrefix: 'https://junglebetdev2.page.link',
    appStoreUrl:
      'https://apps.apple.com/us/app/jungle-bet-in-play-betting/id1606617248',
    playStore:
      'https://play.google.com/store/apps/details?id=xyz.jungleuk.junglebet&utm_source=web&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  },
  appBuildConfiguration: BuildConfiguration.StagingRelease,
  applicationId: 'web.jungle.bet.stg',
  intercomAppId: 'o8dxuqhq',
};

// prod_agoraAppId = '4a6b7e7de2cf45c7a7f29aad75ce3bc2';

const getEnvConfig = (): ConfigType => {
  const Config = DevConfig;

  if (envName === 'dev') {
    return DevConfig;
  }
  if (envName === 'staging') {
    return StagingConfig;
  }
  if (envName === 'prod') {
    return ProdConfig;
  }
  return Config;
};

export default getEnvConfig();
