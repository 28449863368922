import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSJSON: string;
  AWSURL: string;
};

export type AcceptTermAndConditionInput = {
  termAndConditionId: Scalars['ID'];
};

export type AcceptTermAndConditionOutput = {
  __typename?: 'AcceptTermAndConditionOutput';
  accepted: Maybe<Scalars['Boolean']>;
};

export type AgoraChannelInformation = {
  __typename?: 'AgoraChannelInformation';
  channelName: Scalars['String'];
  token: Scalars['String'];
  uid: Scalars['Int'];
};

export type AmericanFootballSummaryInput = {
  customerId: Scalars['ID'];
  fixtureId: Scalars['ID'];
  messageTimestampUtc: Scalars['AWSDateTime'];
};

export type BalanceWalletHistory = WalletHistory & {
  __typename?: 'BalanceWalletHistory';
  amount: Scalars['Int'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  transactionId: Scalars['ID'];
  transactionType: TransactionType;
  isShown: Scalars['Boolean'];
  text: Maybe<Scalars['String']>;
  transactionText: Maybe<Scalars['String']>;
};

export type BaseballSummaryInput = {
  customerId: Scalars['ID'];
  fixtureId: Scalars['ID'];
  messageTimestampUtc: Scalars['AWSDateTime'];
};

export type BasketBallMatchSummary = MatchSummary & {
  __typename?: 'BasketBallMatchSummary';
  createdAt: Scalars['AWSDateTime'];
  currentPhase: Maybe<BasketBallPhaseType>;
  id: Scalars['ID'];
  score: Maybe<Score>;
  sportKey: SportKey;
  updatedAt: Scalars['AWSDateTime'];
};

export enum BasketBallPhaseType {
  BeforeFourthQuarter = 'BEFORE_FOURTH_QUARTER',
  BeforeOvertime = 'BEFORE_OVERTIME',
  BeforeSecondQuarter = 'BEFORE_SECOND_QUARTER',
  FirstHalf = 'FIRST_HALF',
  FirstQuarter = 'FIRST_QUARTER',
  FourthQuarter = 'FOURTH_QUARTER',
  GameAbandoned = 'GAME_ABANDONED',
  Halftime = 'HALFTIME',
  Overtime = 'OVERTIME',
  Postgame = 'POSTGAME',
  Pregame = 'PREGAME',
  SecondHalf = 'SECOND_HALF',
  SecondQuarter = 'SECOND_QUARTER',
  ThirdQuarter = 'THIRD_QUARTER',
}

export type BasketBallScoreDataInput = {
  currentScore: Maybe<ScoreInput>;
  firstHalfScore: Maybe<ScoreInput>;
  firstQuarterScore: Maybe<ScoreInput>;
  firstTeamToScore: Maybe<Scalars['Int']>;
  fourthQuarterScore: Maybe<ScoreInput>;
  lastTeamToScore: Maybe<Scalars['Int']>;
  overtimeScore: Maybe<ScoreInput>;
  regulationTimeFinalScore: Maybe<ScoreInput>;
  secondHalfScore: Maybe<ScoreInput>;
  secondQuarterScore: Maybe<ScoreInput>;
  thirdQuarterScore: Maybe<ScoreInput>;
};

export type BasketBallSummaryInput = {
  currentPhase: Maybe<BasketBallPhaseType>;
  customerId: Scalars['ID'];
  fixtureId: Scalars['ID'];
  foulCount: Maybe<FoulCountInput>;
  isClockRunning: Maybe<Scalars['Boolean']>;
  isReliable: Maybe<Scalars['Boolean']>;
  matchTipOffWinner: Maybe<HomeAwaySide>;
  messageTimestampUtc: Scalars['AWSDateTime'];
  numberOfFreeThrowsRemaining: Maybe<Scalars['Int']>;
  overtimeCount: Maybe<Scalars['Int']>;
  possession: Maybe<Scalars['Int']>;
  scores: Maybe<BasketBallScoreDataInput>;
  timeRemainingInPhaseAtTimestamp: Maybe<TimeRemainingInPhaseAtTimestamp>;
};

export type Bet = {
  __typename?: 'Bet';
  confirmationStatus: ConfirmationStatus;
  confirmedAt: Maybe<Scalars['AWSDateTime']>;
  createdAt: Scalars['AWSDateTime'];
  estimatedReturn: Scalars['Int'];
  id: Scalars['ID'];
  market: InplayMarket;
  match: Match;
  oddsId: Scalars['ID'];
  oddsValue: Scalars['Float'];
  postBalance: Maybe<Scalars['Int']>;
  preBalance: Maybe<Scalars['Int']>;
  returned: Maybe<Scalars['Int']>;
  selectedOption: InplayMarketOption;
  settledAt: Maybe<Scalars['AWSDateTime']>;
  status: BetStatus;
  updatedAt: Scalars['AWSDateTime'];
  userConfirmedResult: Maybe<Scalars['Boolean']>;
  wager: Scalars['Int'];
  isCash: Maybe<Scalars['Boolean']>;
  bonusReturned: Maybe<Scalars['Int']>;
  cashReturned: Maybe<Scalars['Int']>;
  preBonus: Maybe<Scalars['Int']>;
  postBonus: Maybe<Scalars['Int']>;
  preCash: Maybe<Scalars['Int']>;
  postCash: Maybe<Scalars['Int']>;
};

export type BetInput = {
  marketId: Scalars['ID'];
  odds: OddsInput;
  optionId: Scalars['ID'];
  wager: Scalars['Int'];
};

export type BetLimitations = {
  __typename?: 'BetLimitations';
  betLimitationAllowed: Maybe<LimitationAllowed>;
  depositLimitationAllowed: Maybe<LimitationAllowed>;
  lossLimitationAllowed: Maybe<LimitationAllowed>;
};

export type BetListOutput = {
  __typename?: 'BetListOutput';
  bets: Array<Bet>;
  profit: Maybe<Scalars['Float']>;
  totalBets: Maybe<Scalars['Float']>;
  totalReturns: Maybe<Scalars['Float']>;
};

export type BetListV2Output = {
  __typename?: 'BetListV2Output';
  bets: Array<Bet>;
  totalBonusReturns: Maybe<Scalars['Int']>;
  totalBonusBets: Maybe<Scalars['Int']>;
  bonusProfit: Maybe<Scalars['Int']>;
  totalCashReturns: Maybe<Scalars['Int']>;
  totalCashBets: Maybe<Scalars['Int']>;
  cashProfit: Maybe<Scalars['Int']>;
  totalBetCount: Maybe<Scalars['Int']>;
  totalCorrectBetCount: Maybe<Scalars['Int']>;
  totalUnconfirmedBetCount: Maybe<Scalars['Int']>;
};

export type BetMetadataInput = {
  userAgent: Maybe<UserAgentType>;
};

export type BetOutput = {
  __typename?: 'BetOutput';
  bet: Bet;
  wallet: Wallet;
};

export enum BetStatus {
  Canceled = 'CANCELED',
  Lost = 'LOST',
  NotDecided = 'NOT_DECIDED',
  Won = 'WON',
}

export type BetV2Input = {
  marketId: Scalars['ID'];
  odds: OddsInput;
  optionId: Scalars['ID'];
  wager: Scalars['Int'];
  isCash: Scalars['Boolean'];
  metadata: Maybe<BetMetadataInput>;
};

export type BetsOutputs = {
  __typename?: 'BetsOutputs';
  bets: Array<Bet>;
  nextKey: Maybe<Scalars['String']>;
};

export type BonusConfig = {
  __typename?: 'BonusConfig';
  onIdVerification: Maybe<Scalars['Boolean']>;
  onIdVerificationAmount: Maybe<Scalars['Int']>;
  onIdVerificationCash: Maybe<Scalars['Int']>;
  onIdVerificationExplanation: Maybe<Scalars['String']>;
  onSignUp: Maybe<Scalars['Boolean']>;
  onSignUpAmount: Maybe<Scalars['Int']>;
  onSignUpExplanation: Maybe<Scalars['String']>;
};

export enum BonusConfigAction {
  SignUp = 'SIGN_UP',
  IdVerification = 'ID_VERIFICATION',
}

export enum BonusConfigRequirement {
  IdVerification = 'ID_VERIFICATION',
  RefereeIdVerification = 'REFEREE_ID_VERIFICATION',
}

export enum BonusConfigTerm {
  Term_1Day = 'TERM_1_DAY',
}

export type BonusConfigV2 = {
  __typename?: 'BonusConfigV2';
  id: Scalars['ID'];
  typeKey: Scalars['String'];
  name: Maybe<Scalars['String']>;
  bonusType: BonusType;
  countType: BonusCountType;
  startAt: Maybe<Scalars['AWSDateTime']>;
  endAt: Maybe<Scalars['AWSDateTime']>;
  requiredCount: Maybe<Scalars['Int']>;
  maxCount: Maybe<Scalars['Int']>;
  requiredAmount: Maybe<Scalars['Int']>;
  additionalRequirements: Maybe<Array<BonusConfigRequirement>>;
  amount: Maybe<Scalars['Int']>;
  amountPercent: Maybe<Scalars['Float']>;
  text: Maybe<Scalars['String']>;
  txnText: Maybe<Scalars['String']>;
  explanation: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['AWSURL']>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  isEnabled: Scalars['Boolean'];
  term: Maybe<BonusConfigTerm>;
  requiredAction: Maybe<BonusConfigAction>;
};

export enum BonusCountType {
  Accum = 'ACCUM',
  Period = 'PERIOD',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type BonusInput = {
  amount: Scalars['Int'];
  transactionUUID: Scalars['String'];
  userId: Scalars['ID'];
};

export enum BonusType {
  Signup = 'SIGNUP',
  Bet = 'BET',
  Win = 'WIN',
  Lose = 'LOSE',
  IdVerification = 'ID_VERIFICATION',
  Deposit = 'DEPOSIT',
  Referrer = 'REFERRER',
  Referee = 'REFEREE',
  SignIn = 'SIGN_IN',
}

export type BonusWallet = {
  __typename?: 'BonusWallet';
  bonus: Scalars['Int'];
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
};

export type Campaign = {
  __typename?: 'Campaign';
  id: Scalars['ID'];
  key: CampaignKey;
  value: LocalizedStrings;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export enum CampaignKey {
  HeroSections = 'HeroSections',
}

export type CancelLiveStreamingContentInput = {
  id: Scalars['ID'];
};

export type CancelLiveStreamingContentOutput = {
  __typename?: 'CancelLiveStreamingContentOutput';
  cancelled: Scalars['Boolean'];
};

export type CategoryPriorityPerUserInput = {
  categoryId: Scalars['ID'];
  priority: Maybe<Scalars['Float']>;
};

export type ChannelJoinDetail = {
  __typename?: 'ChannelJoinDetail';
  contentId: Scalars['ID'];
  inChannel: Scalars['Boolean'];
  isAudience: Scalars['Boolean'];
  isBroadcaster: Scalars['Boolean'];
  isInvited: Scalars['Boolean'];
  isRequesting: Scalars['Boolean'];
  isSuspended: Scalars['Boolean'];
};

export type CheckIsValidPostCodeInput = {
  code: Scalars['String'];
  country: UserCountryCodeIso1Alpha2;
};

export type CloseAccountInput = {
  reasons: Array<Scalars['String']>;
};

export type CodeActivationEnabled = {
  __typename?: 'CodeActivationEnabled';
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
};

export type CompetitorsToMatchInput = {
  competitorIds: Array<Scalars['ID']>;
  matchId: Scalars['ID'];
};

export type CompleteUserSignInBonusOutput = {
  __typename?: 'CompleteUserSignInBonusOutput';
  success: Maybe<Scalars['Boolean']>;
};

export type ConfirmMyBetResultInput = {
  betId: Scalars['ID'];
};

export enum ConfirmationStatus {
  Confirmed = 'CONFIRMED',
  NotConfirmed = 'NOT_CONFIRMED',
}

export type CreateCheckOnfidoInput = {
  motionCaptureId: Maybe<Scalars['String']>;
};

export type CreateCheckOnfidoV2Input = {
  motionCaptureId: Maybe<Scalars['String']>;
  isSecondStep: Maybe<Scalars['Boolean']>;
};

export type CreateInplayMarketInput = {
  closeAt: Maybe<Scalars['AWSDateTime']>;
  id: Maybe<Scalars['ID']>;
  matchId: Scalars['ID'];
  mediaUrls: InplayMarketMediaUrlsInput;
  openAt: Maybe<Scalars['AWSDateTime']>;
  originalTitle: Maybe<LocalizedStringsInput>;
  shortTitle: Maybe<LocalizedStringsInput>;
  targetProfileIds: Maybe<Array<Scalars['ID']>>;
  title: LocalizedStringsInput;
};

export type CreateLeagueProfileInput = {
  avatarImageUrl: Scalars['String'];
  id: Maybe<Scalars['ID']>;
  name: LocalizedStringsInput;
  shortName: Maybe<LocalizedStringsInput>;
  sportId: Scalars['ID'];
};

export type CreateLiveStreamingContentInput = {
  channelSetting: Maybe<LiveStreamingChannelSettingInput>;
  description: LocalizedStringsInput;
  imageUrl: Maybe<Scalars['String']>;
  liveAt: Scalars['AWSDateTime'];
  metadata: Maybe<Scalars['AWSJSON']>;
  playlistId: Maybe<Scalars['ID']>;
  sortPriority: Maybe<Scalars['Float']>;
  streamerId: Scalars['ID'];
  streamingMatchId: Maybe<Scalars['ID']>;
  tags: Array<Scalars['String']>;
  title: LocalizedStringsInput;
};

export type CreateMatchCategoryInput = {
  id: Maybe<Scalars['ID']>;
  mediaUrls: SportMediaUrlsInput;
  name: LocalizedStringsInput;
  sportId: Scalars['ID'];
  sportKey: Maybe<SportKey>;
};

export type CreateMatchInput = {
  categoryId: Scalars['ID'];
  competitorIds: Array<Scalars['ID']>;
  finishAt: Maybe<Scalars['AWSDateTime']>;
  id: Maybe<Scalars['ID']>;
  liveAt: Scalars['AWSDateTime'];
  mediaUrls: MatchMediaUrlsInput;
  metadata: Maybe<MatchMetadataInput>;
  shortTitle: Maybe<LocalizedStringsInput>;
  sportKey: Maybe<SportKey>;
  title: LocalizedStringsInput;
};

export type CreatePlayerProfileInput = {
  avatarImageUrl: Scalars['String'];
  id: Maybe<Scalars['ID']>;
  name: LocalizedStringsInput;
  shortName: Maybe<LocalizedStringsInput>;
  sportId: Scalars['ID'];
  teamProfileId: Maybe<Scalars['ID']>;
};

export type CreateSportInput = {
  id: Maybe<Scalars['ID']>;
  mediaUrls: SportMediaUrlsInput;
  name: LocalizedStringsInput;
  sportKey: Maybe<SportKey>;
};

export type CreateStreamerInput = {
  categoryIds: Maybe<Array<Scalars['ID']>>;
  contentImageUrlCandidates: Maybe<Array<Scalars['String']>>;
  description: LocalizedStringsInput;
  imageUrl: Scalars['String'];
  metadata: Maybe<Scalars['AWSJSON']>;
  name: LocalizedStringsInput;
  sortPriority: Maybe<Scalars['Float']>;
  tags: Array<Scalars['String']>;
  userId: Scalars['ID'];
};

export type CreateTeamProfileInput = {
  avatarImageUrl: Scalars['String'];
  id: Maybe<Scalars['ID']>;
  leagueProfileId: Maybe<Scalars['ID']>;
  name: LocalizedStringsInput;
  shortName: Maybe<LocalizedStringsInput>;
  sportId: Scalars['ID'];
};

export type CreateUserMetadataAndroidInput = {
  bundleId: Scalars['ID'];
  appBuildConfiguration: Scalars['ID'];
};

export type CreateUserMetadataInput = {
  ios: Maybe<CreateUserMetadataIosInput>;
  web: Maybe<CreateUserMetadataWebInput>;
  android: Maybe<CreateUserMetadataAndroidInput>;
};

export type CreateUserMetadataIosInput = {
  appBuildConfiguration: Scalars['ID'];
  bundleId: Scalars['ID'];
};

export type CreateUserMetadataWebInput = {
  browser: Maybe<Scalars['String']>;
  os: Maybe<Scalars['String']>;
  appBuildConfiguration: Maybe<Scalars['String']>;
};

export type CreateUserWithCognitoUuidInput = {
  dateOfBirth: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  metadata: Maybe<CreateUserMetadataInput>;
  nickname: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  receiveEmail: Scalars['Boolean'];
  referralCode: Scalars['String'];
  termAndConditionId: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  country: UserCountryCodeIso1Alpha2;
  city: Scalars['String'];
};

export type DateTimeRangeInput = {
  gt: Maybe<Scalars['AWSDateTime']>;
  gte: Maybe<Scalars['AWSDateTime']>;
  lt: Maybe<Scalars['AWSDateTime']>;
  lte: Maybe<Scalars['AWSDateTime']>;
};

export type DepositInput = {
  amount: Scalars['Int'];
  transactionUUID: Scalars['String'];
};

export enum EventLevel {
  Error = 'ERROR',
  Info = 'INFO',
  Notice = 'NOTICE',
  Warn = 'WARN',
}

export type EveryMatrixGetPendingWithdrawalTransactionCodeOutput = {
  __typename?: 'EveryMatrixGetPendingWithdrawalTransactionCodeOutput';
  amount: Scalars['Float'];
  createdAt: Scalars['AWSDateTime'];
  transactionCode: Scalars['ID'];
};

export type EveryMatrixInitDepositInput = {
  amount: Scalars['Float'];
};

export type EveryMatrixInitWithdrawInput = {
  amount: Scalars['Float'];
  source: Maybe<Scalars['String']>;
};

export type EveryMatrixProcessWithdrawInput = {
  transactionCode: Scalars['String'];
};

export enum EveryMatrixTransactionStatus {
  Authorized = 'AUTHORIZED',
  Canceled = 'CANCELED',
  Error = 'ERROR',
  PendingApproval = 'PENDING_APPROVAL',
  PendingConfirmation = 'PENDING_CONFIRMATION',
  PendingNotification = 'PENDING_NOTIFICATION',
  Rejected = 'REJECTED',
  Started = 'STARTED',
  Success = 'SUCCESS',
  Voided = 'VOIDED',
}

export type FaceIdAuthTermAndConditionOutput = {
  __typename?: 'FaceIdAuthTermAndConditionOutput';
  success: Maybe<Scalars['Boolean']>;
};

export type FinishLiveStreamingContentInput = {
  id: Scalars['ID'];
};

export type FootballMatchSummary = MatchSummary & {
  __typename?: 'FootballMatchSummary';
  createdAt: Scalars['AWSDateTime'];
  currentPhase: Maybe<FootballPhaseType>;
  id: Scalars['ID'];
  currentPhaseStartedAt: Maybe<Scalars['AWSDateTime']>;
  score: Maybe<Score>;
  sportKey: SportKey;
  updatedAt: Scalars['AWSDateTime'];
};

export enum FootballPhaseType {
  ExtratimeFirstHalf = 'EXTRATIME_FIRST_HALF',
  ExtratimeHalfTime = 'EXTRATIME_HALF_TIME',
  ExtratimeSecondHalf = 'EXTRATIME_SECOND_HALF',
  FirstHalf = 'FIRST_HALF',
  FulltimeExtraTime = 'FULLTIME_EXTRA_TIME',
  FulltimeNormalTime = 'FULLTIME_NORMAL_TIME',
  Halftime = 'HALFTIME',
  MatchAbandoned = 'MATCH_ABANDONED',
  Penalties = 'PENALTIES',
  Postmatch = 'POSTMATCH',
  Prematch = 'PREMATCH',
  SecondHalf = 'SECOND_HALF',
}

export type FootballScoreSummaryInput = {
  isCollected: Maybe<Scalars['Boolean']>;
  isReliable: Maybe<Scalars['Boolean']>;
  score: ScoreInput;
};

export type FootballSummaryInput = {
  blockedShots: Maybe<FootballScoreSummaryInput>;
  corners: Maybe<FootballScoreSummaryInput>;
  currentPhase: Maybe<FootballPhaseType>;
  customerId: Scalars['ID'];
  fixtureId: Scalars['ID'];
  fouls: Maybe<FootballScoreSummaryInput>;
  goalKicks: Maybe<FootballScoreSummaryInput>;
  goals: Maybe<FootballScoreSummaryInput>;
  messageTimestampUtc: Scalars['AWSDateTime'];
  missedPenalties: Maybe<FootballScoreSummaryInput>;
  offsides: Maybe<FootballScoreSummaryInput>;
  penaltiesAwarded: Maybe<FootballScoreSummaryInput>;
  savedPenalties: Maybe<FootballScoreSummaryInput>;
  secondYellowCards: Maybe<FootballScoreSummaryInput>;
  shotsOffTarget: Maybe<FootballScoreSummaryInput>;
  shotsOffWoodwork: Maybe<FootballScoreSummaryInput>;
  shotsOnTarget: Maybe<FootballScoreSummaryInput>;
  straightRedCards: Maybe<FootballScoreSummaryInput>;
  substitutions: Maybe<FootballScoreSummaryInput>;
  throwIns: Maybe<FootballScoreSummaryInput>;
  yellowCards: Maybe<FootballScoreSummaryInput>;
};

export type ForceUpgradeAppVersionConfig = {
  __typename?: 'ForceUpgradeAppVersionConfig';
  id: Scalars['ID'];
  iosVersion: Scalars['String'];
  androidVersion: Scalars['String'];
};

export type FoulCountInput = {
  away: Maybe<Scalars['Int']>;
  home: Maybe<Scalars['Int']>;
};

export type FourwDaoPlayerStatOutput = {
  __typename?: 'FourwDaoPlayerStatOutput';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  lane: Scalars['Int'];
  lapTime: Maybe<Scalars['Float']>;
};

export type FourwDaoPlayerStatsResultOutput = {
  __typename?: 'FourwDaoPlayerStatsResultOutput';
  groupTag: Maybe<PlayerGroupTagOutput>;
  results: Array<FourwDaoPlayerStatOutput>;
};

export enum GamstopStatus {
  Excluded = 'EXCLUDED',
  NotExcluded = 'NOT_EXCLUDED',
}

export type GenerateOnfidoJwtTokenInput = {
  applicantId: Scalars['ID'];
  applicationId: Maybe<Scalars['String']>;
};

export type GeniusSportsMatchSummaryInput = {
  americanFootball: Maybe<AmericanFootballSummaryInput>;
  baseball: Maybe<BaseballSummaryInput>;
  basketball: Maybe<BasketBallSummaryInput>;
  football: Maybe<FootballSummaryInput>;
  leagueOfLegends: Maybe<LeagueOfLegendsSummaryInput>;
  multiSportV2: Maybe<MultiSportV2SummaryInput>;
  tennis: Maybe<TennisSummaryInput>;
};

export type GeniusSportsMedia = {
  __typename?: 'GeniusSportsMedia';
  tracker: Maybe<GeniusSportsTracker>;
  videoStreaming: Maybe<GeniusSportsVideoStreaming>;
};

export type GeniusSportsMediaDrm = {
  __typename?: 'GeniusSportsMediaDrm';
  fairplay: Maybe<Scalars['String']>;
  fairplayCertificate: Maybe<Scalars['String']>;
  playready: Maybe<Scalars['String']>;
  widevine: Maybe<Scalars['String']>;
};

export type GeniusSportsTracker = {
  __typename?: 'GeniusSportsTracker';
  liveTrackerUrl: Scalars['String'];
};

export type GeniusSportsVideoStreaming = {
  __typename?: 'GeniusSportsVideoStreaming';
  drm: Maybe<GeniusSportsMediaDrm>;
  videoStreamingUrl: Scalars['String'];
};

export type GetCampaignsOutput = {
  __typename?: 'GetCampaignsOutput';
  campaigns: Array<Campaign>;
};

export type GetCampaignsParams = {
  keys: Array<CampaignKey>;
};

export type GetCodeActivationEnabledConfigOutPut = {
  __typename?: 'GetCodeActivationEnabledConfigOutPut';
  codeActivationEnabled: Maybe<CodeActivationEnabled>;
};

export type GetCountryFromLatLongOutput = {
  __typename?: 'GetCountryFromLatLongOutput';
  countries: Array<Maybe<Scalars['String']>>;
};

export type GetCountryFromLatLongParams = {
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type GetCountryLanguageOutput = {
  __typename?: 'GetCountryLanguageOutput';
  countryLanguage: Maybe<Scalars['AWSJSON']>;
};

export type GetForceUpgradeAppVersionConfigOutput = {
  __typename?: 'GetForceUpgradeAppVersionConfigOutput';
  forceUpgradeConfig: Maybe<ForceUpgradeAppVersionConfig>;
};

export type GetLiveStreamingChannelConnectionOutput = {
  __typename?: 'GetLiveStreamingChannelConnectionOutput';
  agora: AgoraChannelInformation;
};

export type GetMatchOutput = {
  __typename?: 'GetMatchOutput';
  markets: Array<InplayMarket>;
  match: Maybe<Match>;
};

export type GetMatchPlayerStatsOutput = {
  __typename?: 'GetMatchPlayerStatsOutput';
  playerStatTable: Maybe<MatchPlayerStatsOutput>;
  isUnlocked: Maybe<Scalars['Boolean']>;
  gemPrice: Maybe<Scalars['Int']>;
};

export type GetMyOnfidoBreakdownReasoning = {
  __typename?: 'GetMyOnfidoBreakdownReasoning';
  reason: OnfidoReportBreakDownReasoningOutput;
};

export type GetMyOnfidoBreakdownReasoningV2 = {
  __typename?: 'GetMyOnfidoBreakdownReasoningV2';
  document: Maybe<Array<Scalars['String']>>;
  face: Maybe<Array<Scalars['String']>>;
  address: Maybe<Array<Scalars['String']>>;
  aml: Maybe<Array<Scalars['String']>>;
};

export type GetOnfidoAddressOutput = {
  __typename?: 'GetOnfidoAddressOutput';
  postcode: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  addressLine1: Scalars['String'];
};

export type GetOnfidoCheckParams = {
  applicantId: Scalars['ID'];
  reportNames: Array<Scalars['String']>;
};

export type GetStreamingContentChatMessagesOutput = {
  __typename?: 'GetStreamingContentChatMessagesOutput';
  messages: Array<StreamingContentChatMessage>;
  nextSinceKey: Maybe<Scalars['String']>;
  nextUntilKey: Maybe<Scalars['String']>;
};

export type GetStreamingContentChatMessagesParams = {
  contentId: Maybe<Scalars['ID']>;
  matchId: Scalars['ID'];
  sinceKey: Maybe<Scalars['String']>;
  size: Maybe<Scalars['Int']>;
  untilKey: Maybe<Scalars['String']>;
};

export type GetTermAndConditionEnabledOutput = {
  __typename?: 'GetTermAndConditionEnabledOutput';
  termAndCondition: TermAndCondition;
};

export type GetTermAndConditionEnabledParams = {
  country: Maybe<UserCountryCodeIso1Alpha2>;
};

export type GetUnlockMarketsOutput = {
  __typename?: 'GetUnlockMarketsOutput';
  matchId: Scalars['ID'];
  isUnlockedMarkets: Maybe<Scalars['Boolean']>;
  marketGemPrice: Maybe<Scalars['Int']>;
  isUnlockedPastVideo: Maybe<Scalars['Boolean']>;
  pastVideoGemPrice: Maybe<Scalars['Int']>;
  hidePastVideo: Maybe<Scalars['Boolean']>;
};

export type GetVerificationUrlParams = {
  redirectUrl: Maybe<Scalars['String']>;
};

export type GlobalSettingAppParams = {
  appBuildConfiguration: Scalars['ID'];
  bundleId: Scalars['ID'];
};

export type GlobalSettingParams = {
  os: Maybe<Scalars['String']>;
  appConfiguration: Maybe<GlobalSettingAppParams>;
  version: Maybe<Scalars['String']>;
  country: Maybe<UserCountryCodeIso1Alpha2>;
};

export type GlobalSettings = {
  __typename?: 'GlobalSettings';
  maxBetUU: Scalars['Int'];
  maxBetLimitPerBet: Scalars['Int'];
  maxBetLimitPerMarket: Scalars['Int'];
  minimumBetWager: Scalars['Int'];
  minDeposit: Scalars['Int'];
  maxDeposit: Scalars['Int'];
  minWithDraw: Scalars['Int'];
  maxWithDraw: Scalars['Int'];
  availableCountries: Maybe<Array<Scalars['String']>>;
  bonusConfig: Maybe<BonusConfig>;
  countryCodes: Array<Scalars['String']>;
  latestVersion: Maybe<Scalars['String']>;
  myCountryCode: Maybe<Scalars['String']>;
};

export type GroupTag = {
  __typename?: 'GroupTag';
  order: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  isLive: Scalars['Boolean'];
  closeAt: Maybe<Scalars['AWSDateTime']>;
  openAt: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  videoStartsAt: Maybe<Scalars['AWSDateTime']>;
  videoEndsAt: Maybe<Scalars['AWSDateTime']>;
  preVideoUrl: Maybe<Scalars['String']>;
  pastVideoUrl: Maybe<Scalars['String']>;
  thumbnailUrl: Maybe<Scalars['String']>;
  isOddsBoosted: Maybe<Scalars['Boolean']>;
};

export enum HandlePendingValueAction {
  Approve = 'APPROVE',
  Cancel = 'CANCEL',
}

export type HandlePendingValueInput = {
  action: HandlePendingValueAction;
  limitationType: LimitationType;
  term: LimitationTerm;
};

export type HistoriesInput = {
  id: Scalars['ID'];
  isShown: Scalars['Boolean'];
};

export type HistoriesOutput = {
  __typename?: 'HistoriesOutput';
  id: Scalars['ID'];
  isShown: Scalars['Boolean'];
};

export enum HomeAwaySide {
  Away = 'AWAY',
  Home = 'HOME',
  Unknown = 'UNKNOWN',
}

export enum IdVerificationStatus {
  Complete = 'COMPLETE',
  NotVerified = 'NOT_VERIFIED',
  Rejected = 'REJECTED',
  Waiting = 'WAITING',
}

export type InplayHalfModalState = {
  __typename?: 'InplayHalfModalState';
  activeModal: InplayHalfModalType;
  marketId: Maybe<Scalars['ID']>;
};

export enum InplayHalfModalType {
  Default = 'DEFAULT',
  MarketTarget = 'MARKET_TARGET',
  Stats = 'STATS',
}

export type InplayMarket = {
  __typename?: 'InplayMarket';
  closeAt: Maybe<Scalars['AWSDateTime']>;
  confirmedAt: Maybe<Scalars['AWSDateTime']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isConfirmed: Scalars['Boolean'];
  isOpen: Scalars['Boolean'];
  isSettled: Maybe<Scalars['Boolean']>;
  isWon: Maybe<Scalars['Boolean']>;
  isValid: Scalars['Boolean'];
  isVoid: Maybe<Scalars['Boolean']>;
  matchId: Scalars['ID'];
  mediaUrls: InplayMarketMediaUrls;
  openAt: Maybe<Scalars['AWSDateTime']>;
  options: Array<InplayMarketOption>;
  settledAt: Maybe<Scalars['AWSDateTime']>;
  shortTitle: Maybe<LocalizedStrings>;
  targetProfiles: Maybe<Array<Profile>>;
  title: LocalizedStrings;
  updatedAt: Scalars['AWSDateTime'];
  voidAt: Maybe<Scalars['AWSDateTime']>;
  weight: Maybe<Scalars['Float']>;
  disableBet: Scalars['Boolean'];
  disable: Scalars['Boolean'];
  isOddsBoosted: Maybe<Scalars['Boolean']>;
  riskControlProperties: Maybe<RiskControlProperties>;
};

export type InplayMarketBatchResponse = {
  __typename?: 'InplayMarketBatchResponse';
  closeAt: Maybe<Scalars['AWSDateTime']>;
  confirmedAt: Maybe<Scalars['AWSDateTime']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isConfirmed: Scalars['Boolean'];
  isOpen: Scalars['Boolean'];
  isSettled: Maybe<Scalars['Boolean']>;
  isValid: Scalars['Boolean'];
  isVoid: Maybe<Scalars['Boolean']>;
  matchId: Scalars['ID'];
  mediaUrls: InplayMarketMediaUrls;
  openAt: Maybe<Scalars['AWSDateTime']>;
  optionIds: Array<Scalars['ID']>;
  settledAt: Maybe<Scalars['AWSDateTime']>;
  shortTitle: Maybe<LocalizedStrings>;
  targetProfileIds: Maybe<Array<Scalars['ID']>>;
  title: LocalizedStrings;
  updatedAt: Scalars['AWSDateTime'];
  voidAt: Maybe<Scalars['AWSDateTime']>;
};

export type InplayMarketMediaUrls = {
  __typename?: 'InplayMarketMediaUrls';
  backgroundLargeImageUrl: Scalars['String'];
  backgroundSmallImageUrl: Scalars['String'];
};

export type InplayMarketMediaUrlsInput = {
  backgroundLargeImageUrl: Scalars['String'];
  backgroundSmallImageUrl: Scalars['String'];
};

export type InplayMarketOption = {
  __typename?: 'InplayMarketOption';
  id: Scalars['ID'];
  isValid: Scalars['Boolean'];
  isWon: Maybe<Scalars['Boolean']>;
  marketId: Scalars['ID'];
  odds: Odds;
  oldOdds: Maybe<Odds>;
  shortTitle: Maybe<LocalizedStrings>;
  targetProfile: Maybe<Profile>;
  title: LocalizedStrings;
  weight: Maybe<Scalars['Float']>;
};

export type InplayMarketOptionsOutput = {
  __typename?: 'InplayMarketOptionsOutput';
  matchId: Maybe<Scalars['ID']>;
  options: Array<InplayMarketOption>;
};

export enum IntercomPlatform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

export type IntercomUpdateUserInput = {
  appBuildNumber: Scalars['String'];
};

export type IntercomUpdateUserOutput = {
  __typename?: 'IntercomUpdateUserOutput';
  success: Maybe<Scalars['Boolean']>;
};

export type InvalidateInplayMarketOptionsInput = {
  invalidOptionIds: Array<Scalars['ID']>;
  marketId: Scalars['ID'];
};

export type JoinToTalkInput = {
  contentId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type JoinToTalkOutput = {
  __typename?: 'JoinToTalkOutput';
  agora: Maybe<AgoraChannelInformation>;
  channelJoinDetail: Maybe<ChannelJoinDetail>;
};

export type LeagueOfLegendsSummaryInput = {
  customerId: Scalars['ID'];
  fixtureId: Scalars['ID'];
  messageTimestampUtc: Scalars['AWSDateTime'];
};

export type LeagueProfile = Profile & {
  __typename?: 'LeagueProfile';
  avatarImageUrl: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isFollowing: Maybe<Scalars['Boolean']>;
  name: LocalizedStrings;
  shortName: Maybe<LocalizedStrings>;
  updatedAt: Scalars['AWSDateTime'];
};

export type LimitationAllowed = {
  __typename?: 'LimitationAllowed';
  allowedByDay: Maybe<Scalars['Boolean']>;
  allowedByMonth: Maybe<Scalars['Boolean']>;
  allowedByWeek: Maybe<Scalars['Boolean']>;
};

export type LimitationSettings = {
  __typename?: 'LimitationSettings';
  betLimitations: LimitationValues;
  depositLimitations: LimitationValues;
  lossLimitations: LimitationValues;
};

export enum LimitationTerm {
  TermDays = 'TERM_DAYS',
  TermMonths = 'TERM_MONTHS',
  TermWeeks = 'TERM_WEEKS',
}

export enum LimitationType {
  Bet = 'BET',
  Deposit = 'DEPOSIT',
  Loss = 'LOSS',
}

export type LimitationValue = {
  __typename?: 'LimitationValue';
  pendingValue: Maybe<PendingLimitationValue>;
  value: Scalars['Int'];
};

export type LimitationValues = {
  __typename?: 'LimitationValues';
  daily: Maybe<LimitationValue>;
  monthly: Maybe<LimitationValue>;
  weekly: Maybe<LimitationValue>;
};

export type ListMarketResultsByMatchOutput = {
  __typename?: 'ListMarketResultsByMatchOutput';
  marketGroups: Array<MarketGroup>;
};

export type ListMarketsByMatchOutput = {
  __typename?: 'ListMarketsByMatchOutput';
  markets: Array<InplayMarket>;
  profit: Maybe<Scalars['Float']>;
  totalBets: Maybe<Scalars['Float']>;
  totalReturns: Maybe<Scalars['Float']>;
};

export type ListMarketsByMatchV3Output = {
  __typename?: 'ListMarketsByMatchV3Output';
  totalReturns: Maybe<Scalars['Float']>;
  totalBets: Maybe<Scalars['Float']>;
  profit: Maybe<Scalars['Float']>;
  marketGroups: Array<MarketGroup>;
};

export type ListMarketsParams = {
  showOnlyOpenMarkets: Maybe<Scalars['Boolean']>;
};

export type ListMatchGradesParams = {
  categoryId: Maybe<Scalars['ID']>;
};

export type ListSportParams = {
  country: Maybe<UserCountryCodeIso1Alpha2>;
};

export type LiveStreamingChannelSettingInput = {
  allowCustomersJoin: Scalars['Boolean'];
};

export type LiveStreamingContent = StreamingContent & {
  __typename?: 'LiveStreamingContent';
  audienceCount: Maybe<Scalars['Int']>;
  categoryIds: Maybe<Array<Scalars['ID']>>;
  createdAt: Scalars['AWSDateTime'];
  description: LocalizedStrings;
  finishedAt: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  liveAt: Scalars['AWSDateTime'];
  liveStartedAt: Maybe<Scalars['AWSDateTime']>;
  matchId: Maybe<Scalars['ID']>;
  metadata: Maybe<Scalars['AWSJSON']>;
  playlistId: Maybe<Scalars['ID']>;
  randomSortPriority: Maybe<Scalars['Float']>;
  sortPriority: Maybe<Scalars['Float']>;
  sportId: Maybe<Scalars['ID']>;
  status: StreamingContentStatus;
  streamer: Streamer;
  streamingMatchId: Maybe<Scalars['ID']>;
  tags: Array<Scalars['String']>;
  title: LocalizedStrings;
  type: StreamingContentType;
  updatedAt: Maybe<Scalars['AWSDateTime']>;
  version: Scalars['Int'];
  isKilled: Maybe<Scalars['Boolean']>;
};

export type LocalizedStrings = {
  __typename?: 'LocalizedStrings';
  en_DEFAULT: Maybe<Scalars['String']>;
  en_GB: Maybe<Scalars['String']>;
  en_US: Maybe<Scalars['String']>;
  en_IE: Maybe<Scalars['String']>;
  ja_JP: Maybe<Scalars['String']>;
};

export type LocalizedStringsInput = {
  en_GB: Maybe<Scalars['String']>;
  en_US: Maybe<Scalars['String']>;
  ja_JP: Maybe<Scalars['String']>;
};

export type ManagerProfile = Profile & {
  __typename?: 'ManagerProfile';
  avatarImageUrl: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isFollowing: Maybe<Scalars['Boolean']>;
  name: LocalizedStrings;
  shortName: Maybe<LocalizedStrings>;
  teamProfile: Maybe<TeamProfile>;
  updatedAt: Scalars['AWSDateTime'];
};

export type MarketGroup = {
  __typename?: 'MarketGroup';
  groupTag: Maybe<GroupTag>;
  fourwDao: Maybe<Scalars['String']>;
  sumoDao: Maybe<Scalars['String']>;
  yadokariDao: Maybe<Scalars['String']>;
  markets: Array<InplayMarket>;
};

export type Match = {
  __typename?: 'Match';
  cancelDetail: Maybe<MatchCancelDetail>;
  category: MatchCategory;
  competitors: Array<Profile>;
  createdAt: Scalars['AWSDateTime'];
  feedStatus: MatchFeedStatus;
  finishAt: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  isCanceled: Scalars['Boolean'];
  isLive: Scalars['Boolean'];
  isOpen: Scalars['Boolean'];
  ivsStatus: Maybe<MatchIvsStatus>;
  liveAt: Scalars['AWSDateTime'];
  liveEndAt: Maybe<Scalars['AWSDateTime']>;
  liveStartedAt: Maybe<Scalars['AWSDateTime']>;
  liveStatus: MatchLiveStatus;
  mediaSourceService: Maybe<MediaSourceService>;
  mediaUrls: MatchMediaUrls;
  metadata: Maybe<Scalars['AWSJSON']>;
  openMarketsCount: Maybe<Scalars['Int']>;
  shortTitle: Maybe<LocalizedStrings>;
  simplifiedSummary: Maybe<SimplifiedMatchSummary>;
  sportKey: Maybe<SportKey>;
  stats: Maybe<Scalars['AWSJSON']>;
  streamingContentIds: Maybe<Array<Scalars['ID']>>;
  title: LocalizedStrings;
  updatedAt: Scalars['AWSDateTime'];
};

export enum MatchCancelDetail {
  Abandoned = 'ABANDONED',
  Canceled = 'CANCELED',
  Postponed = 'POSTPONED',
  Suspended = 'SUSPENDED',
}

export type MatchCategory = {
  __typename?: 'MatchCategory';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  mediaUrls: SportMediaUrls;
  name: LocalizedStrings;
  sport: Maybe<Sport>;
  updatedAt: Scalars['AWSDateTime'];
};

export enum MatchFeedStatus {
  Blocked = 'BLOCKED',
  Normal = 'NORMAL',
  Restricted = 'RESTRICTED',
}

export type MatchGrade = {
  __typename?: 'MatchGrade';
  id: Scalars['ID'];
  name: Maybe<LocalizedStrings>;
  createdAt: Maybe<Scalars['AWSDateTime']>;
  colorCode: Maybe<Scalars['String']>;
  point: Scalars['Int'];
  gemPrice: Maybe<Scalars['Int']>;
  pastVideoGemPrice: Maybe<Scalars['Int']>;
  hidePastVideo: Maybe<Scalars['Boolean']>;
  categoryId: Maybe<Scalars['ID']>;
};

export enum MatchIvsStatus {
  Finished = 'FINISHED',
  Started = 'STARTED',
}

export enum MatchLiveStatus {
  Finish = 'FINISH',
  Live = 'LIVE',
  LiveSoon = 'LIVE_SOON',
  NotStarted = 'NOT_STARTED',
}

export type MatchMediaUrls = {
  __typename?: 'MatchMediaUrls';
  fallbackImageUrl: Maybe<Scalars['String']>;
  logoImageUrl: Maybe<Scalars['String']>;
  videoStreamingFallbackImageUrl: Maybe<Scalars['AWSURL']>;
  videoStreamingUrl: Maybe<Scalars['AWSURL']>;
};

export type MatchMediaUrlsInput = {
  fallbackImageUrl: Scalars['String'];
  logoImageUrl: Maybe<Scalars['String']>;
  videoStreamingUrl: Maybe<Scalars['String']>;
};

export type MatchMetadataInput = {
  fixtureId: Scalars['ID'];
  sourceService: SourceService;
};

export type MatchPlayerStatsOutput = {
  __typename?: 'MatchPlayerStatsOutput';
  id: Scalars['ID'];
  results: Array<PlayerStatsTable>;
};

export type MatchPriorityPerUserInput = {
  matchId: Scalars['ID'];
  priority: Maybe<Scalars['Float']>;
};

export type MatchSummary = {
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  sportKey: SportKey;
  updatedAt: Scalars['AWSDateTime'];
};

export type MatchWalletHistory = WalletHistory & {
  __typename?: 'MatchWalletHistory';
  amount: Maybe<Scalars['Int']>;
  bet: BetListV2Output;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  match: SimplifiedMatch;
  transactionId: Maybe<Scalars['ID']>;
  transactionType: TransactionType;
  isShown: Scalars['Boolean'];
  text: Maybe<Scalars['String']>;
  transactionText: Maybe<Scalars['String']>;
};

export enum MediaSourceService {
  AwsIvs = 'AWS_IVS',
  GeniusSports = 'GENIUS_SPORTS',
  Non = 'NON',
  Twitch = 'TWITCH',
}

export type ModelInput = {
  id: Scalars['ID'];
  modelType: ModelType;
};

export enum ModelType {
  Bet = 'BET',
  InplayMarket = 'INPLAY_MARKET',
  InplayMarketOption = 'INPLAY_MARKET_OPTION',
  Match = 'MATCH',
  MatchCategory = 'MATCH_CATEGORY',
  Other = 'OTHER',
  Profile = 'PROFILE',
  Sport = 'SPORT',
  StreamingContent = 'STREAMING_CONTENT',
  User = 'USER',
}

export type MultiSportV2SummaryInput = {
  customerId: Scalars['ID'];
  fixtureId: Scalars['ID'];
  messageTimestampUtc: Scalars['AWSDateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addUserFirebaseRegistrationToken: Maybe<Scalars['Boolean']>;
  becomeAudience: Maybe<JoinToTalkOutput>;
  bet: BetOutput;
  betV2: BetOutput;
  saveWalletHistoryIsShown: SaveWalletHistoryIsShownOutput;
  cancelLiveStreamingContent: Maybe<CancelLiveStreamingContentOutput>;
  closeAccount: User;
  completeOnfidoCheck: Maybe<UserIdVerificationStatus>;
  confirmMyBetResult: Scalars['Boolean'];
  createLiveStreamingContent: Maybe<StreamingContent>;
  createOnfidoCheck: Scalars['Boolean'];
  createOnfidoCheckV2: Scalars['Boolean'];
  createUserWithCognitoUUID: User;
  deposit: Maybe<Wallet>;
  everyMatrixApproveWithdraw: Maybe<Scalars['AWSJSON']>;
  everyMatrixInitDeposit: Maybe<Scalars['AWSJSON']>;
  everyMatrixInitWithdraw: Maybe<Scalars['AWSJSON']>;
  everyMatrixRejectWithdraw: Maybe<Scalars['AWSJSON']>;
  finishLiveStreamingContent: StreamingContent;
  follow: Profile;
  handlePendingLimitationValue: UserSettings;
  invalidateInplayMarketOptions: Maybe<InplayMarket>;
  joinLiveStreamingChannelCustomer: Maybe<JoinToTalkOutput>;
  leaveLiveStreamingChannel: Maybe<JoinToTalkOutput>;
  notifyLivePublishingIsStartedV2: StreamingContent;
  notifyMarketsUpdated: Maybe<NotifyMarketsUpdatedOutput>;
  notifyMarketsUpdatedV2: Maybe<NotifyMarketsUpdatedV2Output>;
  notifyMatchesUpdated: Maybe<NotifyMatchesUpdatedOutput>;
  notifyVerificationStatusUpdated: Maybe<UserVerificationStatusRealTimeOutput>;
  removeUserFirebaseRegistrationToken: Maybe<Scalars['Boolean']>;
  reportStreamingContentChatMessage: Maybe<Scalars['ID']>;
  saveCategoryPriorityPerUser: Maybe<Scalars['Boolean']>;
  saveLimitationSettings: UserSettings;
  saveMatchPriorityPerUser: Maybe<Scalars['Boolean']>;
  saveSelfExclusion: Maybe<UserSettings>;
  saveTimeout: Maybe<UserSettings>;
  saveRealityCheck: Maybe<UserSettings>;
  sendStreamingContentChatMessage: Maybe<StreamingContentChatMessage>;
  sendUserActivity: Maybe<Scalars['Boolean']>;
  startLiveStreamingContentV2: Maybe<StartLiveStreamingContentOutputV2>;
  startOnfidoVerification: Maybe<StartOnfidoVerificationOutput>;
  startOnfidoVerificationV2: Maybe<StartOnfidoVerificationOutput>;
  unfollow: Profile;
  updateLiveStreamingContent: Maybe<StreamingContent>;
  updateUserProperties: User;
  withdraw: Maybe<Wallet>;
  notifyWalletUpdated: Maybe<NotifyWalletUpdatedOutput>;
  acceptTermAndCondition: Maybe<AcceptTermAndConditionOutput>;
  unlockMatchGroupPlayerStat: Maybe<UnlockedMatchGroupPlayerStatOutput>;
  unlockMarkets: Maybe<UnlockedMarketsOutput>;
  unlockPastVideo: Maybe<UnlockedPastVideoOutput>;
  submitActivationCode: Maybe<SubmitActivationCodeOutput>;
  requestActivationCode: Maybe<RequestActivationCodeOutput>;
  faceIdAuthAcceptTermAndCondition: Maybe<FaceIdAuthTermAndConditionOutput>;
  intercomUpdateUser: Maybe<IntercomUpdateUserOutput>;
  completeUserSignInBonus: Maybe<CompleteUserSignInBonusOutput>;
};

export type MutationAddUserFirebaseRegistrationTokenArgs = {
  firebaseRegistrationToken: Scalars['ID'];
};

export type MutationBecomeAudienceArgs = {
  contentId: Scalars['ID'];
};

export type MutationBetArgs = {
  input: BetInput;
};

export type MutationBetV2Args = {
  input: BetV2Input;
};

export type MutationSaveWalletHistoryIsShownArgs = {
  input: SaveWalletHistoryIsShownInput;
};

export type MutationCancelLiveStreamingContentArgs = {
  input: CancelLiveStreamingContentInput;
};

export type MutationCloseAccountArgs = {
  input: CloseAccountInput;
};

export type MutationCompleteOnfidoCheckArgs = {
  userId: Scalars['ID'];
};

export type MutationConfirmMyBetResultArgs = {
  input: ConfirmMyBetResultInput;
};

export type MutationCreateLiveStreamingContentArgs = {
  input: CreateLiveStreamingContentInput;
};

export type MutationCreateOnfidoCheckArgs = {
  input: Maybe<CreateCheckOnfidoInput>;
};

export type MutationCreateOnfidoCheckV2Args = {
  input: Maybe<CreateCheckOnfidoV2Input>;
};

export type MutationCreateUserWithCognitoUuidArgs = {
  input: Maybe<CreateUserWithCognitoUuidInput>;
};

export type MutationDepositArgs = {
  input: DepositInput;
};

export type MutationEveryMatrixApproveWithdrawArgs = {
  input: EveryMatrixProcessWithdrawInput;
};

export type MutationEveryMatrixInitDepositArgs = {
  input: EveryMatrixInitDepositInput;
};

export type MutationEveryMatrixInitWithdrawArgs = {
  input: EveryMatrixInitWithdrawInput;
};

export type MutationEveryMatrixRejectWithdrawArgs = {
  input: Maybe<EveryMatrixProcessWithdrawInput>;
};

export type MutationFinishLiveStreamingContentArgs = {
  input: FinishLiveStreamingContentInput;
};

export type MutationFollowArgs = {
  profileId: Scalars['ID'];
};

export type MutationHandlePendingLimitationValueArgs = {
  input: HandlePendingValueInput;
};

export type MutationInvalidateInplayMarketOptionsArgs = {
  input: InvalidateInplayMarketOptionsInput;
  matchId: Scalars['ID'];
};

export type MutationJoinLiveStreamingChannelCustomerArgs = {
  contentId: Scalars['ID'];
};

export type MutationLeaveLiveStreamingChannelArgs = {
  contentId: Scalars['ID'];
};

export type MutationNotifyLivePublishingIsStartedV2Args = {
  contentId: Scalars['ID'];
};

export type MutationNotifyMarketsUpdatedArgs = {
  ids: Array<Scalars['ID']>;
  matchId: Maybe<Scalars['ID']>;
};

export type MutationNotifyMarketsUpdatedV2Args = {
  ids: Array<Scalars['ID']>;
  matchId: Maybe<Scalars['ID']>;
};

export type MutationNotifyMatchesUpdatedArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationNotifyVerificationStatusUpdatedArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveUserFirebaseRegistrationTokenArgs = {
  firebaseRegistrationToken: Scalars['ID'];
};

export type MutationReportStreamingContentChatMessageArgs = {
  input: ReportStreamingContentChatMessageInput;
};

export type MutationSaveCategoryPriorityPerUserArgs = {
  input: Maybe<SaveCategoryPriorityPerUserInput>;
};

export type MutationSaveLimitationSettingsArgs = {
  input: SaveLimitationSettingsInput;
};

export type MutationSaveMatchPriorityPerUserArgs = {
  input: Maybe<SaveMatchPriorityPerUserInput>;
};

export type MutationSaveSelfExclusionArgs = {
  input: SaveSelfExclusionInput;
};

export type MutationSaveTimeoutArgs = {
  input: SaveTimeoutInput;
};

export type MutationSaveRealityCheckArgs = {
  input: SaveRealityCheckInput;
};

export type MutationSendStreamingContentChatMessageArgs = {
  contentId: Maybe<Scalars['ID']>;
  matchId: Scalars['ID'];
  message: Scalars['String'];
};

export type MutationSendUserActivityArgs = {
  input: SendUserActivityInput;
};

export type MutationStartLiveStreamingContentV2Args = {
  input: StartLiveStreamingContentInput;
};

export type MutationStartOnfidoVerificationArgs = {
  input: StartOnfidoVerificationInput;
};

export type MutationStartOnfidoVerificationV2Args = {
  input: StartOnfidoVerificationInput;
};

export type MutationUnfollowArgs = {
  profileId: Scalars['ID'];
};

export type MutationUpdateLiveStreamingContentArgs = {
  input: UpdateLiveStreamingContentInput;
};

export type MutationUpdateUserPropertiesArgs = {
  input: UpdateUserPropertiesInput;
};

export type MutationWithdrawArgs = {
  input: WithdrawInput;
};

export type MutationNotifyWalletUpdatedArgs = {
  id: Scalars['ID'];
};

export type MutationAcceptTermAndConditionArgs = {
  input: AcceptTermAndConditionInput;
};

export type MutationUnlockMatchGroupPlayerStatArgs = {
  id: Scalars['ID'];
};

export type MutationUnlockMarketsArgs = {
  id: Scalars['ID'];
};

export type MutationUnlockPastVideoArgs = {
  id: Scalars['ID'];
};

export type MutationSubmitActivationCodeArgs = {
  input: SubmitActivationCodeInput;
};

export type MutationIntercomUpdateUserArgs = {
  input: IntercomUpdateUserInput;
};

export type MyBetsHistoryParams = {
  confirmationStatus: Maybe<ConfirmationStatus>;
  dateRange: Maybe<RangeParams>;
  marketId: Maybe<Scalars['ID']>;
  matchId: Maybe<Scalars['ID']>;
  size: Maybe<Scalars['Int']>;
  startKey: Maybe<Scalars['String']>;
  status: Maybe<BetStatus>;
};

export type MyNetDepositParams = {
  dateRange: Maybe<RangeParams>;
};

export type MySignedUrlOutput = {
  __typename?: 'MySignedUrlOutput';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type MyTransactionSummaryParams = {
  dateRange: Maybe<RangeParams>;
  size: Maybe<Scalars['Int']>;
  startKey: Maybe<Scalars['String']>;
};

export type MyWalletsHistoryParams = {
  dateRange: Maybe<RangeParams>;
  size: Maybe<Scalars['Int']>;
  startKey: Maybe<Scalars['String']>;
  transactionTypes: Array<TransactionType>;
};

export type NetDepositBySource = {
  __typename?: 'NetDepositBySource';
  netDeposit: Scalars['Int'];
  source: Maybe<Scalars['String']>;
  totalDeposit: Scalars['Int'];
  totalWithdrawal: Scalars['Int'];
  isAvailable: Scalars['Boolean'];
};

export type NetDepositOutput = {
  __typename?: 'NetDepositOutput';
  netDeposit: Scalars['Int'];
  netDepositsBySource: Maybe<Array<NetDepositBySource>>;
  totalDeposit: Scalars['Int'];
  totalWithdrawal: Scalars['Int'];
};

export type NotifyMarketsUpdatedOutput = {
  __typename?: 'NotifyMarketsUpdatedOutput';
  markets: Array<InplayMarket>;
  matchId: Maybe<Scalars['ID']>;
};

export type NotifyMarketsUpdatedV2Output = {
  __typename?: 'NotifyMarketsUpdatedV2Output';
  matchId: Maybe<Scalars['ID']>;
  marketGroups: Array<MarketGroup>;
};

export type NotifyMatchesUpdatedOutput = {
  __typename?: 'NotifyMatchesUpdatedOutput';
  matches: Array<Match>;
};

export type NotifyWalletUpdatedOutput = {
  __typename?: 'NotifyWalletUpdatedOutput';
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
};

export type Odds = {
  __typename?: 'Odds';
  updatedAt: Scalars['AWSDateTime'];
  value: Scalars['Float'];
};

export type OddsInput = {
  value: Scalars['Float'];
};

export type OnfidoReportBreakDownReasoningOutput = {
  __typename?: 'OnfidoReportBreakDownReasoningOutput';
  document: Maybe<OnfidoReportDocumentBreakDownReasoning>;
  facialSimilarityVideo: Maybe<OnfidoReportFacialSimilarityVideoBreakDownReasoning>;
  facialSimilarityMotion: Maybe<OnfidoReportFacialSimilarityVideoBreakDownReasoning>;
  identityEnhanced: Maybe<Scalars['String']>;
  watchlistAml: Maybe<Scalars['String']>;
  proofOfAddress: Maybe<OnfidoReportProofOfAddressBreakDownReasoning>;
};

export type OnfidoReportDocumentBreakDownReasoning = {
  __typename?: 'OnfidoReportDocumentBreakDownReasoning';
  ageValidation: Maybe<Scalars['String']>;
  compromisedDocument: Maybe<Scalars['String']>;
  dataComparison: Maybe<OnfidoReportDocumentDataComparisonBreakDown>;
  dataConsistency: Maybe<Scalars['String']>;
  dataValidation: Maybe<Scalars['String']>;
  imageIntegrity: Maybe<OnfidoReportDocumentImageIntegrityBreakDown>;
  policeRecord: Maybe<Scalars['String']>;
  visualAuthenticity: Maybe<Scalars['String']>;
  issuingAuthority: Maybe<Scalars['String']>;
};

export type OnfidoReportDocumentDataComparisonBreakDown = {
  __typename?: 'OnfidoReportDocumentDataComparisonBreakDown';
  gender: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  dateOfBirth: Maybe<Scalars['String']>;
};

export type OnfidoReportDocumentImageIntegrityBreakDown = {
  __typename?: 'OnfidoReportDocumentImageIntegrityBreakDown';
  imageQuality: Maybe<Scalars['String']>;
  conclusiveDocumentQuality: Maybe<Scalars['String']>;
  colourPicture: Maybe<Scalars['String']>;
  supportedDocument: Maybe<Scalars['String']>;
};

export type OnfidoReportFacialSimilarityVideoBreakDownReasoning = {
  __typename?: 'OnfidoReportFacialSimilarityVideoBreakDownReasoning';
  imageIntegrity: Maybe<Scalars['String']>;
  faceComparison: Maybe<Scalars['String']>;
  visualAuthenticity: Maybe<Scalars['String']>;
};

export type OnfidoReportProofOfAddressBreakDownReasoning = {
  __typename?: 'OnfidoReportProofOfAddressBreakDownReasoning';
  imageIntegrity: Maybe<Scalars['String']>;
  dataComparison: Maybe<Scalars['String']>;
  sourceIntegrity: Maybe<Scalars['String']>;
  documentClassification: Maybe<Scalars['String']>;
};

export type PendingLimitationValue = {
  __typename?: 'PendingLimitationValue';
  coolingOffEndsAt: Maybe<Scalars['AWSDateTime']>;
  createdAt: Scalars['AWSDateTime'];
  isUnderCoolingOff: Scalars['Boolean'];
  value: Scalars['Int'];
};

export type PlayerGroupTagOutput = {
  __typename?: 'PlayerGroupTagOutput';
  name: Maybe<Scalars['String']>;
  order: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['ID']>;
};

export type PlayerProfile = Profile & {
  __typename?: 'PlayerProfile';
  avatarImageUrl: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isFollowing: Maybe<Scalars['Boolean']>;
  name: LocalizedStrings;
  shortName: Maybe<LocalizedStrings>;
  teamProfile: Maybe<TeamProfile>;
  updatedAt: Scalars['AWSDateTime'];
};

export type PlayerStatsRow = {
  __typename?: 'PlayerStatsRow';
  values: Array<Scalars['String']>;
};

export type PlayerStatsTable = {
  __typename?: 'PlayerStatsTable';
  groupTag: Maybe<PlayerGroupTagOutput>;
  headers: Array<Scalars['String']>;
  rows: Array<PlayerStatsRow>;
};

export type Profile = {
  avatarImageUrl: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isFollowing: Maybe<Scalars['Boolean']>;
  name: LocalizedStrings;
  shortName: Maybe<LocalizedStrings>;
  updatedAt: Scalars['AWSDateTime'];
};

export type ProfileImageUrls = {
  __typename?: 'ProfileImageUrls';
  large: Maybe<Scalars['String']>;
  small: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  checkIsReviewerPhoneNumber: Scalars['Boolean'];
  checkIsValidUkPostcode: Scalars['Boolean'];
  everyMatrixCheckTransactionStatus: Maybe<EveryMatrixTransactionStatus>;
  everyMatrixGetPendingWithdrawalTransactionCode: Maybe<EveryMatrixGetPendingWithdrawalTransactionCodeOutput>;
  everyMatrixGetPendingWithdrawalsUrl: Maybe<Scalars['AWSJSON']>;
  getAddress: GetOnfidoAddressOutput;
  getBatchInplayMarkets: Array<InplayMarketBatchResponse>;
  getCampaigns: Maybe<GetCampaignsOutput>;
  getCodeActivationEnabled: Maybe<GetCodeActivationEnabledConfigOutPut>;
  getCountryLanguages: Maybe<GetCountryLanguageOutput>;
  getForceUpgradeAppVersionConfig: Maybe<GetForceUpgradeAppVersionConfigOutput>;
  getInplayMarket: Maybe<InplayMarket>;
  getLiveStreamingChannelConnection: Maybe<GetLiveStreamingChannelConnectionOutput>;
  getMatch: Maybe<Match>;
  getMatchGeniusSportsMedia: Maybe<GeniusSportsMedia>;
  getMatchPlayerStats: Maybe<GetMatchPlayerStatsOutput>;
  getMatchSummary: Maybe<MatchSummary>;
  getMyTransactionSummary: TransactionSummaryOutput;
  getOnfidoCheck: Array<Scalars['AWSJSON']>;
  getProfile: Maybe<Profile>;
  getReviewerTemporaryCode: Maybe<Scalars['String']>;
  getStreamer: Maybe<Streamer>;
  getStreamingContent: Maybe<StreamingContent>;
  getStreamingContentAgoraToken: Maybe<Scalars['String']>;
  getStreamingContentChatMessages: GetStreamingContentChatMessagesOutput;
  getTermAndConditionEnabled: Maybe<GetTermAndConditionEnabledOutput>;
  getUnlockMarkets: Maybe<GetUnlockMarketsOutput>;
  getVerificationUrl: Maybe<Scalars['String']>;
  globalSettings: Maybe<GlobalSettings>;
  inplayHalfModalState: InplayHalfModalState;
  listAllMatchCategories: Array<MatchCategory>;
  listMarketResultsByMatch: Maybe<ListMarketResultsByMatchOutput>;
  listMarketsByMatchV2: Maybe<ListMarketsByMatchOutput>;
  listMarketsByMatchV3: Maybe<ListMarketsByMatchV3Output>;
  listMarketsByStreamingContent: Array<InplayMarket>;
  listMatchCategoriesBySport: Array<MatchCategory>;
  listMatchGrades: Array<MatchGrade>;
  listMyBets: BetListOutput;
  listMyBetsV2: BetListV2Output;
  listYoutubeVideos: Array<YoutubeVideo>;
  me: Maybe<User>;
  meAsStreamer: Maybe<Streamer>;
  meV2: Maybe<User>;
  myGamstopStatus: Maybe<GamstopStatus>;
  myIdVerificationStatus: Maybe<UserIdVerificationStatus>;
  myIdVerificationStatusV2: Maybe<UserIdVerificationStatusV2>;
  myIntercomUserHash: Scalars['String'];
  myLimitationAllowed: Maybe<UserLimitationAllowed>;
  myNetDeposit: NetDepositOutput;
  myOnfidoBreakdownReasoning: Maybe<GetMyOnfidoBreakdownReasoning>;
  myOnfidoBreakdownReasoningV2: Maybe<GetMyOnfidoBreakdownReasoningV2>;
  myProfileImageUploadUrl: MySignedUrlOutput;
  mySettings: Maybe<UserSettings>;
  myWallet: Maybe<Wallet>;
  searchBonusConfigs: SearchBonusConfigsOutput;
  searchMatches: SearchMatchesOutput;
  searchMyBetsHistory: BetsOutputs;
  searchMyWalletHistory: WalletHistoriesOutput;
};

export type QueryCheckIsReviewerPhoneNumberArgs = {
  phoneNumber: Maybe<Scalars['String']>;
};

export type QueryCheckIsValidUkPostcodeArgs = {
  input: CheckIsValidPostCodeInput;
};

export type QueryEveryMatrixCheckTransactionStatusArgs = {
  transactionCode: Scalars['String'];
};

export type QueryGetAddressArgs = {
  postalCode: Scalars['String'];
};

export type QueryGetBatchInplayMarketsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryGetCampaignsArgs = {
  params: Maybe<GetCampaignsParams>;
};

export type QueryGetInplayMarketArgs = {
  id: Scalars['ID'];
};

export type QueryGetLiveStreamingChannelConnectionArgs = {
  contentId: Scalars['ID'];
};

export type QueryGetMatchArgs = {
  id: Scalars['ID'];
};

export type QueryGetMatchGeniusSportsMediaArgs = {
  matchId: Maybe<Scalars['ID']>;
};

export type QueryGetMatchPlayerStatsArgs = {
  id: Scalars['ID'];
};

export type QueryGetMatchSummaryArgs = {
  matchId: Scalars['ID'];
};

export type QueryGetMyTransactionSummaryArgs = {
  params: Maybe<MyTransactionSummaryParams>;
};

export type QueryGetOnfidoCheckArgs = {
  params: Maybe<GetOnfidoCheckParams>;
};

export type QueryGetProfileArgs = {
  id: Scalars['ID'];
};

export type QueryGetReviewerTemporaryCodeArgs = {
  phoneNumber: Maybe<Scalars['String']>;
};

export type QueryGetStreamerArgs = {
  streamerId: Scalars['ID'];
};

export type QueryGetStreamingContentArgs = {
  contentId: Scalars['ID'];
};

export type QueryGetStreamingContentAgoraTokenArgs = {
  contentId: Scalars['ID'];
};

export type QueryGetStreamingContentChatMessagesArgs = {
  params: GetStreamingContentChatMessagesParams;
};

export type QueryGetTermAndConditionEnabledArgs = {
  params: Maybe<GetTermAndConditionEnabledParams>;
};

export type QueryGetUnlockMarketsArgs = {
  id: Scalars['ID'];
};

export type QueryGetVerificationUrlArgs = {
  params: Maybe<GetVerificationUrlParams>;
};

export type QueryGlobalSettingsArgs = {
  params: Maybe<GlobalSettingParams>;
};

export type QueryListMarketResultsByMatchArgs = {
  id: Scalars['ID'];
  settledAtAfter: Maybe<Scalars['AWSDateTime']>;
};

export type QueryListMarketsByMatchV2Args = {
  id: Scalars['ID'];
  params: Maybe<ListMarketsParams>;
};

export type QueryListMarketsByMatchV3Args = {
  id: Scalars['ID'];
  params: Maybe<ListMarketsParams>;
};

export type QueryListMarketsByStreamingContentArgs = {
  id: Scalars['ID'];
};

export type QueryListMatchCategoriesBySportArgs = {
  id: Scalars['ID'];
};

export type QueryListMatchGradesArgs = {
  params: Maybe<ListMatchGradesParams>;
};

export type QueryListMyBetsArgs = {
  marketId: Maybe<Scalars['ID']>;
  matchId: Scalars['ID'];
};

export type QueryListMyBetsV2Args = {
  marketId: Maybe<Scalars['ID']>;
  matchId: Scalars['ID'];
};

export type QueryMyIntercomUserHashArgs = {
  platform: IntercomPlatform;
};

export type QueryMyNetDepositArgs = {
  params: Maybe<MyNetDepositParams>;
};

export type QuerySearchBonusConfigsArgs = {
  params: SearchBonusConfigsParams;
};

export type QuerySearchMatchesArgs = {
  params: SearchMatchesParams;
};

export type QuerySearchMyBetsHistoryArgs = {
  params: Maybe<MyBetsHistoryParams>;
};

export type QuerySearchMyWalletHistoryArgs = {
  params: Maybe<MyWalletsHistoryParams>;
};

export type RaceGrade = {
  __typename?: 'RaceGrade';
  name: Maybe<Scalars['String']>;
  colorCode: Maybe<Scalars['String']>;
  textColor: Maybe<Scalars['String']>;
};

export type RangeParams = {
  gte: Maybe<Scalars['AWSDateTime']>;
  lte: Maybe<Scalars['AWSDateTime']>;
};

export type RealityCheck = {
  __typename?: 'RealityCheck';
  enable: Scalars['Boolean'];
  frequency: Maybe<RealityCheckFrequency>;
};

export enum RealityCheckFrequency {
  Frequency_10Minutes = 'FREQUENCY_10_MINUTES',
  Frequency_30Minutes = 'FREQUENCY_30_MINUTES',
  Frequency_1Hours = 'FREQUENCY_1_HOURS',
}

export type ReportStreamingContentChatMessageInput = {
  messageId: Scalars['ID'];
};

export type RequestActivationCodeOutput = {
  __typename?: 'RequestActivationCodeOutput';
  waitingList: Maybe<WaitingListUserResponse>;
};

export type RiskControlProperties = {
  __typename?: 'RiskControlProperties';
  minimumBetWager: Maybe<Scalars['Int']>;
  wagerLimitPerUser: Maybe<Scalars['Int']>;
  wagerLimitInTotal: Maybe<Scalars['Int']>;
  countLimit: Maybe<Scalars['Int']>;
};

export type SaveCategoryPriorityPerUserInput = {
  priorities: Array<CategoryPriorityPerUserInput>;
  userId: Scalars['ID'];
};

export type SaveLimitationSettingsInput = {
  limitationType: LimitationType;
  settingValues: SaveLimitationSettingsValuesInput;
};

export type SaveLimitationSettingsValuesInput = {
  daily: Scalars['Int'];
  monthly: Scalars['Int'];
  weekly: Scalars['Int'];
};

export type SaveMatchEventInput = {
  body: Scalars['AWSJSON'];
  matchId: Scalars['ID'];
  sourceId: Maybe<Scalars['ID']>;
  sourceService: SourceService;
  sportKey: SportKey;
  timestamp: Scalars['AWSDateTime'];
};

export type SaveMatchPriorityPerUserInput = {
  priorities: Array<MatchPriorityPerUserInput>;
  userId: Scalars['ID'];
};

export type SaveMatchSummaryInput = {
  geniusSportsSummary: Maybe<GeniusSportsMatchSummaryInput>;
  matchId: Scalars['ID'];
  sourceService: SourceService;
  sportKey: SportKey;
  summary: Maybe<Scalars['AWSJSON']>;
  timestamp: Scalars['AWSDateTime'];
};

export type SaveProfileStatsInput = {
  body: Scalars['AWSJSON'];
  profileId: Scalars['ID'];
  sourceId: Maybe<Scalars['ID']>;
  sourceService: SourceService;
  sportKey: SportKey;
  timestamp: Scalars['AWSDateTime'];
};

export type SaveRealityCheckInput = {
  enable: Maybe<Scalars['Boolean']>;
  frequency: Maybe<RealityCheckFrequency>;
};

export type SaveSelfExclusionInput = {
  term: SelfExclusionTerm;
};

export type SaveTimeoutInput = {
  term: TimeoutTerm;
};

export type SaveWalletHistoryIsShownInput = {
  histories: HistoriesInput;
};

export type SaveWalletHistoryIsShownOutput = {
  __typename?: 'SaveWalletHistoryIsShownOutput';
  histories: HistoriesOutput;
};

export type Score = {
  __typename?: 'Score';
  away: Scalars['Int'];
  awayText: Maybe<Scalars['String']>;
  home: Scalars['Int'];
  homeText: Maybe<Scalars['String']>;
};

export type ScoreInput = {
  away: Scalars['Int'];
  home: Scalars['Int'];
};

export type SearchBonusConfigsOutput = {
  __typename?: 'SearchBonusConfigsOutput';
  configs: Array<BonusConfigV2>;
  nextKey: Maybe<Scalars['String']>;
};

export type SearchBonusConfigsParams = {
  bonusType: Maybe<BonusType>;
  countType: Maybe<BonusCountType>;
  isEnabled: Maybe<Scalars['Boolean']>;
  size: Maybe<Scalars['Int']>;
  startKey: Maybe<Scalars['String']>;
};

export type SearchByEsQueryParams = {
  esIndex: Scalars['String'];
  esQuery: Scalars['AWSJSON'];
};

export type SearchMatchesOutput = {
  __typename?: 'SearchMatchesOutput';
  featuredMatches: Maybe<Array<Match>>;
  matches: Array<Match>;
  nextKey: Maybe<Scalars['String']>;
};

export type SearchMatchesParams = {
  categoryId: Maybe<Scalars['ID']>;
  hasLiveStreaming: Maybe<Scalars['Boolean']>;
  isLive: Maybe<Scalars['Boolean']>;
  size: Maybe<Scalars['Int']>;
  sportId: Maybe<Scalars['ID']>;
  startKey: Maybe<Scalars['String']>;
  country: Maybe<UserCountryCodeIso1Alpha2>;
};

export type SearchStreamingContentsOutput = {
  __typename?: 'SearchStreamingContentsOutput';
  contents: Array<StreamingContent>;
  lastKey: Maybe<Scalars['String']>;
};

export type SearchStreamingContentsParams = {
  categoryIds: Maybe<Array<Scalars['ID']>>;
  contentIds: Maybe<Array<Scalars['ID']>>;
  excludeTags: Maybe<Array<Scalars['String']>>;
  isLiveFinished: Maybe<Scalars['Boolean']>;
  isLiveStarted: Maybe<Scalars['Boolean']>;
  liveAt: Maybe<DateTimeRangeInput>;
  liveStartedAt: Maybe<DateTimeRangeInput>;
  matchId: Maybe<Scalars['ID']>;
  playlistIds: Maybe<Array<Scalars['ID']>>;
  preset: Maybe<Scalars['String']>;
  size: Maybe<Scalars['Int']>;
  sort: Maybe<SearchStreamingContentsSort>;
  sportId: Maybe<Scalars['ID']>;
  startKey: Maybe<Scalars['String']>;
  statuses: Maybe<Array<StreamingContentStatus>>;
  streamerIds: Maybe<Array<Scalars['ID']>>;
  tags: Maybe<Array<Scalars['String']>>;
  type: Maybe<StreamingContentType>;
};

export enum SearchStreamingContentsSort {
  Default = 'Default',
  Latest = 'Latest',
  Random = 'Random',
}

export type SelfExclusion = {
  __typename?: 'SelfExclusion';
  selfExclusionEndsAt: Maybe<Scalars['AWSDateTime']>;
  updatedAt: Scalars['AWSDateTime'];
};

export enum SelfExclusionTerm {
  Term_1Year = 'TERM_1_YEAR',
  Term_5Years = 'TERM_5_YEARS',
  Term_6Months = 'TERM_6_MONTHS',
}

export type SendUserActivityInput = {
  categoryId: Maybe<Scalars['ID']>;
  contentId: Maybe<Scalars['ID']>;
  djUserId: Maybe<Scalars['ID']>;
  eventId: Scalars['ID'];
  marketId: Maybe<Scalars['ID']>;
  matchId: Maybe<Scalars['ID']>;
  metadata: Maybe<Scalars['AWSJSON']>;
  profileIds: Maybe<Array<Scalars['ID']>>;
  sportId: Maybe<Scalars['ID']>;
  timestamp: Scalars['AWSDateTime'];
  userId: Maybe<Scalars['ID']>;
  user_pseudo_id: Maybe<Scalars['ID']>;
};

export type SetMatchLiveStatusInput = {
  id: Scalars['ID'];
  liveStatus: MatchLiveStatus;
};

export type SimplifiedCompetitor = {
  __typename?: 'SimplifiedCompetitor';
  avatarImageUrl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: LocalizedStrings;
};

export type SimplifiedMatch = {
  __typename?: 'SimplifiedMatch';
  competitors: Array<SimplifiedCompetitor>;
  id: Scalars['ID'];
};

export type SimplifiedMatchSummary = {
  __typename?: 'SimplifiedMatchSummary';
  currentPhase: Maybe<Scalars['String']>;
  currentStatus: Maybe<Scalars['String']>;
  currentPhaseStartedAt: Maybe<Scalars['AWSDateTime']>;
  score: Maybe<Score>;
  grade: Maybe<RaceGrade>;
  liveAt: Maybe<Scalars['AWSDateTime']>;
  liveEndAt: Maybe<Scalars['AWSDateTime']>;
  isOddsBoosted: Maybe<Scalars['Boolean']>;
};

export enum SourceModule {
  AppIdVerification = 'APP_ID_VERIFICATION',
  AppMain = 'APP_MAIN',
  AppPayment = 'APP_PAYMENT',
  IntegrationMarket = 'INTEGRATION_MARKET',
  IntegrationRisk = 'INTEGRATION_RISK',
  IntegrationVideo = 'INTEGRATION_VIDEO',
  None = 'NONE',
}

export enum SourceService {
  BetRadar = 'BET_RADAR',
  Everymatrix = 'EVERYMATRIX',
  GeniusSports = 'GENIUS_SPORTS',
  None = 'NONE',
  Onfido = 'ONFIDO',
}

export type Sport = {
  __typename?: 'Sport';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  mediaUrls: SportMediaUrls;
  name: LocalizedStrings;
  sportKey: Maybe<SportKey>;
  updatedAt: Scalars['AWSDateTime'];
};

export enum SportKey {
  AmericanFootball = 'AMERICAN_FOOTBALL',
  AustralianRules = 'AUSTRALIAN_RULES',
  Baseball = 'BASEBALL',
  Basketball = 'BASKETBALL',
  BeachVolleyball = 'BEACH_VOLLEYBALL',
  Bowls = 'BOWLS',
  Boxing = 'BOXING',
  Cricket = 'CRICKET',
  Cycling = 'CYCLING',
  Darts = 'DARTS',
  ESports = 'E_SPORTS',
  Football = 'FOOTBALL',
  Futsal = 'FUTSAL',
  GaelicFootball = 'GAELIC_FOOTBALL',
  Golf = 'GOLF',
  Handball = 'HANDBALL',
  Hockey = 'HOCKEY',
  HorseRacing = 'HORSE_RACING',
  Hurling = 'HURLING',
  IceHockey = 'ICE_HOCKEY',
  MartialArts = 'MARTIAL_ARTS',
  Mortorsport = 'MORTORSPORT',
  OctopusGames = 'OCTOPUS_GAMES',
  YamanekoGames = 'YAMANEKO_GAMES',
  RugbyLeague = 'RUGBY_LEAGUE',
  RugbyUnion = 'RUGBY_UNION',
  Snooker = 'SNOOKER',
  Squash = 'SQUASH',
  TableTennis = 'TABLE_TENNIS',
  Tennis = 'TENNIS',
  ThreeXTreeBasketball = 'THREE_X_TREE_BASKETBALL',
  Volleyball = 'VOLLEYBALL',
}

export type SportMediaUrls = {
  __typename?: 'SportMediaUrls';
  logoImageUrl: Scalars['String'];
};

export type SportMediaUrlsInput = {
  logoImageUrl: Scalars['String'];
};

export type StartLiveStreamingContentInput = {
  id: Scalars['ID'];
};

export type StartLiveStreamingContentOutputV2 = {
  __typename?: 'StartLiveStreamingContentOutputV2';
  agora: AgoraChannelInformation;
};

export type StartOnfidoVerificationInput = {
  applicationId: Scalars['String'];
  referrer: Maybe<Scalars['String']>;
};

export type StartOnfidoVerificationOutput = {
  __typename?: 'StartOnfidoVerificationOutput';
  sdkToken: Scalars['String'];
};

export type Streamer = {
  __typename?: 'Streamer';
  categoryIds: Maybe<Array<Scalars['ID']>>;
  contentImageUrlCandidates: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['AWSDateTime'];
  description: LocalizedStrings;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  metadata: Maybe<Scalars['AWSJSON']>;
  name: LocalizedStrings;
  sourceUrl: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  updatedAt: Maybe<Scalars['AWSDateTime']>;
  version: Scalars['Int'];
};

export type StreamingContent = {
  categoryIds: Maybe<Array<Scalars['ID']>>;
  createdAt: Scalars['AWSDateTime'];
  description: LocalizedStrings;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  matchId: Maybe<Scalars['ID']>;
  metadata: Maybe<Scalars['AWSJSON']>;
  playlistId: Maybe<Scalars['ID']>;
  sortPriority: Maybe<Scalars['Float']>;
  sportId: Maybe<Scalars['ID']>;
  streamer: Streamer;
  streamingMatchId: Maybe<Scalars['ID']>;
  tags: Array<Scalars['String']>;
  title: LocalizedStrings;
  type: StreamingContentType;
  updatedAt: Maybe<Scalars['AWSDateTime']>;
  version: Scalars['Int'];
};

export type StreamingContentChatMessage = {
  __typename?: 'StreamingContentChatMessage';
  author: StreamingContentChatMessageAuthor;
  contentId: Maybe<Scalars['ID']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  matchId: Scalars['ID'];
  message: Scalars['String'];
  messageKey: Scalars['String'];
};

export type StreamingContentChatMessageAuthor = {
  __typename?: 'StreamingContentChatMessageAuthor';
  imageUrl: Maybe<Scalars['String']>;
  nickname: Scalars['String'];
  userId: Maybe<Scalars['ID']>;
};

export enum StreamingContentStatus {
  Active = 'Active',
  Archived = 'Archived',
  Draft = 'Draft',
  Inactive = 'Inactive',
  LiveProcessing = 'LiveProcessing',
}

export enum StreamingContentType {
  LiveStreaming = 'LiveStreaming',
  Podcast = 'Podcast',
}

export type StreamingPlaylist = {
  __typename?: 'StreamingPlaylist';
  categoryIds: Maybe<Array<Scalars['ID']>>;
  contentImageUrlCandidates: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['AWSDateTime'];
  description: LocalizedStrings;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  metadata: Maybe<Scalars['AWSJSON']>;
  streamerId: Scalars['ID'];
  tags: Array<Scalars['String']>;
  title: LocalizedStrings;
  updatedAt: Maybe<Scalars['AWSDateTime']>;
  version: Scalars['Int'];
};

export type SubmitActivationCodeInput = {
  code: Scalars['String'];
};

export type SubmitActivationCodeOutput = {
  __typename?: 'SubmitActivationCodeOutput';
  isMatch: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  idVerificationStatusUpdated: Maybe<UserIdVerificationStatus>;
  inplayMarketsUpdated: Maybe<NotifyMarketsUpdatedOutput>;
  inplayMarketsUpdatedV2: Maybe<NotifyMarketsUpdatedV2Output>;
  liveStreamingContentUpdated: Maybe<StreamingContent>;
  matchesUpdated: Maybe<NotifyMatchesUpdatedOutput>;
  streamingContentChatMessageUpdated: Maybe<StreamingContentChatMessage>;
  walletUpdated: Maybe<NotifyWalletUpdatedOutput>;
  verificationStatusUpdated: Maybe<UserVerificationStatusRealTimeOutput>;
};

export type SubscriptionIdVerificationStatusUpdatedArgs = {
  userId: Scalars['ID'];
};

export type SubscriptionInplayMarketsUpdatedArgs = {
  matchId: Maybe<Scalars['ID']>;
};

export type SubscriptionInplayMarketsUpdatedV2Args = {
  matchId: Maybe<Scalars['ID']>;
};

export type SubscriptionStreamingContentChatMessageUpdatedArgs = {
  contentId: Maybe<Scalars['ID']>;
  matchId: Scalars['ID'];
};

export type SubscriptionWalletUpdatedArgs = {
  id: Scalars['ID'];
};

export type SubscriptionVerificationStatusUpdatedArgs = {
  id: Scalars['ID'];
};

export type SumoDaoPlayerStatOutput = {
  __typename?: 'SumoDaoPlayerStatOutput';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  position: Maybe<Scalars['String']>;
  stable: Maybe<Scalars['String']>;
  height: Maybe<Scalars['Float']>;
  weight: Maybe<Scalars['Float']>;
};

export type SumoDaoPlayerStatsResultOutput = {
  __typename?: 'SumoDaoPlayerStatsResultOutput';
  groupTag: Maybe<PlayerGroupTagOutput>;
  results: Array<SumoDaoPlayerStatOutput>;
};

export type Tag = {
  __typename?: 'Tag';
  key: Scalars['String'];
  value: Maybe<Scalars['String']>;
};

export type TeamProfile = Profile & {
  __typename?: 'TeamProfile';
  avatarImageUrl: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isFollowing: Maybe<Scalars['Boolean']>;
  leagueProfileId: Maybe<Scalars['ID']>;
  name: LocalizedStrings;
  shortName: Maybe<LocalizedStrings>;
  updatedAt: Scalars['AWSDateTime'];
};

export type TennisSummaryInput = {
  customerId: Scalars['ID'];
  fixtureId: Scalars['ID'];
  messageTimestampUtc: Scalars['AWSDateTime'];
};

export type TermAndCondition = {
  __typename?: 'TermAndCondition';
  id: Scalars['ID'];
  version: Scalars['Int'];
  title: Scalars['String'];
  link: Scalars['String'];
  message: Scalars['String'];
  isEnabled: Scalars['Boolean'];
};

export type TimeRemainingInPhaseAtTimestamp = {
  timeRemainingInPhaseMilliseconds: Maybe<Scalars['Int']>;
  timeStampUtc: Maybe<Scalars['AWSDateTime']>;
};

export type Timeout = {
  __typename?: 'Timeout';
  coolingOffEndsAt: Maybe<Scalars['AWSDateTime']>;
  isUnderCoolingOff: Scalars['Boolean'];
  timeoutEndsAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export enum TimeoutTerm {
  Term_1Day = 'TERM_1_DAY',
  Term_1Month = 'TERM_1_MONTH',
  Term_1Week = 'TERM_1_WEEK',
}

export type TransactionSummaryOutput = {
  __typename?: 'TransactionSummaryOutput';
  totalDeposits: Scalars['Int'];
  totalWithdrawals: Scalars['Int'];
  totalCashReturns: Scalars['Int'];
  totalCashBets: Scalars['Int'];
  totalBonusReturns: Scalars['Int'];
  totalBonusBets: Scalars['Int'];
  nextKey: Maybe<Scalars['String']>;
};

export enum TransactionType {
  Bet = 'BET',
  Bonus = 'BONUS',
  BonusCash = 'BONUS_CASH',
  Deposit = 'DEPOSIT',
  Match = 'MATCH',
  Refund = 'REFUND',
  Withdrawal = 'WITHDRAWAL',
  Won = 'WON',
  WithdrawalRefund = 'WITHDRAWAL_REFUND',
  Adjustment = 'ADJUSTMENT',
  DepositCancelled = 'DEPOSIT_CANCELLED',
  UnlockedPlayerStat = 'UNLOCKED_PLAYER_STAT',
  UnlockedMarkets = 'UNLOCKED_MARKETS',
  UnlockedPastVideo = 'UNLOCKED_PAST_VIDEO',
  JackpotBonus = 'JACKPOT_BONUS',
  CompensationCash = 'COMPENSATION_CASH',
  JackpotCash = 'JACKPOT_CASH',
}

export type UnlockedMarketsOutput = {
  __typename?: 'UnlockedMarketsOutput';
  id: Scalars['ID'];
  gradeId: Scalars['String'];
  matchId: Scalars['String'];
};

export type UnlockedMatchGroupPlayerStatOutput = {
  __typename?: 'UnlockedMatchGroupPlayerStatOutput';
  id: Scalars['ID'];
  groupPlayerStatId: Scalars['String'];
  matchId: Scalars['String'];
};

export type UnlockedPastVideoOutput = {
  __typename?: 'UnlockedPastVideoOutput';
  id: Scalars['ID'];
  gradeId: Scalars['String'];
  matchId: Scalars['String'];
};

export type UpdateInplayMarketOptionInput = {
  marketOptionId: Scalars['ID'];
  odds: OddsInput;
  weight: Maybe<Scalars['Int']>;
};

export type UpdateInplayMarketOptionsInput = {
  marketId: Scalars['ID'];
  options: Array<UpdateInplayMarketOptionInput>;
};

export type UpdateInplayMarketOptionsOverMultipleMarketsInput = {
  matchId: Maybe<Scalars['ID']>;
  options: Array<UpdateInplayMarketOptionInput>;
};

export type UpdateLiveStreamingContentInput = {
  description: LocalizedStringsInput;
  finishedAt: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  liveAt: Scalars['AWSDateTime'];
  metadata: Maybe<Scalars['AWSJSON']>;
  sortPriority: Maybe<Scalars['Float']>;
  streamingMatchId: Maybe<Scalars['ID']>;
  tags: Array<Scalars['String']>;
  title: LocalizedStringsInput;
};

export type UpdateMatchInput = {
  cancelDetail: Maybe<MatchCancelDetail>;
  categoryId: Scalars['ID'];
  competitorIds: Array<Scalars['ID']>;
  feedStatus: Maybe<MatchFeedStatus>;
  finishAt: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  isCanceled: Scalars['Boolean'];
  isLive: Scalars['Boolean'];
  isOpen: Scalars['Boolean'];
  liveAt: Scalars['AWSDateTime'];
  liveStatus: Maybe<MatchLiveStatus>;
  mediaUrls: MatchMediaUrlsInput;
  metadata: Maybe<MatchMetadataInput>;
  shortTitle: Maybe<LocalizedStringsInput>;
  title: LocalizedStringsInput;
};

export type UpdateStreamerInput = {
  categoryIds: Maybe<Array<Scalars['ID']>>;
  contentImageUrlCandidates: Maybe<Array<Scalars['String']>>;
  description: LocalizedStringsInput;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  metadata: Maybe<Scalars['AWSJSON']>;
  name: LocalizedStringsInput;
  sortPriority: Maybe<Scalars['Float']>;
  tags: Array<Scalars['String']>;
};

export type UpdateTeamProfileInput = {
  avatarImageUrl: Scalars['String'];
  id: Scalars['ID'];
  leagueProfileId: Maybe<Scalars['ID']>;
  name: LocalizedStringsInput;
  shortName: Maybe<LocalizedStringsInput>;
  sportId: Scalars['ID'];
};

export type UpdateUserPropertiesInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  postalCode: Scalars['String'];
  nickname: Maybe<Scalars['String']>;
  subscribeEmail: Maybe<Scalars['Boolean']>;
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  city: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  cognitoUUID: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  nickname: Maybe<Scalars['String']>;
  activationCode: Maybe<Scalars['String']>;
  isCodeVerified: Maybe<Scalars['Boolean']>;
  friendReferralCode: Maybe<Scalars['String']>;
  postInteractionLevel: Maybe<Scalars['Int']>;
  isPaused: Maybe<Scalars['Boolean']>;
  country: Maybe<UserCountryCodeIso1Alpha2>;
  referralCode: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  profileImageUrls: Maybe<ProfileImageUrls>;
  properties: UserProperties;
  subscribeEmail: Maybe<Scalars['Boolean']>;
  tags: Maybe<Array<Tag>>;
  updatedAt: Scalars['AWSDateTime'];
  wallet: Wallet;
  agreedTermsAndConditions: Maybe<Array<Scalars['String']>>;
};

export enum UserAgentType {
  PcWeb = 'PC_WEB',
  MobileWeb = 'MOBILE_WEB',
  MobileApp = 'MOBILE_APP',
}

export enum UserCountryCodeIso1Alpha2 {
  Jp = 'JP',
  Us = 'US',
  Ua = 'UA',
  Il = 'IL',
  Gb = 'GB',
  Vn = 'VN',
  Ie = 'IE',
}

export type UserIdReportStatus = {
  __typename?: 'UserIdReportStatus';
  document: Maybe<Scalars['Boolean']>;
  facial_similarity_motion: Maybe<Scalars['Boolean']>;
  facial_similarity_video: Maybe<Scalars['Boolean']>;
  identity_enhanced: Maybe<Scalars['Boolean']>;
  proof_of_address: Maybe<Scalars['Boolean']>;
  watch_list: Maybe<Scalars['Boolean']>;
};

export type UserIdReportStatusRealTimeOutput = {
  __typename?: 'UserIdReportStatusRealTimeOutput';
  document: Maybe<Scalars['Boolean']>;
  facial_similarity_motion: Maybe<Scalars['Boolean']>;
  facial_similarity_video: Maybe<Scalars['Boolean']>;
  identity_enhanced: Maybe<Scalars['Boolean']>;
  proof_of_address: Maybe<Scalars['Boolean']>;
  watch_list: Maybe<Scalars['Boolean']>;
};

export type UserIdReportStatusV2 = {
  __typename?: 'UserIdReportStatusV2';
  document: Maybe<Scalars['Boolean']>;
  face: Maybe<Scalars['Boolean']>;
  address: Maybe<Scalars['Boolean']>;
  aml: Maybe<Scalars['Boolean']>;
};

export type UserIdVerificationHistory = {
  __typename?: 'UserIdVerificationHistory';
  createdAt: Scalars['AWSDateTime'];
  key: Maybe<Scalars['String']>;
  status: Maybe<IdVerificationStatus>;
};

export type UserIdVerificationStatus = {
  __typename?: 'UserIdVerificationStatus';
  completedAt: Maybe<Scalars['AWSDateTime']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  status: IdVerificationStatus;
  statusFirstStep: Maybe<IdVerificationStatus>;
  statusSecondStep: Maybe<IdVerificationStatus>;
  reportStatus: Maybe<UserIdReportStatus>;
  submittedAt: Maybe<Scalars['AWSDateTime']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type UserIdVerificationStatusV2 = {
  __typename?: 'UserIdVerificationStatusV2';
  id: Scalars['ID'];
  status: IdVerificationStatus;
  reportStatus: Maybe<UserIdReportStatusV2>;
  submittedAt: Maybe<Scalars['AWSDateTime']>;
  completedAt: Maybe<Scalars['AWSDateTime']>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export type UserLimitationAllowed = {
  __typename?: 'UserLimitationAllowed';
  bet: Maybe<BetLimitations>;
  deposit: Maybe<LimitationAllowed>;
};

export type UserProperties = {
  __typename?: 'UserProperties';
  dateOfBirth: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  isEmailVerified: Scalars['Boolean'];
  isPhoneVerified: Scalars['Boolean'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  city: Scalars['String'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  limitation: LimitationSettings;
  selfExclusion: Maybe<SelfExclusion>;
  timeout: Maybe<Timeout>;
  realityCheck: Maybe<RealityCheck>;
};

export type UserVerificationStatusRealTimeOutput = {
  __typename?: 'UserVerificationStatusRealTimeOutput';
  id: Scalars['ID'];
  status: IdVerificationStatus;
  statusFirstStep: Maybe<IdVerificationStatus>;
  statusSecondStep: Maybe<IdVerificationStatus>;
  reportStatus: Maybe<UserIdReportStatusRealTimeOutput>;
  submittedAt: Maybe<Scalars['AWSDateTime']>;
  completedAt: Maybe<Scalars['AWSDateTime']>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export enum VerificationStatus {
  Complete = 'COMPLETE',
  NotVerified = 'NOT_VERIFIED',
  Waiting = 'WAITING',
}

export type WaitingListUserResponse = {
  __typename?: 'WaitingListUserResponse';
  id: Scalars['String'];
  intercomContactId: Scalars['String'];
};

export type Wallet = {
  __typename?: 'Wallet';
  balance: Scalars['Int'];
  bonus: Scalars['Int'];
  bonusUsedInThisMonth: Scalars['Int'];
  defaultBetMonthLimit: Scalars['Int'];
  cashFromDeposit: Scalars['Int'];
  bonusBeforeBet: Scalars['Int'];
  bonusAfterBet: Scalars['Int'];
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
};

export type WalletHistoriesOutput = {
  __typename?: 'WalletHistoriesOutput';
  history: Array<WalletHistory>;
  nextKey: Maybe<Scalars['String']>;
};

export type WalletHistory = {
  amount: Maybe<Scalars['Int']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  transactionId: Maybe<Scalars['ID']>;
  transactionType: TransactionType;
  isShown: Scalars['Boolean'];
  text: Maybe<Scalars['String']>;
  transactionText: Maybe<Scalars['String']>;
};

export type WithdrawInput = {
  amount: Scalars['Int'];
  transactionUUID: Scalars['String'];
};

export type YadokariDaoPlayerStatOutput = {
  __typename?: 'YadokariDaoPlayerStatOutput';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  lane: Scalars['Int'];
  weight: Maybe<Scalars['Float']>;
};

export type YadokariDaoPlayerStatsResultOutput = {
  __typename?: 'YadokariDaoPlayerStatsResultOutput';
  groupTag: Maybe<PlayerGroupTagOutput>;
  results: Array<YadokariDaoPlayerStatOutput>;
};

export type YoutubeVideo = {
  __typename?: 'YoutubeVideo';
  embedUrl: Scalars['String'];
  thumbnails: YoutubeVideoThumbnail;
};

export type YoutubeVideoThumbnail = {
  __typename?: 'YoutubeVideoThumbnail';
  default: Scalars['String'];
  medium: Scalars['String'];
  high: Scalars['String'];
};

export type AgoraChannelInformationFieldsFragment = {
  __typename?: 'AgoraChannelInformation';
} & Pick<AgoraChannelInformation, 'channelName' | 'token' | 'uid'>;

export type BetHistoryMatchFieldsFragment = { __typename?: 'Match' } & Pick<
  Match,
  'id' | 'createdAt' | 'updatedAt'
> & {
    title: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    category: { __typename?: 'MatchCategory' } & CategoryFieldsFragment;
    competitors: Array<
      | ({
          __typename?: 'LeagueProfile';
        } & BetTargetProfileFields_LeagueProfile_Fragment)
      | ({
          __typename?: 'ManagerProfile';
        } & BetTargetProfileFields_ManagerProfile_Fragment)
      | ({
          __typename?: 'PlayerProfile';
        } & BetTargetProfileFields_PlayerProfile_Fragment)
      | ({
          __typename?: 'TeamProfile';
        } & BetTargetProfileFields_TeamProfile_Fragment)
    >;
  };

export type BetListOutputFieldsFragment = {
  __typename?: 'BetListOutput';
} & Pick<BetListOutput, 'totalReturns' | 'totalBets' | 'profit'> & {
    bets: Array<{ __typename?: 'Bet' } & BetHistoryFieldsFragment>;
  };

export type BetListOutputV2FieldsFragment = {
  __typename?: 'BetListV2Output';
} & Pick<
  BetListV2Output,
  | 'totalBonusBets'
  | 'totalBonusReturns'
  | 'bonusProfit'
  | 'totalCashBets'
  | 'totalCashReturns'
  | 'cashProfit'
  | 'totalBetCount'
  | 'totalCorrectBetCount'
  | 'totalUnconfirmedBetCount'
> & { bets: Array<{ __typename?: 'Bet' } & BetHistoryFieldsFragment> };

export type BetHistoryFieldsFragment = { __typename?: 'Bet' } & Pick<
  Bet,
  | 'id'
  | 'oddsValue'
  | 'estimatedReturn'
  | 'returned'
  | 'confirmationStatus'
  | 'confirmedAt'
  | 'status'
  | 'userConfirmedResult'
  | 'createdAt'
  | 'updatedAt'
  | 'postBalance'
  | 'preBalance'
  | 'isCash'
  | 'bonusReturned'
  | 'cashReturned'
  | 'postBonus'
  | 'preBonus'
  | 'preCash'
  | 'postCash'
> & { betAmount: Bet['wager'] } & {
    match: { __typename?: 'Match' } & BetHistoryMatchFieldsFragment;
    market: { __typename?: 'InplayMarket' } & InplayMarketFieldsFragment;
    selectedOption: {
      __typename?: 'InplayMarketOption';
    } & InplayMarketOptionFieldsFragment;
  };

export type BetInMarketFieldsFragment = { __typename?: 'Bet' } & Pick<
  Bet,
  | 'id'
  | 'estimatedReturn'
  | 'returned'
  | 'confirmationStatus'
  | 'confirmedAt'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'preBalance'
  | 'postBalance'
> & { betAmount: Bet['wager'] } & {
    selectedOption: {
      __typename?: 'InplayMarketOption';
    } & InplayMarketOptionFieldsFragment;
  };

export type BonusConfigV2FieldsFragment = {
  __typename?: 'BonusConfigV2';
} & Pick<
  BonusConfigV2,
  | 'id'
  | 'typeKey'
  | 'name'
  | 'bonusType'
  | 'countType'
  | 'startAt'
  | 'endAt'
  | 'requiredCount'
  | 'maxCount'
  | 'requiredAmount'
  | 'additionalRequirements'
  | 'amount'
  | 'amountPercent'
  | 'text'
  | 'txnText'
  | 'explanation'
  | 'imageUrl'
  | 'createdAt'
  | 'updatedAt'
  | 'isEnabled'
  | 'term'
  | 'requiredAction'
>;

export type LiveStreamingContentFieldsFragment = {
  __typename?: 'LiveStreamingContent';
} & Pick<
  LiveStreamingContent,
  | 'audienceCount'
  | 'id'
  | 'type'
  | 'imageUrl'
  | 'matchId'
  | 'streamingMatchId'
  | 'liveStartedAt'
  | 'finishedAt'
  | 'sportId'
  | 'isKilled'
> & {
    title: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    streamer: { __typename?: 'Streamer' } & Pick<
      Streamer,
      'id' | 'imageUrl' | 'tags'
    > & {
        name: {
          __typename?: 'LocalizedStrings';
        } & LocalizedStringFieldsFragment;
      };
  };

export type StreamingContentFieldsFragment = {
  __typename: 'LiveStreamingContent';
} & LiveStreamingContentFieldsFragment;

export type StreamingContentChatMessageFieldsFragment = {
  __typename?: 'StreamingContentChatMessage';
} & Pick<
  StreamingContentChatMessage,
  'id' | 'matchId' | 'contentId' | 'message' | 'messageKey' | 'createdAt'
> & {
    author: { __typename?: 'StreamingContentChatMessageAuthor' } & Pick<
      StreamingContentChatMessageAuthor,
      'userId' | 'nickname' | 'imageUrl'
    >;
  };

export type ChannelJoinDetailFieldsFragment = {
  __typename?: 'ChannelJoinDetail';
} & Pick<
  ChannelJoinDetail,
  | 'contentId'
  | 'inChannel'
  | 'isAudience'
  | 'isBroadcaster'
  | 'isInvited'
  | 'isRequesting'
  | 'isSuspended'
>;

export type StreamerDetailFieldsFragment = { __typename?: 'Streamer' } & Pick<
  Streamer,
  'id' | 'categoryIds' | 'imageUrl' | 'contentImageUrlCandidates' | 'tags'
> & {
    name: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
  };

export type LocalizedStringFieldsFragment = {
  __typename?: 'LocalizedStrings';
} & Pick<
  LocalizedStrings,
  'en_DEFAULT' | 'en_GB' | 'en_US' | 'en_IE' | 'ja_JP'
>;

export type InplayMarketFieldsFragment = { __typename?: 'InplayMarket' } & Pick<
  InplayMarket,
  | 'id'
  | 'matchId'
  | 'isOpen'
  | 'isWon'
  | 'openAt'
  | 'closeAt'
  | 'isVoid'
  | 'isConfirmed'
  | 'confirmedAt'
  | 'createdAt'
  | 'updatedAt'
  | 'weight'
  | 'disableBet'
  | 'disable'
  | 'isOddsBoosted'
> & {
    title: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    shortTitle: Maybe<
      { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment
    >;
    options: Array<
      { __typename?: 'InplayMarketOption' } & InplayMarketOptionFieldsFragment
    >;
    targetProfiles: Maybe<
      Array<
        | ({
            __typename?: 'LeagueProfile';
          } & BetTargetProfileFields_LeagueProfile_Fragment)
        | ({
            __typename?: 'ManagerProfile';
          } & BetTargetProfileFields_ManagerProfile_Fragment)
        | ({
            __typename?: 'PlayerProfile';
          } & BetTargetProfileFields_PlayerProfile_Fragment)
        | ({
            __typename?: 'TeamProfile';
          } & BetTargetProfileFields_TeamProfile_Fragment)
      >
    >;
    mediaUrls: { __typename?: 'InplayMarketMediaUrls' } & Pick<
      InplayMarketMediaUrls,
      'backgroundSmallImageUrl' | 'backgroundLargeImageUrl'
    >;
    riskControlProperties: Maybe<
      { __typename?: 'RiskControlProperties' } & Pick<
        RiskControlProperties,
        | 'minimumBetWager'
        | 'wagerLimitPerUser'
        | 'wagerLimitInTotal'
        | 'countLimit'
      >
    >;
  };

export type GroupTagFieldsFragment = { __typename?: 'GroupTag' } & Pick<
  GroupTag,
  | 'order'
  | 'name'
  | 'isLive'
  | 'closeAt'
  | 'openAt'
  | 'id'
  | 'preVideoUrl'
  | 'pastVideoUrl'
  | 'thumbnailUrl'
  | 'videoStartsAt'
  | 'videoEndsAt'
  | 'isOddsBoosted'
>;

export type InplayMarketGroupFieldsFragment = {
  __typename?: 'MarketGroup';
} & Pick<MarketGroup, 'fourwDao' | 'sumoDao' | 'yadokariDao'> & {
    groupTag: Maybe<{ __typename?: 'GroupTag' } & GroupTagFieldsFragment>;
    markets: Array<
      { __typename?: 'InplayMarket' } & InplayMarketFieldsFragment
    >;
  };

export type InplayMarketOptionFieldsFragment = {
  __typename?: 'InplayMarketOption';
} & Pick<InplayMarketOption, 'id' | 'isWon'> & {
    title: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    odds: { __typename?: 'Odds' } & Pick<Odds, 'value' | 'updatedAt'>;
    oldOdds: Maybe<{ __typename?: 'Odds' } & Pick<Odds, 'value' | 'updatedAt'>>;
  };

type BetTargetProfileFields_LeagueProfile_Fragment = {
  __typename: 'LeagueProfile';
} & Pick<LeagueProfile, 'id' | 'avatarImageUrl' | 'createdAt' | 'updatedAt'> & {
    name: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    shortName: Maybe<
      { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment
    >;
  };

type BetTargetProfileFields_ManagerProfile_Fragment = {
  __typename: 'ManagerProfile';
} & Pick<
  ManagerProfile,
  'id' | 'avatarImageUrl' | 'createdAt' | 'updatedAt'
> & {
    teamProfile: Maybe<
      { __typename?: 'TeamProfile' } & Pick<TeamProfile, 'avatarImageUrl'>
    >;
    name: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    shortName: Maybe<
      { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment
    >;
  };

type BetTargetProfileFields_PlayerProfile_Fragment = {
  __typename: 'PlayerProfile';
} & Pick<PlayerProfile, 'id' | 'avatarImageUrl' | 'createdAt' | 'updatedAt'> & {
    teamProfile: Maybe<
      { __typename?: 'TeamProfile' } & Pick<TeamProfile, 'avatarImageUrl'>
    >;
    name: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    shortName: Maybe<
      { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment
    >;
  };

type BetTargetProfileFields_TeamProfile_Fragment = {
  __typename: 'TeamProfile';
} & Pick<TeamProfile, 'id' | 'avatarImageUrl' | 'createdAt' | 'updatedAt'> & {
    name: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    shortName: Maybe<
      { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment
    >;
  };

export type BetTargetProfileFieldsFragment =
  | BetTargetProfileFields_LeagueProfile_Fragment
  | BetTargetProfileFields_ManagerProfile_Fragment
  | BetTargetProfileFields_PlayerProfile_Fragment
  | BetTargetProfileFields_TeamProfile_Fragment;

export type ListMarketsByMatchOutputFieldsFragment = {
  __typename?: 'ListMarketsByMatchOutput';
} & Pick<ListMarketsByMatchOutput, 'profit' | 'totalBets' | 'totalReturns'> & {
    markets: Array<
      { __typename?: 'InplayMarket' } & InplayMarketFieldsFragment
    >;
  };

export type ListMarketsByMatchOutputFieldsV3Fragment = {
  __typename?: 'ListMarketsByMatchV3Output';
} & Pick<
  ListMarketsByMatchV3Output,
  'profit' | 'totalBets' | 'totalReturns'
> & {
    marketGroups: Array<
      { __typename?: 'MarketGroup' } & InplayMarketGroupFieldsFragment
    >;
  };

export type InplayMarketWithoutTargetProfileFieldsFragment = {
  __typename?: 'InplayMarket';
} & Pick<
  InplayMarket,
  | 'id'
  | 'matchId'
  | 'isOpen'
  | 'isWon'
  | 'openAt'
  | 'closeAt'
  | 'isVoid'
  | 'isConfirmed'
  | 'confirmedAt'
  | 'createdAt'
  | 'updatedAt'
  | 'weight'
  | 'disableBet'
  | 'disable'
  | 'isOddsBoosted'
> & {
    title: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    shortTitle: Maybe<
      { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment
    >;
    options: Array<
      { __typename?: 'InplayMarketOption' } & InplayMarketOptionFieldsFragment
    >;
    mediaUrls: { __typename?: 'InplayMarketMediaUrls' } & Pick<
      InplayMarketMediaUrls,
      'backgroundSmallImageUrl' | 'backgroundLargeImageUrl'
    >;
  };

export type SportFieldsFragment = { __typename?: 'Sport' } & Pick<
  Sport,
  'id' | 'sportKey' | 'createdAt' | 'updatedAt'
> & {
    name: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    mediaUrls: { __typename?: 'SportMediaUrls' } & Pick<
      SportMediaUrls,
      'logoImageUrl'
    >;
  };

export type GradeFieldsFragment = { __typename?: 'MatchGrade' } & Pick<
  MatchGrade,
  | 'id'
  | 'createdAt'
  | 'colorCode'
  | 'point'
  | 'gemPrice'
  | 'pastVideoGemPrice'
  | 'hidePastVideo'
  | 'categoryId'
> & {
    name: Maybe<
      { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment
    >;
  };

export type CategoryFieldsFragment = { __typename?: 'MatchCategory' } & Pick<
  MatchCategory,
  'id' | 'createdAt' | 'updatedAt'
> & {
    name: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    sport: Maybe<{ __typename?: 'Sport' } & SportFieldsFragment>;
  };

export type MatchFieldsFragment = { __typename?: 'Match' } & Pick<
  Match,
  | 'id'
  | 'liveAt'
  | 'liveEndAt'
  | 'finishAt'
  | 'isLive'
  | 'isOpen'
  | 'ivsStatus'
  | 'liveStatus'
  | 'metadata'
  | 'mediaSourceService'
  | 'streamingContentIds'
  | 'createdAt'
  | 'updatedAt'
> & {
    title: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    competitors: Array<
      | ({
          __typename?: 'LeagueProfile';
        } & CompetitorFields_LeagueProfile_Fragment)
      | ({
          __typename?: 'ManagerProfile';
        } & CompetitorFields_ManagerProfile_Fragment)
      | ({
          __typename?: 'PlayerProfile';
        } & CompetitorFields_PlayerProfile_Fragment)
      | ({ __typename?: 'TeamProfile' } & CompetitorFields_TeamProfile_Fragment)
    >;
    category: { __typename?: 'MatchCategory' } & CategoryFieldsFragment;
    mediaUrls: { __typename?: 'MatchMediaUrls' } & MatchMediaUrlFieldsFragment;
    simplifiedSummary: Maybe<
      {
        __typename?: 'SimplifiedMatchSummary';
      } & SimplifiedMatchSummaryFieldsFragment
    >;
  };

export type PlayerStatsRowFieldsFragment = {
  __typename?: 'PlayerStatsRow';
} & Pick<PlayerStatsRow, 'values'>;

export type PlayerStatsTableFieldsFragment = {
  __typename?: 'PlayerStatsTable';
} & Pick<PlayerStatsTable, 'headers'> & {
    groupTag: Maybe<
      { __typename?: 'PlayerGroupTagOutput' } & PlayerGroupTagFieldsFragment
    >;
    rows: Array<
      { __typename?: 'PlayerStatsRow' } & PlayerStatsRowFieldsFragment
    >;
  };

export type MatchPlayerStatsFieldsFragment = {
  __typename?: 'MatchPlayerStatsOutput';
} & Pick<MatchPlayerStatsOutput, 'id'> & {
    results: Array<
      { __typename?: 'PlayerStatsTable' } & PlayerStatsTableFieldsFragment
    >;
  };

type CompetitorFields_LeagueProfile_Fragment = {
  __typename: 'LeagueProfile';
} & Pick<LeagueProfile, 'id' | 'avatarImageUrl' | 'createdAt' | 'updatedAt'> & {
    name: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    shortName: Maybe<
      { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment
    >;
  };

type CompetitorFields_ManagerProfile_Fragment = {
  __typename: 'ManagerProfile';
} & Pick<
  ManagerProfile,
  'id' | 'avatarImageUrl' | 'createdAt' | 'updatedAt'
> & {
    teamProfile: Maybe<
      { __typename?: 'TeamProfile' } & Pick<TeamProfile, 'avatarImageUrl'>
    >;
    name: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    shortName: Maybe<
      { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment
    >;
  };

type CompetitorFields_PlayerProfile_Fragment = {
  __typename: 'PlayerProfile';
} & Pick<PlayerProfile, 'id' | 'avatarImageUrl' | 'createdAt' | 'updatedAt'> & {
    teamProfile: Maybe<
      { __typename?: 'TeamProfile' } & Pick<TeamProfile, 'avatarImageUrl'>
    >;
    name: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    shortName: Maybe<
      { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment
    >;
  };

type CompetitorFields_TeamProfile_Fragment = {
  __typename: 'TeamProfile';
} & Pick<TeamProfile, 'id' | 'avatarImageUrl' | 'createdAt' | 'updatedAt'> & {
    name: { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment;
    shortName: Maybe<
      { __typename?: 'LocalizedStrings' } & LocalizedStringFieldsFragment
    >;
  };

export type CompetitorFieldsFragment =
  | CompetitorFields_LeagueProfile_Fragment
  | CompetitorFields_ManagerProfile_Fragment
  | CompetitorFields_PlayerProfile_Fragment
  | CompetitorFields_TeamProfile_Fragment;

type MatchSummaryFields_BasketBallMatchSummary_Fragment = {
  __typename: 'BasketBallMatchSummary';
} & Pick<BasketBallMatchSummary, 'id' | 'sportKey'> & {
    basketballCurrentPhase: BasketBallMatchSummary['currentPhase'];
  } & {
    score: Maybe<
      { __typename?: 'Score' } & Pick<
        Score,
        'home' | 'homeText' | 'away' | 'awayText'
      >
    >;
  };

type MatchSummaryFields_FootballMatchSummary_Fragment = {
  __typename: 'FootballMatchSummary';
} & Pick<FootballMatchSummary, 'id' | 'currentPhaseStartedAt' | 'sportKey'> & {
    footballCurrentPhase: FootballMatchSummary['currentPhase'];
  } & {
    score: Maybe<
      { __typename?: 'Score' } & Pick<
        Score,
        'home' | 'homeText' | 'away' | 'awayText'
      >
    >;
  };

export type MatchSummaryFieldsFragment =
  | MatchSummaryFields_BasketBallMatchSummary_Fragment
  | MatchSummaryFields_FootballMatchSummary_Fragment;

export type SimplifiedMatchSummaryFieldsFragment = {
  __typename?: 'SimplifiedMatchSummary';
} & Pick<
  SimplifiedMatchSummary,
  | 'currentPhase'
  | 'currentStatus'
  | 'currentPhaseStartedAt'
  | 'liveAt'
  | 'liveEndAt'
  | 'isOddsBoosted'
> & {
    grade: Maybe<
      { __typename?: 'RaceGrade' } & Pick<
        RaceGrade,
        'name' | 'colorCode' | 'textColor'
      >
    >;
    score: Maybe<
      { __typename?: 'Score' } & Pick<
        Score,
        'home' | 'homeText' | 'away' | 'awayText'
      >
    >;
  };

export type MatchMediaUrlFieldsFragment = {
  __typename?: 'MatchMediaUrls';
} & Pick<
  MatchMediaUrls,
  | 'fallbackImageUrl'
  | 'logoImageUrl'
  | 'videoStreamingFallbackImageUrl'
  | 'videoStreamingUrl'
>;

export type PlayerGroupTagFieldsFragment = {
  __typename?: 'PlayerGroupTagOutput';
} & Pick<PlayerGroupTagOutput, 'id' | 'name' | 'order'>;

export type FourwDaoPlayerStatsResultFieldsFragment = {
  __typename?: 'FourwDaoPlayerStatsResultOutput';
} & {
  groupTag: Maybe<
    { __typename?: 'PlayerGroupTagOutput' } & PlayerGroupTagFieldsFragment
  >;
  results: Array<
    {
      __typename?: 'FourwDaoPlayerStatOutput';
    } & FourwDaoPlayerStatFieldsFragment
  >;
};

export type FourwDaoPlayerStatFieldsFragment = {
  __typename?: 'FourwDaoPlayerStatOutput';
} & Pick<FourwDaoPlayerStatOutput, 'id' | 'name' | 'lane' | 'lapTime'>;

export type SumoDaoPlayerStatsResultFieldsFragment = {
  __typename?: 'SumoDaoPlayerStatsResultOutput';
} & {
  groupTag: Maybe<
    { __typename?: 'PlayerGroupTagOutput' } & PlayerGroupTagFieldsFragment
  >;
  results: Array<
    { __typename?: 'SumoDaoPlayerStatOutput' } & SumoDaoPlayerStatFieldsFragment
  >;
};

export type SumoDaoPlayerStatFieldsFragment = {
  __typename?: 'SumoDaoPlayerStatOutput';
} & Pick<
  SumoDaoPlayerStatOutput,
  'id' | 'name' | 'position' | 'stable' | 'height' | 'weight'
>;

export type YadokariDaoPlayerStatsResultFieldsFragment = {
  __typename?: 'YadokariDaoPlayerStatsResultOutput';
} & {
  groupTag: Maybe<
    { __typename?: 'PlayerGroupTagOutput' } & PlayerGroupTagFieldsFragment
  >;
  results: Array<
    {
      __typename?: 'YadokariDaoPlayerStatOutput';
    } & YadokariDaoPlayerStatFieldsFragment
  >;
};

export type YadokariDaoPlayerStatFieldsFragment = {
  __typename?: 'YadokariDaoPlayerStatOutput';
} & Pick<YadokariDaoPlayerStatOutput, 'id' | 'name' | 'lane' | 'weight'>;

export type DocumentFieldFragment = {
  __typename?: 'OnfidoReportDocumentBreakDownReasoning';
} & Pick<
  OnfidoReportDocumentBreakDownReasoning,
  | 'ageValidation'
  | 'compromisedDocument'
  | 'dataConsistency'
  | 'dataValidation'
  | 'policeRecord'
  | 'visualAuthenticity'
  | 'issuingAuthority'
> & {
    dataComparison: Maybe<
      {
        __typename?: 'OnfidoReportDocumentDataComparisonBreakDown';
      } & DataComparisonFieldFragment
    >;
    imageIntegrity: Maybe<
      {
        __typename?: 'OnfidoReportDocumentImageIntegrityBreakDown';
      } & ImageIntegrityFieldFragment
    >;
  };

export type DataComparisonFieldFragment = {
  __typename?: 'OnfidoReportDocumentDataComparisonBreakDown';
} & Pick<
  OnfidoReportDocumentDataComparisonBreakDown,
  'gender' | 'lastName' | 'firstName' | 'dateOfBirth'
>;

export type ImageIntegrityFieldFragment = {
  __typename?: 'OnfidoReportDocumentImageIntegrityBreakDown';
} & Pick<
  OnfidoReportDocumentImageIntegrityBreakDown,
  | 'imageQuality'
  | 'conclusiveDocumentQuality'
  | 'colourPicture'
  | 'supportedDocument'
>;

export type FacialSimilarityVideoFieldFragment = {
  __typename?: 'OnfidoReportFacialSimilarityVideoBreakDownReasoning';
} & Pick<
  OnfidoReportFacialSimilarityVideoBreakDownReasoning,
  'imageIntegrity' | 'faceComparison' | 'visualAuthenticity'
>;

export type FacialSimilarityMotionFieldFragment = {
  __typename?: 'OnfidoReportFacialSimilarityVideoBreakDownReasoning';
} & Pick<
  OnfidoReportFacialSimilarityVideoBreakDownReasoning,
  'imageIntegrity' | 'faceComparison' | 'visualAuthenticity'
>;

export type ProofOfAddressFieldFragment = {
  __typename?: 'OnfidoReportProofOfAddressBreakDownReasoning';
} & Pick<
  OnfidoReportProofOfAddressBreakDownReasoning,
  | 'imageIntegrity'
  | 'dataComparison'
  | 'sourceIntegrity'
  | 'documentClassification'
>;

export type LimitationValueFieldsFragment = {
  __typename?: 'LimitationValue';
} & Pick<LimitationValue, 'value'> & {
    pendingValue: Maybe<
      { __typename?: 'PendingLimitationValue' } & Pick<
        PendingLimitationValue,
        'value' | 'coolingOffEndsAt' | 'isUnderCoolingOff'
      >
    >;
  };

export type LimitationValuesFieldsFragment = {
  __typename?: 'LimitationValues';
} & {
  daily: Maybe<
    { __typename?: 'LimitationValue' } & LimitationValueFieldsFragment
  >;
  weekly: Maybe<
    { __typename?: 'LimitationValue' } & LimitationValueFieldsFragment
  >;
  monthly: Maybe<
    { __typename?: 'LimitationValue' } & LimitationValueFieldsFragment
  >;
};

export type LimitationSettingsFieldsFragment = {
  __typename?: 'LimitationSettings';
} & {
  depositLimitations: {
    __typename?: 'LimitationValues';
  } & LimitationValuesFieldsFragment;
  betLimitations: {
    __typename?: 'LimitationValues';
  } & LimitationValuesFieldsFragment;
  lossLimitations: {
    __typename?: 'LimitationValues';
  } & LimitationValuesFieldsFragment;
};

export type UserSettingsFieldsFragment = { __typename?: 'UserSettings' } & {
  limitation: {
    __typename?: 'LimitationSettings';
  } & LimitationSettingsFieldsFragment;
  timeout: Maybe<
    { __typename?: 'Timeout' } & Pick<
      Timeout,
      'timeoutEndsAt' | 'isUnderCoolingOff' | 'coolingOffEndsAt'
    >
  >;
  selfExclusion: Maybe<
    { __typename?: 'SelfExclusion' } & Pick<
      SelfExclusion,
      'selfExclusionEndsAt'
    >
  >;
  realityCheck: Maybe<
    { __typename?: 'RealityCheck' } & Pick<RealityCheck, 'enable' | 'frequency'>
  >;
};

export type BonusConfigFieldsFragment = { __typename?: 'BonusConfig' } & Pick<
  BonusConfig,
  | 'onIdVerification'
  | 'onIdVerificationAmount'
  | 'onIdVerificationCash'
  | 'onIdVerificationExplanation'
  | 'onSignUp'
  | 'onSignUpAmount'
  | 'onSignUpExplanation'
>;

export type TermAndConditionFieldsFragment = {
  __typename?: 'TermAndCondition';
} & Pick<
  TermAndCondition,
  'id' | 'version' | 'title' | 'link' | 'message' | 'isEnabled'
>;

export type MeFieldsFragment = { __typename?: 'User' } & Pick<
  User,
  | 'cognitoUUID'
  | 'id'
  | 'nickname'
  | 'activationCode'
  | 'isCodeVerified'
  | 'friendReferralCode'
  | 'postInteractionLevel'
  | 'isPaused'
  | 'country'
  | 'referralCode'
  | 'subscribeEmail'
  | 'createdAt'
  | 'agreedTermsAndConditions'
> & {
    profileImageUrls: Maybe<
      { __typename?: 'ProfileImageUrls' } & Pick<ProfileImageUrls, 'large'>
    >;
    properties: { __typename?: 'UserProperties' } & Pick<
      UserProperties,
      | 'firstName'
      | 'lastName'
      | 'email'
      | 'dateOfBirth'
      | 'postalCode'
      | 'phoneNumber'
      | 'addressLine1'
      | 'addressLine2'
      | 'city'
    >;
    wallet: { __typename?: 'Wallet' } & WalletFieldsFragment;
  };

export type MyVerificationStatusFieldsFragment = {
  __typename?: 'UserIdVerificationStatus';
} & Pick<
  UserIdVerificationStatus,
  | 'id'
  | 'status'
  | 'statusFirstStep'
  | 'statusSecondStep'
  | 'submittedAt'
  | 'completedAt'
  | 'createdAt'
  | 'updatedAt'
> & {
    reportStatus: Maybe<
      { __typename?: 'UserIdReportStatus' } & Pick<
        UserIdReportStatus,
        | 'document'
        | 'facial_similarity_motion'
        | 'facial_similarity_video'
        | 'identity_enhanced'
        | 'proof_of_address'
        | 'watch_list'
      >
    >;
  };

export type MyVerificationStatusV2FieldsFragment = {
  __typename?: 'UserIdVerificationStatusV2';
} & Pick<
  UserIdVerificationStatusV2,
  'id' | 'status' | 'submittedAt' | 'completedAt' | 'createdAt' | 'updatedAt'
> & {
    reportStatus: Maybe<
      { __typename?: 'UserIdReportStatusV2' } & Pick<
        UserIdReportStatusV2,
        'document' | 'face' | 'address' | 'aml'
      >
    >;
  };

export type WalletFieldsFragment = { __typename?: 'Wallet' } & Pick<
  Wallet,
  | 'balance'
  | 'bonus'
  | 'bonusUsedInThisMonth'
  | 'defaultBetMonthLimit'
  | 'cashFromDeposit'
  | 'bonusBeforeBet'
  | 'bonusAfterBet'
  | 'updatedAt'
>;

type WalletHistoryFields_BalanceWalletHistory_Fragment = {
  __typename?: 'BalanceWalletHistory';
} & Pick<
  BalanceWalletHistory,
  | 'id'
  | 'amount'
  | 'transactionId'
  | 'transactionType'
  | 'isShown'
  | 'createdAt'
  | 'transactionText'
  | 'text'
>;

type WalletHistoryFields_MatchWalletHistory_Fragment = {
  __typename?: 'MatchWalletHistory';
} & Pick<
  MatchWalletHistory,
  | 'id'
  | 'amount'
  | 'transactionId'
  | 'transactionType'
  | 'isShown'
  | 'createdAt'
  | 'transactionText'
  | 'text'
>;

export type WalletHistoryFieldsFragment =
  | WalletHistoryFields_BalanceWalletHistory_Fragment
  | WalletHistoryFields_MatchWalletHistory_Fragment;

export type MatchWalletHistoryFieldsFragment = {
  __typename?: 'MatchWalletHistory';
} & Pick<
  MatchWalletHistory,
  | 'id'
  | 'amount'
  | 'transactionId'
  | 'transactionType'
  | 'isShown'
  | 'createdAt'
  | 'transactionText'
  | 'text'
> & {
    bet: { __typename?: 'BetListV2Output' } & Pick<
      BetListV2Output,
      | 'totalBonusReturns'
      | 'totalBonusBets'
      | 'bonusProfit'
      | 'totalCashReturns'
      | 'totalCashBets'
      | 'cashProfit'
    >;
    match: { __typename?: 'SimplifiedMatch' } & Pick<SimplifiedMatch, 'id'> & {
        competitors: Array<
          { __typename?: 'SimplifiedCompetitor' } & Pick<
            SimplifiedCompetitor,
            'id' | 'avatarImageUrl'
          > & {
              name: { __typename?: 'LocalizedStrings' } & Pick<
                LocalizedStrings,
                'en_GB'
              >;
            }
        >;
      };
  };

export type AcceptTermAndConditionMutationVariables = Exact<{
  input: AcceptTermAndConditionInput;
}>;

export type AcceptTermAndConditionMutation = { __typename?: 'Mutation' } & {
  acceptTermAndCondition: Maybe<
    { __typename?: 'AcceptTermAndConditionOutput' } & Pick<
      AcceptTermAndConditionOutput,
      'accepted'
    >
  >;
};

export type SubmitActivationCodeMutationVariables = Exact<{
  input: SubmitActivationCodeInput;
}>;

export type SubmitActivationCodeMutation = { __typename?: 'Mutation' } & {
  submitActivationCode: Maybe<
    { __typename?: 'SubmitActivationCodeOutput' } & Pick<
      SubmitActivationCodeOutput,
      'isMatch'
    >
  >;
};

export type RequestActivationCodeMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RequestActivationCodeMutation = { __typename?: 'Mutation' } & {
  requestActivationCode: Maybe<
    { __typename?: 'RequestActivationCodeOutput' } & {
      waitingList: Maybe<
        { __typename?: 'WaitingListUserResponse' } & Pick<
          WaitingListUserResponse,
          'id' | 'intercomContactId'
        >
      >;
    }
  >;
};

export type SendUserActivityMutationVariables = Exact<{
  input: SendUserActivityInput;
}>;

export type SendUserActivityMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'sendUserActivity'
>;

export type BetMutationVariables = Exact<{
  input: BetInput;
}>;

export type BetMutation = { __typename?: 'Mutation' } & {
  bet: { __typename?: 'BetOutput' } & {
    bet: { __typename?: 'Bet' } & BetHistoryFieldsFragment;
    wallet: { __typename?: 'Wallet' } & WalletFieldsFragment;
  };
};

export type BetV2MutationVariables = Exact<{
  input: BetV2Input;
}>;

export type BetV2Mutation = { __typename?: 'Mutation' } & {
  betV2: { __typename?: 'BetOutput' } & {
    bet: { __typename?: 'Bet' } & BetHistoryFieldsFragment;
    wallet: { __typename?: 'Wallet' } & WalletFieldsFragment;
  };
};

export type ConfirmMyBetResultMutationVariables = Exact<{
  input: ConfirmMyBetResultInput;
}>;

export type ConfirmMyBetResultMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'confirmMyBetResult'
>;

export type CreateLiveStreamingContentMutationVariables = Exact<{
  input: CreateLiveStreamingContentInput;
}>;

export type CreateLiveStreamingContentMutation = { __typename?: 'Mutation' } & {
  createLiveStreamingContent: Maybe<
    { __typename?: 'LiveStreamingContent' } & StreamingContentFieldsFragment
  >;
};

export type StartLiveStreamingContentMutationVariables = Exact<{
  input: StartLiveStreamingContentInput;
}>;

export type StartLiveStreamingContentMutation = { __typename?: 'Mutation' } & {
  startLiveStreamingContentV2: Maybe<
    { __typename?: 'StartLiveStreamingContentOutputV2' } & {
      agora: {
        __typename?: 'AgoraChannelInformation';
      } & AgoraChannelInformationFieldsFragment;
    }
  >;
};

export type NotifyLivePublishingIsStartedMutationVariables = Exact<{
  contentId: Scalars['ID'];
}>;

export type NotifyLivePublishingIsStartedMutation = {
  __typename?: 'Mutation';
} & {
  notifyLivePublishingIsStartedV2: {
    __typename?: 'LiveStreamingContent';
  } & StreamingContentFieldsFragment;
};

export type FinishLiveStreamingContentMutationVariables = Exact<{
  input: FinishLiveStreamingContentInput;
}>;

export type FinishLiveStreamingContentMutation = { __typename?: 'Mutation' } & {
  finishLiveStreamingContent: {
    __typename?: 'LiveStreamingContent';
  } & StreamingContentFieldsFragment;
};

export type StartOnfidoVerificationMutationVariables = Exact<{
  input: StartOnfidoVerificationInput;
}>;

export type StartOnfidoVerificationMutation = { __typename?: 'Mutation' } & {
  startOnfidoVerification: Maybe<
    { __typename?: 'StartOnfidoVerificationOutput' } & Pick<
      StartOnfidoVerificationOutput,
      'sdkToken'
    >
  >;
};

export type StartOnfidoVerificationV2MutationVariables = Exact<{
  input: StartOnfidoVerificationInput;
}>;

export type StartOnfidoVerificationV2Mutation = { __typename?: 'Mutation' } & {
  startOnfidoVerificationV2: Maybe<
    { __typename?: 'StartOnfidoVerificationOutput' } & Pick<
      StartOnfidoVerificationOutput,
      'sdkToken'
    >
  >;
};

export type CreateOnfidoCheckMutationVariables = Exact<{
  input: Maybe<CreateCheckOnfidoInput>;
}>;

export type CreateOnfidoCheckMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'createOnfidoCheck'
>;

export type CreateOnfidoCheckV2MutationVariables = Exact<{
  input: Maybe<CreateCheckOnfidoV2Input>;
}>;

export type CreateOnfidoCheckV2Mutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'createOnfidoCheckV2'
>;

export type CompleteOnfidoCheckMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type CompleteOnfidoCheckMutation = { __typename?: 'Mutation' } & {
  completeOnfidoCheck: Maybe<
    {
      __typename?: 'UserIdVerificationStatus';
    } & MyVerificationStatusFieldsFragment
  >;
};

export type IntercomUpdateUserMutationVariables = Exact<{
  input: IntercomUpdateUserInput;
}>;

export type IntercomUpdateUserMutation = { __typename?: 'Mutation' } & {
  intercomUpdateUser: Maybe<
    { __typename?: 'IntercomUpdateUserOutput' } & Pick<
      IntercomUpdateUserOutput,
      'success'
    >
  >;
};

export type JoinLiveStreamingChannelCustomerMutationVariables = Exact<{
  contentId: Scalars['ID'];
}>;

export type JoinLiveStreamingChannelCustomerMutation = {
  __typename?: 'Mutation';
} & {
  joinLiveStreamingChannelCustomer: Maybe<
    { __typename?: 'JoinToTalkOutput' } & {
      agora: Maybe<
        {
          __typename?: 'AgoraChannelInformation';
        } & AgoraChannelInformationFieldsFragment
      >;
      channelJoinDetail: Maybe<
        { __typename?: 'ChannelJoinDetail' } & ChannelJoinDetailFieldsFragment
      >;
    }
  >;
};

export type SendStreamingContentChatMessageMutationVariables = Exact<{
  matchId: Scalars['ID'];
  contentId: Maybe<Scalars['ID']>;
  message: Scalars['String'];
}>;

export type SendStreamingContentChatMessageMutation = {
  __typename?: 'Mutation';
} & {
  sendStreamingContentChatMessage: Maybe<
    {
      __typename?: 'StreamingContentChatMessage';
    } & StreamingContentChatMessageFieldsFragment
  >;
};

export type BecomeLiveStreamingAudienceMutationVariables = Exact<{
  contentId: Scalars['ID'];
}>;

export type BecomeLiveStreamingAudienceMutation = {
  __typename?: 'Mutation';
} & {
  becomeAudience: Maybe<
    { __typename?: 'JoinToTalkOutput' } & {
      agora: Maybe<
        {
          __typename?: 'AgoraChannelInformation';
        } & AgoraChannelInformationFieldsFragment
      >;
      channelJoinDetail: Maybe<
        { __typename?: 'ChannelJoinDetail' } & ChannelJoinDetailFieldsFragment
      >;
    }
  >;
};

export type LeaveLiveStreamingChannelMutationVariables = Exact<{
  contentId: Scalars['ID'];
}>;

export type LeaveLiveStreamingChannelMutation = { __typename?: 'Mutation' } & {
  leaveLiveStreamingChannel: Maybe<
    { __typename?: 'JoinToTalkOutput' } & {
      agora: Maybe<
        {
          __typename?: 'AgoraChannelInformation';
        } & AgoraChannelInformationFieldsFragment
      >;
      channelJoinDetail: Maybe<
        { __typename?: 'ChannelJoinDetail' } & ChannelJoinDetailFieldsFragment
      >;
    }
  >;
};

export type UnlockMarketsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UnlockMarketsMutation = { __typename?: 'Mutation' } & {
  unlockMarkets: Maybe<
    { __typename?: 'UnlockedMarketsOutput' } & Pick<
      UnlockedMarketsOutput,
      'id' | 'gradeId' | 'matchId'
    >
  >;
};

export type UnlockPastVideoMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UnlockPastVideoMutation = { __typename?: 'Mutation' } & {
  unlockPastVideo: Maybe<
    { __typename?: 'UnlockedPastVideoOutput' } & Pick<
      UnlockedPastVideoOutput,
      'id' | 'gradeId' | 'matchId'
    >
  >;
};

export type UnlockMatchGroupPlayerStatMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UnlockMatchGroupPlayerStatMutation = { __typename?: 'Mutation' } & {
  unlockMatchGroupPlayerStat: Maybe<
    { __typename?: 'UnlockedMatchGroupPlayerStatOutput' } & Pick<
      UnlockedMatchGroupPlayerStatOutput,
      'id' | 'groupPlayerStatId' | 'matchId'
    >
  >;
};

export type AddUserFirebaseRegistrationTokenMutationVariables = Exact<{
  firebaseRegistrationToken: Scalars['ID'];
}>;

export type AddUserFirebaseRegistrationTokenMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'addUserFirebaseRegistrationToken'>;

export type SaveRealityCheckMutationVariables = Exact<{
  input: SaveRealityCheckInput;
}>;

export type SaveRealityCheckMutation = { __typename?: 'Mutation' } & {
  saveRealityCheck: Maybe<
    { __typename?: 'UserSettings' } & UserSettingsFieldsFragment
  >;
};

export type SaveWalletHistoryIsShownMutationVariables = Exact<{
  input: SaveWalletHistoryIsShownInput;
}>;

export type SaveWalletHistoryIsShownMutation = { __typename?: 'Mutation' } & {
  saveWalletHistoryIsShown: {
    __typename?: 'SaveWalletHistoryIsShownOutput';
  } & {
    histories: { __typename?: 'HistoriesOutput' } & Pick<
      HistoriesOutput,
      'id' | 'isShown'
    >;
  };
};

export type SaveLimitationSettingsMutationVariables = Exact<{
  input: SaveLimitationSettingsInput;
}>;

export type SaveLimitationSettingsMutation = { __typename?: 'Mutation' } & {
  saveLimitationSettings: {
    __typename?: 'UserSettings';
  } & UserSettingsFieldsFragment;
};

export type SaveTimeoutMutationVariables = Exact<{
  input: SaveTimeoutInput;
}>;

export type SaveTimeoutMutation = { __typename?: 'Mutation' } & {
  saveTimeout: Maybe<
    { __typename?: 'UserSettings' } & UserSettingsFieldsFragment
  >;
};

export type SaveSelfExclusionMutationVariables = Exact<{
  input: SaveSelfExclusionInput;
}>;

export type SaveSelfExclusionMutation = { __typename?: 'Mutation' } & {
  saveSelfExclusion: Maybe<
    { __typename?: 'UserSettings' } & UserSettingsFieldsFragment
  >;
};

export type HandlePendingLimitationValueMutationVariables = Exact<{
  input: HandlePendingValueInput;
}>;

export type HandlePendingLimitationValueMutation = {
  __typename?: 'Mutation';
} & {
  handlePendingLimitationValue: {
    __typename?: 'UserSettings';
  } & UserSettingsFieldsFragment;
};

export type CloseAccountMutationVariables = Exact<{
  input: CloseAccountInput;
}>;

export type CloseAccountMutation = { __typename?: 'Mutation' } & {
  closeAccount: { __typename?: 'User' } & Pick<
    User,
    'cognitoUUID' | 'createdAt' | 'id'
  >;
};

export type CreateUserWithCognitoUuidMutationVariables = Exact<{
  input: CreateUserWithCognitoUuidInput;
}>;

export type CreateUserWithCognitoUuidMutation = { __typename?: 'Mutation' } & {
  createUserWithCognitoUUID: { __typename?: 'User' } & MeFieldsFragment;
};

export type UpdateUserPropertiesMutationVariables = Exact<{
  input: UpdateUserPropertiesInput;
}>;

export type UpdateUserPropertiesMutation = { __typename?: 'Mutation' } & {
  updateUserProperties: { __typename?: 'User' } & MeFieldsFragment;
};

export type FaceIdAuthAcceptTermAndConditionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type FaceIdAuthAcceptTermAndConditionMutation = {
  __typename?: 'Mutation';
} & {
  faceIdAuthAcceptTermAndCondition: Maybe<
    { __typename?: 'FaceIdAuthTermAndConditionOutput' } & Pick<
      FaceIdAuthTermAndConditionOutput,
      'success'
    >
  >;
};

export type CompleteUserSignInBonusMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CompleteUserSignInBonusMutation = { __typename?: 'Mutation' } & {
  completeUserSignInBonus: Maybe<
    { __typename?: 'CompleteUserSignInBonusOutput' } & Pick<
      CompleteUserSignInBonusOutput,
      'success'
    >
  >;
};

export type DepositMutationVariables = Exact<{
  input: DepositInput;
}>;

export type DepositMutation = { __typename?: 'Mutation' } & {
  deposit: Maybe<{ __typename?: 'Wallet' } & WalletFieldsFragment>;
};

export type WithdrawMutationVariables = Exact<{
  input: WithdrawInput;
}>;

export type WithdrawMutation = { __typename?: 'Mutation' } & {
  withdraw: Maybe<{ __typename?: 'Wallet' } & WalletFieldsFragment>;
};

export type EveryMatrixInitDepositMutationVariables = Exact<{
  input: EveryMatrixInitDepositInput;
}>;

export type EveryMatrixInitDepositMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'everyMatrixInitDeposit'
>;

export type EveryMatrixInitWithdrawMutationVariables = Exact<{
  input: EveryMatrixInitWithdrawInput;
}>;

export type EveryMatrixInitWithdrawMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'everyMatrixInitWithdraw'>;

export type EveryMatrixApproveWithdrawMutationVariables = Exact<{
  input: EveryMatrixProcessWithdrawInput;
}>;

export type EveryMatrixApproveWithdrawMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'everyMatrixApproveWithdraw'>;

export type EveryMatrixRejectWithdrawMutationVariables = Exact<{
  input: EveryMatrixProcessWithdrawInput;
}>;

export type EveryMatrixRejectWithdrawMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'everyMatrixRejectWithdraw'>;

export type GetCodeActivationEnabledQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCodeActivationEnabledQuery = { __typename?: 'Query' } & {
  getCodeActivationEnabled: Maybe<
    { __typename?: 'GetCodeActivationEnabledConfigOutPut' } & {
      codeActivationEnabled: Maybe<
        { __typename?: 'CodeActivationEnabled' } & Pick<
          CodeActivationEnabled,
          'id' | 'isEnabled'
        >
      >;
    }
  >;
};

export type GetAddressQueryVariables = Exact<{
  postalCode: Scalars['String'];
}>;

export type GetAddressQuery = { __typename?: 'Query' } & {
  getAddress: { __typename?: 'GetOnfidoAddressOutput' } & Pick<
    GetOnfidoAddressOutput,
    'postcode' | 'country' | 'city' | 'addressLine1'
  >;
};

export type GetForceUpgradeAppVersionConfigQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetForceUpgradeAppVersionConfigQuery = { __typename?: 'Query' } & {
  getForceUpgradeAppVersionConfig: Maybe<
    { __typename?: 'GetForceUpgradeAppVersionConfigOutput' } & {
      forceUpgradeConfig: Maybe<
        { __typename?: 'ForceUpgradeAppVersionConfig' } & Pick<
          ForceUpgradeAppVersionConfig,
          'id' | 'iosVersion' | 'androidVersion'
        >
      >;
    }
  >;
};

export type SearchBonusConfigsQueryVariables = Exact<{
  params: SearchBonusConfigsParams;
}>;

export type SearchBonusConfigsQuery = { __typename?: 'Query' } & {
  searchBonusConfigs: { __typename?: 'SearchBonusConfigsOutput' } & Pick<
    SearchBonusConfigsOutput,
    'nextKey'
  > & {
      configs: Array<
        { __typename?: 'BonusConfigV2' } & BonusConfigV2FieldsFragment
      >;
    };
};

export type GetCampaignsQueryVariables = Exact<{
  params: GetCampaignsParams;
}>;

export type GetCampaignsQuery = { __typename?: 'Query' } & {
  getCampaigns: Maybe<
    { __typename?: 'GetCampaignsOutput' } & {
      campaigns: Array<
        { __typename?: 'Campaign' } & Pick<
          Campaign,
          'id' | 'key' | 'createdAt' | 'updatedAt'
        > & {
            value: {
              __typename?: 'LocalizedStrings';
            } & LocalizedStringFieldsFragment;
          }
      >;
    }
  >;
};

export type GetCountryLanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountryLanguagesQuery = { __typename?: 'Query' } & {
  getCountryLanguages: Maybe<
    { __typename?: 'GetCountryLanguageOutput' } & Pick<
      GetCountryLanguageOutput,
      'countryLanguage'
    >
  >;
};

export type GetListYoutubeVideosQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetListYoutubeVideosQuery = { __typename?: 'Query' } & {
  listYoutubeVideos: Array<
    { __typename?: 'YoutubeVideo' } & Pick<YoutubeVideo, 'embedUrl'> & {
        thumbnails: { __typename?: 'YoutubeVideoThumbnail' } & Pick<
          YoutubeVideoThumbnail,
          'default' | 'medium' | 'high'
        >;
      }
  >;
};

export type ListMarketsByMatchQueryVariables = Exact<{
  id: Scalars['ID'];
  params: ListMarketsParams;
}>;

export type ListMarketsByMatchQuery = { __typename?: 'Query' } & {
  listMarketsByMatchV2: Maybe<
    {
      __typename?: 'ListMarketsByMatchOutput';
    } & ListMarketsByMatchOutputFieldsFragment
  >;
};

export type ListMarketsByMatchV3QueryVariables = Exact<{
  id: Scalars['ID'];
  params: ListMarketsParams;
}>;

export type ListMarketsByMatchV3Query = { __typename?: 'Query' } & {
  listMarketsByMatchV3: Maybe<
    {
      __typename?: 'ListMarketsByMatchV3Output';
    } & ListMarketsByMatchOutputFieldsV3Fragment
  >;
};

export type ListMarketResultsByMatchQueryVariables = Exact<{
  id: Scalars['ID'];
  settledAtAfter: Maybe<Scalars['AWSDateTime']>;
}>;

export type ListMarketResultsByMatchQuery = { __typename?: 'Query' } & {
  listMarketResultsByMatch: Maybe<
    { __typename?: 'ListMarketResultsByMatchOutput' } & {
      marketGroups: Array<
        { __typename?: 'MarketGroup' } & InplayMarketGroupFieldsFragment
      >;
    }
  >;
};

export type ListMyBetsQueryVariables = Exact<{
  matchId: Scalars['ID'];
  marketId: Maybe<Scalars['ID']>;
}>;

export type ListMyBetsQuery = { __typename?: 'Query' } & {
  listMyBets: { __typename?: 'BetListOutput' } & Pick<
    BetListOutput,
    'totalReturns' | 'totalBets' | 'profit'
  > & { bets: Array<{ __typename?: 'Bet' } & BetHistoryFieldsFragment> };
};

export type ListMyBetsV2QueryVariables = Exact<{
  matchId: Scalars['ID'];
  marketId: Maybe<Scalars['ID']>;
}>;

export type ListMyBetsV2Query = { __typename?: 'Query' } & {
  listMyBetsV2: { __typename?: 'BetListV2Output' } & Pick<
    BetListV2Output,
    | 'totalBonusReturns'
    | 'totalBonusBets'
    | 'bonusProfit'
    | 'totalCashReturns'
    | 'totalCashBets'
    | 'cashProfit'
    | 'totalBetCount'
    | 'totalCorrectBetCount'
    | 'totalUnconfirmedBetCount'
  > & { bets: Array<{ __typename?: 'Bet' } & BetHistoryFieldsFragment> };
};

export type GetInplayMarketQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetInplayMarketQuery = { __typename?: 'Query' } & {
  getInplayMarket: Maybe<
    { __typename?: 'InplayMarket' } & InplayMarketFieldsFragment
  >;
};

export type GetUnlockMarketsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUnlockMarketsQuery = { __typename?: 'Query' } & {
  getUnlockMarkets: Maybe<
    { __typename?: 'GetUnlockMarketsOutput' } & Pick<
      GetUnlockMarketsOutput,
      | 'matchId'
      | 'isUnlockedMarkets'
      | 'marketGemPrice'
      | 'isUnlockedPastVideo'
      | 'pastVideoGemPrice'
      | 'hidePastVideo'
    >
  >;
};

export type SearchMatchesQueryVariables = Exact<{
  params: SearchMatchesParams;
}>;

export type SearchMatchesQuery = { __typename?: 'Query' } & {
  searchMatches: { __typename?: 'SearchMatchesOutput' } & Pick<
    SearchMatchesOutput,
    'nextKey'
  > & { matches: Array<{ __typename?: 'Match' } & MatchFieldsFragment> };
};

export type SearchMatchesBySportQueryVariables = Exact<{
  sportId: Maybe<Scalars['ID']>;
  startKey: Maybe<Scalars['String']>;
  size: Scalars['Int'];
  country: UserCountryCodeIso1Alpha2;
}>;

export type SearchMatchesBySportQuery = { __typename?: 'Query' } & {
  searchMatches: { __typename?: 'SearchMatchesOutput' } & Pick<
    SearchMatchesOutput,
    'nextKey'
  > & { matches: Array<{ __typename?: 'Match' } & MatchFieldsFragment> };
};

export type GetMatchQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMatchQuery = { __typename?: 'Query' } & {
  getMatch: Maybe<{ __typename?: 'Match' } & MatchFieldsFragment>;
};

export type GetMatchPlayerStatsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMatchPlayerStatsQuery = { __typename?: 'Query' } & {
  getMatchPlayerStats: Maybe<
    { __typename?: 'GetMatchPlayerStatsOutput' } & Pick<
      GetMatchPlayerStatsOutput,
      'isUnlocked' | 'gemPrice'
    > & {
        playerStatTable: Maybe<
          {
            __typename?: 'MatchPlayerStatsOutput';
          } & MatchPlayerStatsFieldsFragment
        >;
      }
  >;
};

export type GetMatchGeniusSportsMediaQueryVariables = Exact<{
  matchId: Scalars['ID'];
}>;

export type GetMatchGeniusSportsMediaQuery = { __typename?: 'Query' } & {
  getMatchGeniusSportsMedia: Maybe<
    { __typename?: 'GeniusSportsMedia' } & {
      videoStreaming: Maybe<
        { __typename?: 'GeniusSportsVideoStreaming' } & Pick<
          GeniusSportsVideoStreaming,
          'videoStreamingUrl'
        >
      >;
      tracker: Maybe<
        { __typename?: 'GeniusSportsTracker' } & Pick<
          GeniusSportsTracker,
          'liveTrackerUrl'
        >
      >;
    }
  >;
};

export type GetMatchSummaryQueryVariables = Exact<{
  matchId: Scalars['ID'];
}>;

export type GetMatchSummaryQuery = { __typename?: 'Query' } & {
  getMatchSummary: Maybe<
    | ({
        __typename?: 'BasketBallMatchSummary';
      } & MatchSummaryFields_BasketBallMatchSummary_Fragment)
    | ({
        __typename?: 'FootballMatchSummary';
      } & MatchSummaryFields_FootballMatchSummary_Fragment)
  >;
};

export type ListMatchGradesQueryVariables = Exact<{
  params: Maybe<ListMatchGradesParams>;
}>;

export type ListMatchGradesQuery = { __typename?: 'Query' } & {
  listMatchGrades: Array<{ __typename?: 'MatchGrade' } & GradeFieldsFragment>;
};

export type ListAllMatchCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListAllMatchCategoriesQuery = { __typename?: 'Query' } & {
  listAllMatchCategories: Array<
    { __typename?: 'MatchCategory' } & CategoryFieldsFragment
  >;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = { __typename?: 'Query' } & {
  me: Maybe<{ __typename?: 'User' } & MeFieldsFragment>;
};

export type GetMeV2QueryVariables = Exact<{ [key: string]: never }>;

export type GetMeV2Query = { __typename?: 'Query' } & {
  meV2: Maybe<{ __typename?: 'User' } & MeFieldsFragment>;
};

export type GetMyVerificationStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMyVerificationStatusQuery = { __typename?: 'Query' } & {
  myIdVerificationStatus: Maybe<
    {
      __typename?: 'UserIdVerificationStatus';
    } & MyVerificationStatusFieldsFragment
  >;
};

export type GetMyVerificationStatusV2QueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMyVerificationStatusV2Query = { __typename?: 'Query' } & {
  myIdVerificationStatusV2: Maybe<
    {
      __typename?: 'UserIdVerificationStatusV2';
    } & MyVerificationStatusV2FieldsFragment
  >;
};

export type GetVerificationUrlQueryVariables = Exact<{
  params: Maybe<GetVerificationUrlParams>;
}>;

export type GetVerificationUrlQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getVerificationUrl'
>;

export type GetMySettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMySettingsQuery = { __typename?: 'Query' } & {
  mySettings: Maybe<
    { __typename?: 'UserSettings' } & UserSettingsFieldsFragment
  >;
};

export type GetMeAsStreamerQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeAsStreamerQuery = { __typename?: 'Query' } & {
  meAsStreamer: Maybe<
    { __typename?: 'Streamer' } & StreamerDetailFieldsFragment
  >;
};

export type GetMyGamstopStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyGamstopStatusQuery = { __typename?: 'Query' } & Pick<
  Query,
  'myGamstopStatus'
>;

export type SearchMyBetHistoryQueryVariables = Exact<{
  params: MyBetsHistoryParams;
}>;

export type SearchMyBetHistoryQuery = { __typename?: 'Query' } & {
  searchMyBetsHistory: { __typename?: 'BetsOutputs' } & Pick<
    BetsOutputs,
    'nextKey'
  > & { bets: Array<{ __typename?: 'Bet' } & BetHistoryFieldsFragment> };
};

export type GetMyOnfidoBreakdownReasoningQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMyOnfidoBreakdownReasoningQuery = { __typename?: 'Query' } & {
  myOnfidoBreakdownReasoning: Maybe<
    { __typename?: 'GetMyOnfidoBreakdownReasoning' } & {
      reason: { __typename?: 'OnfidoReportBreakDownReasoningOutput' } & Pick<
        OnfidoReportBreakDownReasoningOutput,
        'identityEnhanced' | 'watchlistAml'
      > & {
          document: Maybe<
            {
              __typename?: 'OnfidoReportDocumentBreakDownReasoning';
            } & DocumentFieldFragment
          >;
          facialSimilarityMotion: Maybe<
            {
              __typename?: 'OnfidoReportFacialSimilarityVideoBreakDownReasoning';
            } & FacialSimilarityVideoFieldFragment
          >;
          facialSimilarityVideo: Maybe<
            {
              __typename?: 'OnfidoReportFacialSimilarityVideoBreakDownReasoning';
            } & FacialSimilarityVideoFieldFragment
          >;
          proofOfAddress: Maybe<
            {
              __typename?: 'OnfidoReportProofOfAddressBreakDownReasoning';
            } & ProofOfAddressFieldFragment
          >;
        };
    }
  >;
};

export type GetMyOnfidoBreakdownReasoningV2QueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMyOnfidoBreakdownReasoningV2Query = { __typename?: 'Query' } & {
  myOnfidoBreakdownReasoningV2: Maybe<
    { __typename?: 'GetMyOnfidoBreakdownReasoningV2' } & Pick<
      GetMyOnfidoBreakdownReasoningV2,
      'document' | 'face' | 'address' | 'aml'
    >
  >;
};

export type GetProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProfileQuery = { __typename?: 'Query' } & {
  getProfile: Maybe<
    | ({
        __typename?: 'LeagueProfile';
      } & BetTargetProfileFields_LeagueProfile_Fragment)
    | ({
        __typename?: 'ManagerProfile';
      } & BetTargetProfileFields_ManagerProfile_Fragment)
    | ({
        __typename?: 'PlayerProfile';
      } & BetTargetProfileFields_PlayerProfile_Fragment)
    | ({
        __typename?: 'TeamProfile';
      } & BetTargetProfileFields_TeamProfile_Fragment)
  >;
};

export type GetMyProfileImageUploadUrlQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMyProfileImageUploadUrlQuery = { __typename?: 'Query' } & {
  myProfileImageUploadUrl: { __typename?: 'MySignedUrlOutput' } & Pick<
    MySignedUrlOutput,
    'key' | 'url'
  >;
};

export type GetGlobalSettingQueryVariables = Exact<{
  params: GlobalSettingParams;
}>;

export type GetGlobalSettingQuery = { __typename?: 'Query' } & {
  globalSettings: Maybe<
    { __typename?: 'GlobalSettings' } & Pick<
      GlobalSettings,
      | 'maxBetUU'
      | 'maxBetLimitPerBet'
      | 'maxBetLimitPerMarket'
      | 'minimumBetWager'
      | 'minDeposit'
      | 'maxDeposit'
      | 'minWithDraw'
      | 'maxWithDraw'
      | 'availableCountries'
      | 'countryCodes'
      | 'latestVersion'
      | 'myCountryCode'
    > & {
        bonusConfig: Maybe<
          { __typename?: 'BonusConfig' } & BonusConfigFieldsFragment
        >;
      }
  >;
};

export type CheckIsValidUkPostcodeQueryVariables = Exact<{
  input: CheckIsValidPostCodeInput;
}>;

export type CheckIsValidUkPostcodeQuery = { __typename?: 'Query' } & Pick<
  Query,
  'checkIsValidUkPostcode'
>;

export type GetMyIntercomUserHashQueryVariables = Exact<{
  platform: IntercomPlatform;
}>;

export type GetMyIntercomUserHashQuery = { __typename?: 'Query' } & Pick<
  Query,
  'myIntercomUserHash'
>;

export type GetIsReviewerPhoneNumberQueryVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;

export type GetIsReviewerPhoneNumberQuery = { __typename?: 'Query' } & Pick<
  Query,
  'checkIsReviewerPhoneNumber'
>;

export type GetReviewerTemporaryCodeQueryVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;

export type GetReviewerTemporaryCodeQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getReviewerTemporaryCode'
>;

export type GetTermAndConditionEnabledQueryVariables = Exact<{
  params: GetTermAndConditionEnabledParams;
}>;

export type GetTermAndConditionEnabledQuery = { __typename?: 'Query' } & {
  getTermAndConditionEnabled: Maybe<
    { __typename?: 'GetTermAndConditionEnabledOutput' } & {
      termAndCondition: {
        __typename?: 'TermAndCondition';
      } & TermAndConditionFieldsFragment;
    }
  >;
};

export type SearchMyWalletHistoryQueryVariables = Exact<{
  params: MyWalletsHistoryParams;
}>;

export type SearchMyWalletHistoryQuery = { __typename?: 'Query' } & {
  searchMyWalletHistory: { __typename?: 'WalletHistoriesOutput' } & Pick<
    WalletHistoriesOutput,
    'nextKey'
  > & {
      history: Array<
        | ({ __typename?: 'BalanceWalletHistory' } & Pick<
            BalanceWalletHistory,
            'id' | 'text' | 'transactionText'
          > &
            WalletHistoryFields_BalanceWalletHistory_Fragment)
        | ({
            __typename?: 'MatchWalletHistory';
          } & WalletHistoryFields_MatchWalletHistory_Fragment &
            MatchWalletHistoryFieldsFragment)
      >;
    };
};

export type GetMyTransactionSummaryQueryVariables = Exact<{
  params: MyTransactionSummaryParams;
}>;

export type GetMyTransactionSummaryQuery = { __typename?: 'Query' } & {
  getMyTransactionSummary: { __typename?: 'TransactionSummaryOutput' } & Pick<
    TransactionSummaryOutput,
    | 'totalDeposits'
    | 'totalWithdrawals'
    | 'totalCashReturns'
    | 'totalCashBets'
    | 'totalBonusReturns'
    | 'totalBonusBets'
    | 'nextKey'
  >;
};

export type GetMyNetDepositsQueryVariables = Exact<{
  params: MyNetDepositParams;
}>;

export type GetMyNetDepositsQuery = { __typename?: 'Query' } & {
  myNetDeposit: { __typename?: 'NetDepositOutput' } & Pick<
    NetDepositOutput,
    'netDeposit' | 'totalDeposit' | 'totalWithdrawal'
  > & {
      netDepositsBySource: Maybe<
        Array<
          { __typename?: 'NetDepositBySource' } & Pick<
            NetDepositBySource,
            | 'netDeposit'
            | 'source'
            | 'totalDeposit'
            | 'totalWithdrawal'
            | 'isAvailable'
          >
        >
      >;
    };
};

export type GetEveryMatrixPendingWithdrawalTransactionCodeQueryVariables =
  Exact<{ [key: string]: never }>;

export type GetEveryMatrixPendingWithdrawalTransactionCodeQuery = {
  __typename?: 'Query';
} & {
  everyMatrixGetPendingWithdrawalTransactionCode: Maybe<
    {
      __typename?: 'EveryMatrixGetPendingWithdrawalTransactionCodeOutput';
    } & Pick<
      EveryMatrixGetPendingWithdrawalTransactionCodeOutput,
      'amount' | 'createdAt' | 'transactionCode'
    >
  >;
};

export type OnStreamingContentChatMessageUpdatedSubscriptionVariables = Exact<{
  matchId: Scalars['ID'];
  contentId: Maybe<Scalars['ID']>;
}>;

export type OnStreamingContentChatMessageUpdatedSubscription = {
  __typename?: 'Subscription';
} & {
  streamingContentChatMessageUpdated: Maybe<
    {
      __typename?: 'StreamingContentChatMessage';
    } & StreamingContentChatMessageFieldsFragment
  >;
};

export type OnLiveStreamingContentUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnLiveStreamingContentUpdatedSubscription = {
  __typename?: 'Subscription';
} & {
  liveStreamingContentUpdated: Maybe<
    { __typename?: 'LiveStreamingContent' } & StreamingContentFieldsFragment
  >;
};

export type OnInplayMarketUpdatedSubscriptionVariables = Exact<{
  matchId: Maybe<Scalars['ID']>;
}>;

export type OnInplayMarketUpdatedSubscription = {
  __typename?: 'Subscription';
} & {
  inplayMarketsUpdated: Maybe<
    { __typename?: 'NotifyMarketsUpdatedOutput' } & Pick<
      NotifyMarketsUpdatedOutput,
      'matchId'
    > & {
        markets: Array<
          {
            __typename?: 'InplayMarket';
          } & InplayMarketWithoutTargetProfileFieldsFragment
        >;
      }
  >;
};

export type OnInplayMarketUpdatedV2SubscriptionVariables = Exact<{
  matchId: Maybe<Scalars['ID']>;
}>;

export type OnInplayMarketUpdatedV2Subscription = {
  __typename?: 'Subscription';
} & {
  inplayMarketsUpdatedV2: Maybe<
    { __typename?: 'NotifyMarketsUpdatedV2Output' } & Pick<
      NotifyMarketsUpdatedV2Output,
      'matchId'
    > & {
        marketGroups: Array<
          { __typename?: 'MarketGroup' } & Pick<
            MarketGroup,
            'fourwDao' | 'sumoDao' | 'yadokariDao'
          > & {
              groupTag: Maybe<
                { __typename?: 'GroupTag' } & GroupTagFieldsFragment
              >;
              markets: Array<
                {
                  __typename?: 'InplayMarket';
                } & InplayMarketWithoutTargetProfileFieldsFragment
              >;
            }
        >;
      }
  >;
};

export type OnMatchUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnMatchUpdatedSubscription = { __typename?: 'Subscription' } & {
  matchesUpdated: Maybe<
    { __typename?: 'NotifyMatchesUpdatedOutput' } & {
      matches: Array<
        { __typename?: 'Match' } & Pick<
          Match,
          | 'finishAt'
          | 'id'
          | 'isCanceled'
          | 'isLive'
          | 'isOpen'
          | 'ivsStatus'
          | 'liveAt'
          | 'liveEndAt'
          | 'liveStartedAt'
          | 'liveStatus'
          | 'mediaSourceService'
        > & {
            mediaUrls: {
              __typename?: 'MatchMediaUrls';
            } & MatchMediaUrlFieldsFragment;
            simplifiedSummary: Maybe<
              {
                __typename?: 'SimplifiedMatchSummary';
              } & SimplifiedMatchSummaryFieldsFragment
            >;
            shortTitle: Maybe<
              {
                __typename?: 'LocalizedStrings';
              } & LocalizedStringFieldsFragment
            >;
            title: {
              __typename?: 'LocalizedStrings';
            } & LocalizedStringFieldsFragment;
          }
      >;
    }
  >;
};

export type OnVerificationStatusUpdatedSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OnVerificationStatusUpdatedSubscription = {
  __typename?: 'Subscription';
} & {
  verificationStatusUpdated: Maybe<
    { __typename?: 'UserVerificationStatusRealTimeOutput' } & Pick<
      UserVerificationStatusRealTimeOutput,
      | 'id'
      | 'status'
      | 'statusFirstStep'
      | 'statusSecondStep'
      | 'submittedAt'
      | 'completedAt'
      | 'createdAt'
      | 'updatedAt'
    > & {
        reportStatus: Maybe<
          { __typename?: 'UserIdReportStatusRealTimeOutput' } & Pick<
            UserIdReportStatusRealTimeOutput,
            | 'document'
            | 'facial_similarity_motion'
            | 'facial_similarity_video'
            | 'identity_enhanced'
            | 'proof_of_address'
            | 'watch_list'
          >
        >;
      }
  >;
};

export type OnWalletUpdatedSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OnWalletUpdatedSubscription = { __typename?: 'Subscription' } & {
  walletUpdated: Maybe<
    { __typename?: 'NotifyWalletUpdatedOutput' } & Pick<
      NotifyWalletUpdatedOutput,
      'id' | 'updatedAt'
    >
  >;
};

export const AgoraChannelInformationFieldsFragmentDoc = gql`
  fragment AgoraChannelInformationFields on AgoraChannelInformation {
    channelName
    token
    uid
  }
`;
export const LocalizedStringFieldsFragmentDoc = gql`
  fragment LocalizedStringFields on LocalizedStrings {
    en_DEFAULT
    en_GB
    en_US
    en_IE
    ja_JP
  }
`;
export const SportFieldsFragmentDoc = gql`
  fragment SportFields on Sport {
    id
    name {
      ...LocalizedStringFields
    }
    sportKey
    createdAt
    updatedAt
    mediaUrls {
      logoImageUrl
    }
  }
  ${LocalizedStringFieldsFragmentDoc}
`;
export const CategoryFieldsFragmentDoc = gql`
  fragment CategoryFields on MatchCategory {
    id
    name {
      ...LocalizedStringFields
    }
    sport {
      ...SportFields
    }
    createdAt
    updatedAt
  }
  ${LocalizedStringFieldsFragmentDoc}
  ${SportFieldsFragmentDoc}
`;
export const BetTargetProfileFieldsFragmentDoc = gql`
  fragment BetTargetProfileFields on Profile {
    id
    name {
      ...LocalizedStringFields
    }
    shortName {
      ...LocalizedStringFields
    }
    avatarImageUrl
    __typename
    ... on PlayerProfile {
      teamProfile {
        avatarImageUrl
      }
    }
    ... on ManagerProfile {
      teamProfile {
        avatarImageUrl
      }
    }
    createdAt
    updatedAt
  }
  ${LocalizedStringFieldsFragmentDoc}
`;
export const BetHistoryMatchFieldsFragmentDoc = gql`
  fragment BetHistoryMatchFields on Match {
    id
    title {
      ...LocalizedStringFields
    }
    category {
      ...CategoryFields
    }
    competitors {
      ...BetTargetProfileFields
    }
    createdAt
    updatedAt
  }
  ${LocalizedStringFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${BetTargetProfileFieldsFragmentDoc}
`;
export const InplayMarketOptionFieldsFragmentDoc = gql`
  fragment InplayMarketOptionFields on InplayMarketOption {
    id
    title {
      ...LocalizedStringFields
    }
    isWon
    odds {
      value
      updatedAt
    }
    oldOdds {
      value
      updatedAt
    }
  }
  ${LocalizedStringFieldsFragmentDoc}
`;
export const InplayMarketFieldsFragmentDoc = gql`
  fragment InplayMarketFields on InplayMarket {
    id
    matchId
    isOpen
    isWon
    openAt
    closeAt
    isVoid
    isConfirmed
    confirmedAt
    title {
      ...LocalizedStringFields
    }
    shortTitle {
      ...LocalizedStringFields
    }
    options {
      ...InplayMarketOptionFields
    }
    targetProfiles {
      ...BetTargetProfileFields
    }
    mediaUrls {
      backgroundSmallImageUrl
      backgroundLargeImageUrl
    }
    createdAt
    updatedAt
    weight
    disableBet
    disable
    isOddsBoosted
    riskControlProperties {
      minimumBetWager
      wagerLimitPerUser
      wagerLimitInTotal
      countLimit
    }
  }
  ${LocalizedStringFieldsFragmentDoc}
  ${InplayMarketOptionFieldsFragmentDoc}
  ${BetTargetProfileFieldsFragmentDoc}
`;
export const BetHistoryFieldsFragmentDoc = gql`
  fragment BetHistoryFields on Bet {
    id
    match {
      ...BetHistoryMatchFields
    }
    market {
      ...InplayMarketFields
    }
    selectedOption {
      ...InplayMarketOptionFields
    }
    betAmount: wager
    oddsValue
    estimatedReturn
    returned
    confirmationStatus
    confirmedAt
    status
    userConfirmedResult
    createdAt
    updatedAt
    postBalance
    preBalance
    isCash
    bonusReturned
    cashReturned
    postBonus
    preBonus
    preCash
    postCash
  }
  ${BetHistoryMatchFieldsFragmentDoc}
  ${InplayMarketFieldsFragmentDoc}
  ${InplayMarketOptionFieldsFragmentDoc}
`;
export const BetListOutputFieldsFragmentDoc = gql`
  fragment BetListOutputFields on BetListOutput {
    bets {
      ...BetHistoryFields
    }
    totalReturns
    totalBets
    profit
  }
  ${BetHistoryFieldsFragmentDoc}
`;
export const BetListOutputV2FieldsFragmentDoc = gql`
  fragment BetListOutputV2Fields on BetListV2Output {
    bets {
      ...BetHistoryFields
    }
    totalBonusBets
    totalBonusReturns
    bonusProfit
    totalCashBets
    totalCashReturns
    cashProfit
    totalBetCount
    totalCorrectBetCount
    totalUnconfirmedBetCount
  }
  ${BetHistoryFieldsFragmentDoc}
`;
export const BetInMarketFieldsFragmentDoc = gql`
  fragment BetInMarketFields on Bet {
    id
    selectedOption {
      ...InplayMarketOptionFields
    }
    betAmount: wager
    estimatedReturn
    returned
    confirmationStatus
    confirmedAt
    status
    createdAt
    updatedAt
    preBalance
    postBalance
  }
  ${InplayMarketOptionFieldsFragmentDoc}
`;
export const BonusConfigV2FieldsFragmentDoc = gql`
  fragment BonusConfigV2Fields on BonusConfigV2 {
    id
    typeKey
    name
    bonusType
    countType
    startAt
    endAt
    requiredCount
    maxCount
    requiredAmount
    additionalRequirements
    amount
    amountPercent
    text
    txnText
    explanation
    imageUrl
    createdAt
    updatedAt
    isEnabled
    term
    requiredAction
  }
`;
export const LiveStreamingContentFieldsFragmentDoc = gql`
  fragment LiveStreamingContentFields on LiveStreamingContent {
    audienceCount
    id
    type
    imageUrl
    matchId
    title {
      ...LocalizedStringFields
    }
    streamer {
      id
      name {
        ...LocalizedStringFields
      }
      imageUrl
      tags
    }
    streamingMatchId
    liveStartedAt
    finishedAt
    audienceCount
    sportId
    isKilled
  }
  ${LocalizedStringFieldsFragmentDoc}
`;
export const StreamingContentFieldsFragmentDoc = gql`
  fragment StreamingContentFields on StreamingContent {
    __typename
    ... on LiveStreamingContent {
      ...LiveStreamingContentFields
    }
  }
  ${LiveStreamingContentFieldsFragmentDoc}
`;
export const StreamingContentChatMessageFieldsFragmentDoc = gql`
  fragment StreamingContentChatMessageFields on StreamingContentChatMessage {
    id
    matchId
    contentId
    author {
      userId
      nickname
      imageUrl
    }
    message
    messageKey
    createdAt
  }
`;
export const ChannelJoinDetailFieldsFragmentDoc = gql`
  fragment ChannelJoinDetailFields on ChannelJoinDetail {
    contentId
    inChannel
    isAudience
    isBroadcaster
    isInvited
    isRequesting
    isSuspended
  }
`;
export const StreamerDetailFieldsFragmentDoc = gql`
  fragment StreamerDetailFields on Streamer {
    id
    name {
      ...LocalizedStringFields
    }
    categoryIds
    imageUrl
    contentImageUrlCandidates
    tags
  }
  ${LocalizedStringFieldsFragmentDoc}
`;
export const ListMarketsByMatchOutputFieldsFragmentDoc = gql`
  fragment ListMarketsByMatchOutputFields on ListMarketsByMatchOutput {
    profit
    totalBets
    totalReturns
    markets {
      ...InplayMarketFields
    }
  }
  ${InplayMarketFieldsFragmentDoc}
`;
export const GroupTagFieldsFragmentDoc = gql`
  fragment GroupTagFields on GroupTag {
    order
    name
    isLive
    closeAt
    openAt
    id
    preVideoUrl
    pastVideoUrl
    thumbnailUrl
    videoStartsAt
    videoEndsAt
    isOddsBoosted
  }
`;
export const InplayMarketGroupFieldsFragmentDoc = gql`
  fragment InplayMarketGroupFields on MarketGroup {
    groupTag {
      ...GroupTagFields
    }
    fourwDao
    sumoDao
    yadokariDao
    markets {
      ...InplayMarketFields
    }
  }
  ${GroupTagFieldsFragmentDoc}
  ${InplayMarketFieldsFragmentDoc}
`;
export const ListMarketsByMatchOutputFieldsV3FragmentDoc = gql`
  fragment ListMarketsByMatchOutputFieldsV3 on ListMarketsByMatchV3Output {
    profit
    totalBets
    totalReturns
    marketGroups {
      ...InplayMarketGroupFields
    }
  }
  ${InplayMarketGroupFieldsFragmentDoc}
`;
export const InplayMarketWithoutTargetProfileFieldsFragmentDoc = gql`
  fragment InplayMarketWithoutTargetProfileFields on InplayMarket {
    id
    matchId
    isOpen
    isWon
    openAt
    closeAt
    isVoid
    isConfirmed
    confirmedAt
    title {
      ...LocalizedStringFields
    }
    shortTitle {
      ...LocalizedStringFields
    }
    options {
      ...InplayMarketOptionFields
    }
    mediaUrls {
      backgroundSmallImageUrl
      backgroundLargeImageUrl
    }
    createdAt
    updatedAt
    weight
    disableBet
    disable
    isOddsBoosted
  }
  ${LocalizedStringFieldsFragmentDoc}
  ${InplayMarketOptionFieldsFragmentDoc}
`;
export const GradeFieldsFragmentDoc = gql`
  fragment GradeFields on MatchGrade {
    id
    name {
      ...LocalizedStringFields
    }
    createdAt
    colorCode
    point
    gemPrice
    pastVideoGemPrice
    hidePastVideo
    categoryId
  }
  ${LocalizedStringFieldsFragmentDoc}
`;
export const CompetitorFieldsFragmentDoc = gql`
  fragment CompetitorFields on Profile {
    id
    name {
      ...LocalizedStringFields
    }
    shortName {
      ...LocalizedStringFields
    }
    avatarImageUrl
    __typename
    ... on PlayerProfile {
      teamProfile {
        avatarImageUrl
      }
    }
    ... on ManagerProfile {
      teamProfile {
        avatarImageUrl
      }
    }
    createdAt
    updatedAt
  }
  ${LocalizedStringFieldsFragmentDoc}
`;
export const MatchMediaUrlFieldsFragmentDoc = gql`
  fragment MatchMediaUrlFields on MatchMediaUrls {
    fallbackImageUrl
    logoImageUrl
    videoStreamingFallbackImageUrl
    videoStreamingUrl
  }
`;
export const SimplifiedMatchSummaryFieldsFragmentDoc = gql`
  fragment SimplifiedMatchSummaryFields on SimplifiedMatchSummary {
    currentPhase
    currentStatus
    currentPhaseStartedAt
    grade {
      name
      colorCode
      textColor
    }
    liveAt
    liveEndAt
    score {
      home
      homeText
      away
      awayText
    }
    isOddsBoosted
  }
`;
export const MatchFieldsFragmentDoc = gql`
  fragment MatchFields on Match {
    id
    title {
      ...LocalizedStringFields
    }
    competitors {
      ...CompetitorFields
    }
    category {
      ...CategoryFields
    }
    liveAt
    liveEndAt
    finishAt
    isLive
    isOpen
    ivsStatus
    liveStatus
    mediaUrls {
      ...MatchMediaUrlFields
    }
    metadata
    mediaSourceService
    simplifiedSummary {
      ...SimplifiedMatchSummaryFields
    }
    streamingContentIds
    createdAt
    updatedAt
  }
  ${LocalizedStringFieldsFragmentDoc}
  ${CompetitorFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${MatchMediaUrlFieldsFragmentDoc}
  ${SimplifiedMatchSummaryFieldsFragmentDoc}
`;
export const PlayerGroupTagFieldsFragmentDoc = gql`
  fragment PlayerGroupTagFields on PlayerGroupTagOutput {
    id
    name
    order
  }
`;
export const PlayerStatsRowFieldsFragmentDoc = gql`
  fragment PlayerStatsRowFields on PlayerStatsRow {
    values
  }
`;
export const PlayerStatsTableFieldsFragmentDoc = gql`
  fragment PlayerStatsTableFields on PlayerStatsTable {
    groupTag {
      ...PlayerGroupTagFields
    }
    headers
    rows {
      ...PlayerStatsRowFields
    }
  }
  ${PlayerGroupTagFieldsFragmentDoc}
  ${PlayerStatsRowFieldsFragmentDoc}
`;
export const MatchPlayerStatsFieldsFragmentDoc = gql`
  fragment MatchPlayerStatsFields on MatchPlayerStatsOutput {
    id
    results {
      ...PlayerStatsTableFields
    }
  }
  ${PlayerStatsTableFieldsFragmentDoc}
`;
export const MatchSummaryFieldsFragmentDoc = gql`
  fragment MatchSummaryFields on MatchSummary {
    id
    sportKey
    __typename
    ... on BasketBallMatchSummary {
      id
      score {
        home
        homeText
        away
        awayText
      }
      basketballCurrentPhase: currentPhase
    }
    ... on FootballMatchSummary {
      id
      score {
        home
        homeText
        away
        awayText
      }
      currentPhaseStartedAt
      footballCurrentPhase: currentPhase
    }
  }
`;
export const FourwDaoPlayerStatFieldsFragmentDoc = gql`
  fragment FourwDaoPlayerStatFields on FourwDaoPlayerStatOutput {
    id
    name
    lane
    lapTime
  }
`;
export const FourwDaoPlayerStatsResultFieldsFragmentDoc = gql`
  fragment FourwDaoPlayerStatsResultFields on FourwDaoPlayerStatsResultOutput {
    groupTag {
      ...PlayerGroupTagFields
    }
    results {
      ...FourwDaoPlayerStatFields
    }
  }
  ${PlayerGroupTagFieldsFragmentDoc}
  ${FourwDaoPlayerStatFieldsFragmentDoc}
`;
export const SumoDaoPlayerStatFieldsFragmentDoc = gql`
  fragment SumoDaoPlayerStatFields on SumoDaoPlayerStatOutput {
    id
    name
    position
    stable
    height
    weight
  }
`;
export const SumoDaoPlayerStatsResultFieldsFragmentDoc = gql`
  fragment SumoDaoPlayerStatsResultFields on SumoDaoPlayerStatsResultOutput {
    groupTag {
      ...PlayerGroupTagFields
    }
    results {
      ...SumoDaoPlayerStatFields
    }
  }
  ${PlayerGroupTagFieldsFragmentDoc}
  ${SumoDaoPlayerStatFieldsFragmentDoc}
`;
export const YadokariDaoPlayerStatFieldsFragmentDoc = gql`
  fragment YadokariDaoPlayerStatFields on YadokariDaoPlayerStatOutput {
    id
    name
    lane
    weight
  }
`;
export const YadokariDaoPlayerStatsResultFieldsFragmentDoc = gql`
  fragment YadokariDaoPlayerStatsResultFields on YadokariDaoPlayerStatsResultOutput {
    groupTag {
      ...PlayerGroupTagFields
    }
    results {
      ...YadokariDaoPlayerStatFields
    }
  }
  ${PlayerGroupTagFieldsFragmentDoc}
  ${YadokariDaoPlayerStatFieldsFragmentDoc}
`;
export const DataComparisonFieldFragmentDoc = gql`
  fragment DataComparisonField on OnfidoReportDocumentDataComparisonBreakDown {
    gender
    lastName
    firstName
    dateOfBirth
  }
`;
export const ImageIntegrityFieldFragmentDoc = gql`
  fragment ImageIntegrityField on OnfidoReportDocumentImageIntegrityBreakDown {
    imageQuality
    conclusiveDocumentQuality
    colourPicture
    supportedDocument
  }
`;
export const DocumentFieldFragmentDoc = gql`
  fragment DocumentField on OnfidoReportDocumentBreakDownReasoning {
    ageValidation
    compromisedDocument
    dataComparison {
      ...DataComparisonField
    }
    dataConsistency
    dataValidation
    imageIntegrity {
      ...ImageIntegrityField
    }
    policeRecord
    visualAuthenticity
    issuingAuthority
  }
  ${DataComparisonFieldFragmentDoc}
  ${ImageIntegrityFieldFragmentDoc}
`;
export const FacialSimilarityVideoFieldFragmentDoc = gql`
  fragment FacialSimilarityVideoField on OnfidoReportFacialSimilarityVideoBreakDownReasoning {
    imageIntegrity
    faceComparison
    visualAuthenticity
  }
`;
export const FacialSimilarityMotionFieldFragmentDoc = gql`
  fragment FacialSimilarityMotionField on OnfidoReportFacialSimilarityVideoBreakDownReasoning {
    imageIntegrity
    faceComparison
    visualAuthenticity
  }
`;
export const ProofOfAddressFieldFragmentDoc = gql`
  fragment ProofOfAddressField on OnfidoReportProofOfAddressBreakDownReasoning {
    imageIntegrity
    dataComparison
    sourceIntegrity
    documentClassification
  }
`;
export const LimitationValueFieldsFragmentDoc = gql`
  fragment LimitationValueFields on LimitationValue {
    value
    pendingValue {
      value
      coolingOffEndsAt
      isUnderCoolingOff
    }
  }
`;
export const LimitationValuesFieldsFragmentDoc = gql`
  fragment LimitationValuesFields on LimitationValues {
    daily {
      ...LimitationValueFields
    }
    weekly {
      ...LimitationValueFields
    }
    monthly {
      ...LimitationValueFields
    }
  }
  ${LimitationValueFieldsFragmentDoc}
`;
export const LimitationSettingsFieldsFragmentDoc = gql`
  fragment LimitationSettingsFields on LimitationSettings {
    depositLimitations {
      ...LimitationValuesFields
    }
    betLimitations {
      ...LimitationValuesFields
    }
    lossLimitations {
      ...LimitationValuesFields
    }
  }
  ${LimitationValuesFieldsFragmentDoc}
`;
export const UserSettingsFieldsFragmentDoc = gql`
  fragment UserSettingsFields on UserSettings {
    limitation {
      ...LimitationSettingsFields
    }
    timeout {
      timeoutEndsAt
      isUnderCoolingOff
      coolingOffEndsAt
    }
    selfExclusion {
      selfExclusionEndsAt
    }
    realityCheck {
      enable
      frequency
    }
  }
  ${LimitationSettingsFieldsFragmentDoc}
`;
export const BonusConfigFieldsFragmentDoc = gql`
  fragment BonusConfigFields on BonusConfig {
    onIdVerification
    onIdVerificationAmount
    onIdVerificationCash
    onIdVerificationExplanation
    onSignUp
    onSignUpAmount
    onSignUpExplanation
  }
`;
export const TermAndConditionFieldsFragmentDoc = gql`
  fragment TermAndConditionFields on TermAndCondition {
    id
    version
    title
    link
    message
    isEnabled
  }
`;
export const WalletFieldsFragmentDoc = gql`
  fragment WalletFields on Wallet {
    balance
    bonus
    bonusUsedInThisMonth
    defaultBetMonthLimit
    cashFromDeposit
    bonusBeforeBet
    bonusAfterBet
    updatedAt
  }
`;
export const MeFieldsFragmentDoc = gql`
  fragment MeFields on User {
    cognitoUUID
    id
    nickname
    activationCode
    isCodeVerified
    profileImageUrls {
      large
    }
    properties {
      firstName
      lastName
      email
      dateOfBirth
      postalCode
      phoneNumber
      addressLine1
      addressLine2
      city
    }
    friendReferralCode
    postInteractionLevel
    isPaused
    country
    referralCode
    subscribeEmail
    wallet {
      ...WalletFields
    }
    createdAt
    agreedTermsAndConditions
  }
  ${WalletFieldsFragmentDoc}
`;
export const MyVerificationStatusFieldsFragmentDoc = gql`
  fragment MyVerificationStatusFields on UserIdVerificationStatus {
    id
    status
    statusFirstStep
    statusSecondStep
    reportStatus {
      document
      facial_similarity_motion
      facial_similarity_video
      identity_enhanced
      proof_of_address
      watch_list
    }
    submittedAt
    completedAt
    createdAt
    updatedAt
  }
`;
export const MyVerificationStatusV2FieldsFragmentDoc = gql`
  fragment MyVerificationStatusV2Fields on UserIdVerificationStatusV2 {
    id
    reportStatus {
      document
      face
      address
      aml
    }
    status
    submittedAt
    completedAt
    createdAt
    updatedAt
  }
`;
export const WalletHistoryFieldsFragmentDoc = gql`
  fragment WalletHistoryFields on WalletHistory {
    id
    amount
    transactionId
    transactionType
    isShown
    createdAt
    transactionText
    text
  }
`;
export const MatchWalletHistoryFieldsFragmentDoc = gql`
  fragment MatchWalletHistoryFields on MatchWalletHistory {
    id
    amount
    bet {
      totalBonusReturns
      totalBonusBets
      bonusProfit
      totalCashReturns
      totalCashBets
      cashProfit
    }
    transactionId
    transactionType
    isShown
    createdAt
    transactionText
    text
    match {
      id
      competitors {
        id
        avatarImageUrl
        name {
          en_GB
        }
      }
    }
  }
`;
export const AcceptTermAndConditionDocument = gql`
  mutation AcceptTermAndCondition($input: AcceptTermAndConditionInput!) {
    acceptTermAndCondition(input: $input) {
      accepted
    }
  }
`;
export type AcceptTermAndConditionMutationFn = Apollo.MutationFunction<
  AcceptTermAndConditionMutation,
  AcceptTermAndConditionMutationVariables
>;

/**
 * __useAcceptTermAndConditionMutation__
 *
 * To run a mutation, you first call `useAcceptTermAndConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermAndConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermAndConditionMutation, { data, loading, error }] = useAcceptTermAndConditionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptTermAndConditionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptTermAndConditionMutation,
    AcceptTermAndConditionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptTermAndConditionMutation,
    AcceptTermAndConditionMutationVariables
  >(AcceptTermAndConditionDocument, options);
}
export type AcceptTermAndConditionMutationHookResult = ReturnType<
  typeof useAcceptTermAndConditionMutation
>;
export type AcceptTermAndConditionMutationResult =
  Apollo.MutationResult<AcceptTermAndConditionMutation>;
export type AcceptTermAndConditionMutationOptions = Apollo.BaseMutationOptions<
  AcceptTermAndConditionMutation,
  AcceptTermAndConditionMutationVariables
>;
export const SubmitActivationCodeDocument = gql`
  mutation SubmitActivationCode($input: SubmitActivationCodeInput!) {
    submitActivationCode(input: $input) {
      isMatch
    }
  }
`;
export type SubmitActivationCodeMutationFn = Apollo.MutationFunction<
  SubmitActivationCodeMutation,
  SubmitActivationCodeMutationVariables
>;

/**
 * __useSubmitActivationCodeMutation__
 *
 * To run a mutation, you first call `useSubmitActivationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitActivationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitActivationCodeMutation, { data, loading, error }] = useSubmitActivationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitActivationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitActivationCodeMutation,
    SubmitActivationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitActivationCodeMutation,
    SubmitActivationCodeMutationVariables
  >(SubmitActivationCodeDocument, options);
}
export type SubmitActivationCodeMutationHookResult = ReturnType<
  typeof useSubmitActivationCodeMutation
>;
export type SubmitActivationCodeMutationResult =
  Apollo.MutationResult<SubmitActivationCodeMutation>;
export type SubmitActivationCodeMutationOptions = Apollo.BaseMutationOptions<
  SubmitActivationCodeMutation,
  SubmitActivationCodeMutationVariables
>;
export const RequestActivationCodeDocument = gql`
  mutation RequestActivationCode {
    requestActivationCode {
      waitingList {
        id
        intercomContactId
      }
    }
  }
`;
export type RequestActivationCodeMutationFn = Apollo.MutationFunction<
  RequestActivationCodeMutation,
  RequestActivationCodeMutationVariables
>;

/**
 * __useRequestActivationCodeMutation__
 *
 * To run a mutation, you first call `useRequestActivationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestActivationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestActivationCodeMutation, { data, loading, error }] = useRequestActivationCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestActivationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestActivationCodeMutation,
    RequestActivationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestActivationCodeMutation,
    RequestActivationCodeMutationVariables
  >(RequestActivationCodeDocument, options);
}
export type RequestActivationCodeMutationHookResult = ReturnType<
  typeof useRequestActivationCodeMutation
>;
export type RequestActivationCodeMutationResult =
  Apollo.MutationResult<RequestActivationCodeMutation>;
export type RequestActivationCodeMutationOptions = Apollo.BaseMutationOptions<
  RequestActivationCodeMutation,
  RequestActivationCodeMutationVariables
>;
export const SendUserActivityDocument = gql`
  mutation SendUserActivity($input: SendUserActivityInput!) {
    sendUserActivity(input: $input)
  }
`;
export type SendUserActivityMutationFn = Apollo.MutationFunction<
  SendUserActivityMutation,
  SendUserActivityMutationVariables
>;

/**
 * __useSendUserActivityMutation__
 *
 * To run a mutation, you first call `useSendUserActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserActivityMutation, { data, loading, error }] = useSendUserActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendUserActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendUserActivityMutation,
    SendUserActivityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendUserActivityMutation,
    SendUserActivityMutationVariables
  >(SendUserActivityDocument, options);
}
export type SendUserActivityMutationHookResult = ReturnType<
  typeof useSendUserActivityMutation
>;
export type SendUserActivityMutationResult =
  Apollo.MutationResult<SendUserActivityMutation>;
export type SendUserActivityMutationOptions = Apollo.BaseMutationOptions<
  SendUserActivityMutation,
  SendUserActivityMutationVariables
>;
export const BetDocument = gql`
  mutation Bet($input: BetInput!) {
    bet(input: $input) {
      bet {
        ...BetHistoryFields
      }
      wallet {
        ...WalletFields
      }
    }
  }
  ${BetHistoryFieldsFragmentDoc}
  ${WalletFieldsFragmentDoc}
`;
export type BetMutationFn = Apollo.MutationFunction<
  BetMutation,
  BetMutationVariables
>;

/**
 * __useBetMutation__
 *
 * To run a mutation, you first call `useBetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [betMutation, { data, loading, error }] = useBetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBetMutation(
  baseOptions?: Apollo.MutationHookOptions<BetMutation, BetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BetMutation, BetMutationVariables>(
    BetDocument,
    options,
  );
}
export type BetMutationHookResult = ReturnType<typeof useBetMutation>;
export type BetMutationResult = Apollo.MutationResult<BetMutation>;
export type BetMutationOptions = Apollo.BaseMutationOptions<
  BetMutation,
  BetMutationVariables
>;
export const BetV2Document = gql`
  mutation BetV2($input: BetV2Input!) {
    betV2(input: $input) {
      bet {
        ...BetHistoryFields
      }
      wallet {
        ...WalletFields
      }
    }
  }
  ${BetHistoryFieldsFragmentDoc}
  ${WalletFieldsFragmentDoc}
`;
export type BetV2MutationFn = Apollo.MutationFunction<
  BetV2Mutation,
  BetV2MutationVariables
>;

/**
 * __useBetV2Mutation__
 *
 * To run a mutation, you first call `useBetV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBetV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [betV2Mutation, { data, loading, error }] = useBetV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBetV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    BetV2Mutation,
    BetV2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BetV2Mutation, BetV2MutationVariables>(
    BetV2Document,
    options,
  );
}
export type BetV2MutationHookResult = ReturnType<typeof useBetV2Mutation>;
export type BetV2MutationResult = Apollo.MutationResult<BetV2Mutation>;
export type BetV2MutationOptions = Apollo.BaseMutationOptions<
  BetV2Mutation,
  BetV2MutationVariables
>;
export const ConfirmMyBetResultDocument = gql`
  mutation ConfirmMyBetResult($input: ConfirmMyBetResultInput!) {
    confirmMyBetResult(input: $input)
  }
`;
export type ConfirmMyBetResultMutationFn = Apollo.MutationFunction<
  ConfirmMyBetResultMutation,
  ConfirmMyBetResultMutationVariables
>;

/**
 * __useConfirmMyBetResultMutation__
 *
 * To run a mutation, you first call `useConfirmMyBetResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMyBetResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMyBetResultMutation, { data, loading, error }] = useConfirmMyBetResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmMyBetResultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmMyBetResultMutation,
    ConfirmMyBetResultMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmMyBetResultMutation,
    ConfirmMyBetResultMutationVariables
  >(ConfirmMyBetResultDocument, options);
}
export type ConfirmMyBetResultMutationHookResult = ReturnType<
  typeof useConfirmMyBetResultMutation
>;
export type ConfirmMyBetResultMutationResult =
  Apollo.MutationResult<ConfirmMyBetResultMutation>;
export type ConfirmMyBetResultMutationOptions = Apollo.BaseMutationOptions<
  ConfirmMyBetResultMutation,
  ConfirmMyBetResultMutationVariables
>;
export const CreateLiveStreamingContentDocument = gql`
  mutation CreateLiveStreamingContent(
    $input: CreateLiveStreamingContentInput!
  ) {
    createLiveStreamingContent(input: $input) {
      ...StreamingContentFields
    }
  }
  ${StreamingContentFieldsFragmentDoc}
`;
export type CreateLiveStreamingContentMutationFn = Apollo.MutationFunction<
  CreateLiveStreamingContentMutation,
  CreateLiveStreamingContentMutationVariables
>;

/**
 * __useCreateLiveStreamingContentMutation__
 *
 * To run a mutation, you first call `useCreateLiveStreamingContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLiveStreamingContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLiveStreamingContentMutation, { data, loading, error }] = useCreateLiveStreamingContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLiveStreamingContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLiveStreamingContentMutation,
    CreateLiveStreamingContentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLiveStreamingContentMutation,
    CreateLiveStreamingContentMutationVariables
  >(CreateLiveStreamingContentDocument, options);
}
export type CreateLiveStreamingContentMutationHookResult = ReturnType<
  typeof useCreateLiveStreamingContentMutation
>;
export type CreateLiveStreamingContentMutationResult =
  Apollo.MutationResult<CreateLiveStreamingContentMutation>;
export type CreateLiveStreamingContentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateLiveStreamingContentMutation,
    CreateLiveStreamingContentMutationVariables
  >;
export const StartLiveStreamingContentDocument = gql`
  mutation StartLiveStreamingContent($input: StartLiveStreamingContentInput!) {
    startLiveStreamingContentV2(input: $input) {
      agora {
        ...AgoraChannelInformationFields
      }
    }
  }
  ${AgoraChannelInformationFieldsFragmentDoc}
`;
export type StartLiveStreamingContentMutationFn = Apollo.MutationFunction<
  StartLiveStreamingContentMutation,
  StartLiveStreamingContentMutationVariables
>;

/**
 * __useStartLiveStreamingContentMutation__
 *
 * To run a mutation, you first call `useStartLiveStreamingContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartLiveStreamingContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startLiveStreamingContentMutation, { data, loading, error }] = useStartLiveStreamingContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartLiveStreamingContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartLiveStreamingContentMutation,
    StartLiveStreamingContentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartLiveStreamingContentMutation,
    StartLiveStreamingContentMutationVariables
  >(StartLiveStreamingContentDocument, options);
}
export type StartLiveStreamingContentMutationHookResult = ReturnType<
  typeof useStartLiveStreamingContentMutation
>;
export type StartLiveStreamingContentMutationResult =
  Apollo.MutationResult<StartLiveStreamingContentMutation>;
export type StartLiveStreamingContentMutationOptions =
  Apollo.BaseMutationOptions<
    StartLiveStreamingContentMutation,
    StartLiveStreamingContentMutationVariables
  >;
export const NotifyLivePublishingIsStartedDocument = gql`
  mutation NotifyLivePublishingIsStarted($contentId: ID!) {
    notifyLivePublishingIsStartedV2(contentId: $contentId) {
      ...StreamingContentFields
    }
  }
  ${StreamingContentFieldsFragmentDoc}
`;
export type NotifyLivePublishingIsStartedMutationFn = Apollo.MutationFunction<
  NotifyLivePublishingIsStartedMutation,
  NotifyLivePublishingIsStartedMutationVariables
>;

/**
 * __useNotifyLivePublishingIsStartedMutation__
 *
 * To run a mutation, you first call `useNotifyLivePublishingIsStartedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyLivePublishingIsStartedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyLivePublishingIsStartedMutation, { data, loading, error }] = useNotifyLivePublishingIsStartedMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useNotifyLivePublishingIsStartedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotifyLivePublishingIsStartedMutation,
    NotifyLivePublishingIsStartedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NotifyLivePublishingIsStartedMutation,
    NotifyLivePublishingIsStartedMutationVariables
  >(NotifyLivePublishingIsStartedDocument, options);
}
export type NotifyLivePublishingIsStartedMutationHookResult = ReturnType<
  typeof useNotifyLivePublishingIsStartedMutation
>;
export type NotifyLivePublishingIsStartedMutationResult =
  Apollo.MutationResult<NotifyLivePublishingIsStartedMutation>;
export type NotifyLivePublishingIsStartedMutationOptions =
  Apollo.BaseMutationOptions<
    NotifyLivePublishingIsStartedMutation,
    NotifyLivePublishingIsStartedMutationVariables
  >;
export const FinishLiveStreamingContentDocument = gql`
  mutation FinishLiveStreamingContent(
    $input: FinishLiveStreamingContentInput!
  ) {
    finishLiveStreamingContent(input: $input) {
      ...StreamingContentFields
    }
  }
  ${StreamingContentFieldsFragmentDoc}
`;
export type FinishLiveStreamingContentMutationFn = Apollo.MutationFunction<
  FinishLiveStreamingContentMutation,
  FinishLiveStreamingContentMutationVariables
>;

/**
 * __useFinishLiveStreamingContentMutation__
 *
 * To run a mutation, you first call `useFinishLiveStreamingContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishLiveStreamingContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishLiveStreamingContentMutation, { data, loading, error }] = useFinishLiveStreamingContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinishLiveStreamingContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinishLiveStreamingContentMutation,
    FinishLiveStreamingContentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FinishLiveStreamingContentMutation,
    FinishLiveStreamingContentMutationVariables
  >(FinishLiveStreamingContentDocument, options);
}
export type FinishLiveStreamingContentMutationHookResult = ReturnType<
  typeof useFinishLiveStreamingContentMutation
>;
export type FinishLiveStreamingContentMutationResult =
  Apollo.MutationResult<FinishLiveStreamingContentMutation>;
export type FinishLiveStreamingContentMutationOptions =
  Apollo.BaseMutationOptions<
    FinishLiveStreamingContentMutation,
    FinishLiveStreamingContentMutationVariables
  >;
export const StartOnfidoVerificationDocument = gql`
  mutation StartOnfidoVerification($input: StartOnfidoVerificationInput!) {
    startOnfidoVerification(input: $input) {
      sdkToken
    }
  }
`;
export type StartOnfidoVerificationMutationFn = Apollo.MutationFunction<
  StartOnfidoVerificationMutation,
  StartOnfidoVerificationMutationVariables
>;

/**
 * __useStartOnfidoVerificationMutation__
 *
 * To run a mutation, you first call `useStartOnfidoVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartOnfidoVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startOnfidoVerificationMutation, { data, loading, error }] = useStartOnfidoVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartOnfidoVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartOnfidoVerificationMutation,
    StartOnfidoVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartOnfidoVerificationMutation,
    StartOnfidoVerificationMutationVariables
  >(StartOnfidoVerificationDocument, options);
}
export type StartOnfidoVerificationMutationHookResult = ReturnType<
  typeof useStartOnfidoVerificationMutation
>;
export type StartOnfidoVerificationMutationResult =
  Apollo.MutationResult<StartOnfidoVerificationMutation>;
export type StartOnfidoVerificationMutationOptions = Apollo.BaseMutationOptions<
  StartOnfidoVerificationMutation,
  StartOnfidoVerificationMutationVariables
>;
export const StartOnfidoVerificationV2Document = gql`
  mutation StartOnfidoVerificationV2($input: StartOnfidoVerificationInput!) {
    startOnfidoVerificationV2(input: $input) {
      sdkToken
    }
  }
`;
export type StartOnfidoVerificationV2MutationFn = Apollo.MutationFunction<
  StartOnfidoVerificationV2Mutation,
  StartOnfidoVerificationV2MutationVariables
>;

/**
 * __useStartOnfidoVerificationV2Mutation__
 *
 * To run a mutation, you first call `useStartOnfidoVerificationV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartOnfidoVerificationV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startOnfidoVerificationV2Mutation, { data, loading, error }] = useStartOnfidoVerificationV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartOnfidoVerificationV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartOnfidoVerificationV2Mutation,
    StartOnfidoVerificationV2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartOnfidoVerificationV2Mutation,
    StartOnfidoVerificationV2MutationVariables
  >(StartOnfidoVerificationV2Document, options);
}
export type StartOnfidoVerificationV2MutationHookResult = ReturnType<
  typeof useStartOnfidoVerificationV2Mutation
>;
export type StartOnfidoVerificationV2MutationResult =
  Apollo.MutationResult<StartOnfidoVerificationV2Mutation>;
export type StartOnfidoVerificationV2MutationOptions =
  Apollo.BaseMutationOptions<
    StartOnfidoVerificationV2Mutation,
    StartOnfidoVerificationV2MutationVariables
  >;
export const CreateOnfidoCheckDocument = gql`
  mutation CreateOnfidoCheck($input: CreateCheckOnfidoInput) {
    createOnfidoCheck(input: $input)
  }
`;
export type CreateOnfidoCheckMutationFn = Apollo.MutationFunction<
  CreateOnfidoCheckMutation,
  CreateOnfidoCheckMutationVariables
>;

/**
 * __useCreateOnfidoCheckMutation__
 *
 * To run a mutation, you first call `useCreateOnfidoCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnfidoCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnfidoCheckMutation, { data, loading, error }] = useCreateOnfidoCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOnfidoCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOnfidoCheckMutation,
    CreateOnfidoCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOnfidoCheckMutation,
    CreateOnfidoCheckMutationVariables
  >(CreateOnfidoCheckDocument, options);
}
export type CreateOnfidoCheckMutationHookResult = ReturnType<
  typeof useCreateOnfidoCheckMutation
>;
export type CreateOnfidoCheckMutationResult =
  Apollo.MutationResult<CreateOnfidoCheckMutation>;
export type CreateOnfidoCheckMutationOptions = Apollo.BaseMutationOptions<
  CreateOnfidoCheckMutation,
  CreateOnfidoCheckMutationVariables
>;
export const CreateOnfidoCheckV2Document = gql`
  mutation CreateOnfidoCheckV2($input: CreateCheckOnfidoV2Input) {
    createOnfidoCheckV2(input: $input)
  }
`;
export type CreateOnfidoCheckV2MutationFn = Apollo.MutationFunction<
  CreateOnfidoCheckV2Mutation,
  CreateOnfidoCheckV2MutationVariables
>;

/**
 * __useCreateOnfidoCheckV2Mutation__
 *
 * To run a mutation, you first call `useCreateOnfidoCheckV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnfidoCheckV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnfidoCheckV2Mutation, { data, loading, error }] = useCreateOnfidoCheckV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOnfidoCheckV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOnfidoCheckV2Mutation,
    CreateOnfidoCheckV2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOnfidoCheckV2Mutation,
    CreateOnfidoCheckV2MutationVariables
  >(CreateOnfidoCheckV2Document, options);
}
export type CreateOnfidoCheckV2MutationHookResult = ReturnType<
  typeof useCreateOnfidoCheckV2Mutation
>;
export type CreateOnfidoCheckV2MutationResult =
  Apollo.MutationResult<CreateOnfidoCheckV2Mutation>;
export type CreateOnfidoCheckV2MutationOptions = Apollo.BaseMutationOptions<
  CreateOnfidoCheckV2Mutation,
  CreateOnfidoCheckV2MutationVariables
>;
export const CompleteOnfidoCheckDocument = gql`
  mutation CompleteOnfidoCheck($userId: ID!) {
    completeOnfidoCheck(userId: $userId) {
      ...MyVerificationStatusFields
    }
  }
  ${MyVerificationStatusFieldsFragmentDoc}
`;
export type CompleteOnfidoCheckMutationFn = Apollo.MutationFunction<
  CompleteOnfidoCheckMutation,
  CompleteOnfidoCheckMutationVariables
>;

/**
 * __useCompleteOnfidoCheckMutation__
 *
 * To run a mutation, you first call `useCompleteOnfidoCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOnfidoCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOnfidoCheckMutation, { data, loading, error }] = useCompleteOnfidoCheckMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCompleteOnfidoCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteOnfidoCheckMutation,
    CompleteOnfidoCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteOnfidoCheckMutation,
    CompleteOnfidoCheckMutationVariables
  >(CompleteOnfidoCheckDocument, options);
}
export type CompleteOnfidoCheckMutationHookResult = ReturnType<
  typeof useCompleteOnfidoCheckMutation
>;
export type CompleteOnfidoCheckMutationResult =
  Apollo.MutationResult<CompleteOnfidoCheckMutation>;
export type CompleteOnfidoCheckMutationOptions = Apollo.BaseMutationOptions<
  CompleteOnfidoCheckMutation,
  CompleteOnfidoCheckMutationVariables
>;
export const IntercomUpdateUserDocument = gql`
  mutation IntercomUpdateUser($input: IntercomUpdateUserInput!) {
    intercomUpdateUser(input: $input) {
      success
    }
  }
`;
export type IntercomUpdateUserMutationFn = Apollo.MutationFunction<
  IntercomUpdateUserMutation,
  IntercomUpdateUserMutationVariables
>;

/**
 * __useIntercomUpdateUserMutation__
 *
 * To run a mutation, you first call `useIntercomUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntercomUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intercomUpdateUserMutation, { data, loading, error }] = useIntercomUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntercomUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntercomUpdateUserMutation,
    IntercomUpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntercomUpdateUserMutation,
    IntercomUpdateUserMutationVariables
  >(IntercomUpdateUserDocument, options);
}
export type IntercomUpdateUserMutationHookResult = ReturnType<
  typeof useIntercomUpdateUserMutation
>;
export type IntercomUpdateUserMutationResult =
  Apollo.MutationResult<IntercomUpdateUserMutation>;
export type IntercomUpdateUserMutationOptions = Apollo.BaseMutationOptions<
  IntercomUpdateUserMutation,
  IntercomUpdateUserMutationVariables
>;
export const JoinLiveStreamingChannelCustomerDocument = gql`
  mutation JoinLiveStreamingChannelCustomer($contentId: ID!) {
    joinLiveStreamingChannelCustomer(contentId: $contentId) {
      agora {
        ...AgoraChannelInformationFields
      }
      channelJoinDetail {
        ...ChannelJoinDetailFields
      }
    }
  }
  ${AgoraChannelInformationFieldsFragmentDoc}
  ${ChannelJoinDetailFieldsFragmentDoc}
`;
export type JoinLiveStreamingChannelCustomerMutationFn =
  Apollo.MutationFunction<
    JoinLiveStreamingChannelCustomerMutation,
    JoinLiveStreamingChannelCustomerMutationVariables
  >;

/**
 * __useJoinLiveStreamingChannelCustomerMutation__
 *
 * To run a mutation, you first call `useJoinLiveStreamingChannelCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinLiveStreamingChannelCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinLiveStreamingChannelCustomerMutation, { data, loading, error }] = useJoinLiveStreamingChannelCustomerMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useJoinLiveStreamingChannelCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinLiveStreamingChannelCustomerMutation,
    JoinLiveStreamingChannelCustomerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinLiveStreamingChannelCustomerMutation,
    JoinLiveStreamingChannelCustomerMutationVariables
  >(JoinLiveStreamingChannelCustomerDocument, options);
}
export type JoinLiveStreamingChannelCustomerMutationHookResult = ReturnType<
  typeof useJoinLiveStreamingChannelCustomerMutation
>;
export type JoinLiveStreamingChannelCustomerMutationResult =
  Apollo.MutationResult<JoinLiveStreamingChannelCustomerMutation>;
export type JoinLiveStreamingChannelCustomerMutationOptions =
  Apollo.BaseMutationOptions<
    JoinLiveStreamingChannelCustomerMutation,
    JoinLiveStreamingChannelCustomerMutationVariables
  >;
export const SendStreamingContentChatMessageDocument = gql`
  mutation SendStreamingContentChatMessage(
    $matchId: ID!
    $contentId: ID
    $message: String!
  ) {
    sendStreamingContentChatMessage(
      contentId: $contentId
      matchId: $matchId
      message: $message
    ) {
      ...StreamingContentChatMessageFields
    }
  }
  ${StreamingContentChatMessageFieldsFragmentDoc}
`;
export type SendStreamingContentChatMessageMutationFn = Apollo.MutationFunction<
  SendStreamingContentChatMessageMutation,
  SendStreamingContentChatMessageMutationVariables
>;

/**
 * __useSendStreamingContentChatMessageMutation__
 *
 * To run a mutation, you first call `useSendStreamingContentChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendStreamingContentChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendStreamingContentChatMessageMutation, { data, loading, error }] = useSendStreamingContentChatMessageMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      contentId: // value for 'contentId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendStreamingContentChatMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendStreamingContentChatMessageMutation,
    SendStreamingContentChatMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendStreamingContentChatMessageMutation,
    SendStreamingContentChatMessageMutationVariables
  >(SendStreamingContentChatMessageDocument, options);
}
export type SendStreamingContentChatMessageMutationHookResult = ReturnType<
  typeof useSendStreamingContentChatMessageMutation
>;
export type SendStreamingContentChatMessageMutationResult =
  Apollo.MutationResult<SendStreamingContentChatMessageMutation>;
export type SendStreamingContentChatMessageMutationOptions =
  Apollo.BaseMutationOptions<
    SendStreamingContentChatMessageMutation,
    SendStreamingContentChatMessageMutationVariables
  >;
export const BecomeLiveStreamingAudienceDocument = gql`
  mutation BecomeLiveStreamingAudience($contentId: ID!) {
    becomeAudience(contentId: $contentId) {
      agora {
        ...AgoraChannelInformationFields
      }
      channelJoinDetail {
        ...ChannelJoinDetailFields
      }
    }
  }
  ${AgoraChannelInformationFieldsFragmentDoc}
  ${ChannelJoinDetailFieldsFragmentDoc}
`;
export type BecomeLiveStreamingAudienceMutationFn = Apollo.MutationFunction<
  BecomeLiveStreamingAudienceMutation,
  BecomeLiveStreamingAudienceMutationVariables
>;

/**
 * __useBecomeLiveStreamingAudienceMutation__
 *
 * To run a mutation, you first call `useBecomeLiveStreamingAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBecomeLiveStreamingAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [becomeLiveStreamingAudienceMutation, { data, loading, error }] = useBecomeLiveStreamingAudienceMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useBecomeLiveStreamingAudienceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BecomeLiveStreamingAudienceMutation,
    BecomeLiveStreamingAudienceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BecomeLiveStreamingAudienceMutation,
    BecomeLiveStreamingAudienceMutationVariables
  >(BecomeLiveStreamingAudienceDocument, options);
}
export type BecomeLiveStreamingAudienceMutationHookResult = ReturnType<
  typeof useBecomeLiveStreamingAudienceMutation
>;
export type BecomeLiveStreamingAudienceMutationResult =
  Apollo.MutationResult<BecomeLiveStreamingAudienceMutation>;
export type BecomeLiveStreamingAudienceMutationOptions =
  Apollo.BaseMutationOptions<
    BecomeLiveStreamingAudienceMutation,
    BecomeLiveStreamingAudienceMutationVariables
  >;
export const LeaveLiveStreamingChannelDocument = gql`
  mutation LeaveLiveStreamingChannel($contentId: ID!) {
    leaveLiveStreamingChannel(contentId: $contentId) {
      agora {
        ...AgoraChannelInformationFields
      }
      channelJoinDetail {
        ...ChannelJoinDetailFields
      }
    }
  }
  ${AgoraChannelInformationFieldsFragmentDoc}
  ${ChannelJoinDetailFieldsFragmentDoc}
`;
export type LeaveLiveStreamingChannelMutationFn = Apollo.MutationFunction<
  LeaveLiveStreamingChannelMutation,
  LeaveLiveStreamingChannelMutationVariables
>;

/**
 * __useLeaveLiveStreamingChannelMutation__
 *
 * To run a mutation, you first call `useLeaveLiveStreamingChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveLiveStreamingChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveLiveStreamingChannelMutation, { data, loading, error }] = useLeaveLiveStreamingChannelMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useLeaveLiveStreamingChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveLiveStreamingChannelMutation,
    LeaveLiveStreamingChannelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeaveLiveStreamingChannelMutation,
    LeaveLiveStreamingChannelMutationVariables
  >(LeaveLiveStreamingChannelDocument, options);
}
export type LeaveLiveStreamingChannelMutationHookResult = ReturnType<
  typeof useLeaveLiveStreamingChannelMutation
>;
export type LeaveLiveStreamingChannelMutationResult =
  Apollo.MutationResult<LeaveLiveStreamingChannelMutation>;
export type LeaveLiveStreamingChannelMutationOptions =
  Apollo.BaseMutationOptions<
    LeaveLiveStreamingChannelMutation,
    LeaveLiveStreamingChannelMutationVariables
  >;
export const UnlockMarketsDocument = gql`
  mutation UnlockMarkets($id: ID!) {
    unlockMarkets(id: $id) {
      id
      gradeId
      matchId
    }
  }
`;
export type UnlockMarketsMutationFn = Apollo.MutationFunction<
  UnlockMarketsMutation,
  UnlockMarketsMutationVariables
>;

/**
 * __useUnlockMarketsMutation__
 *
 * To run a mutation, you first call `useUnlockMarketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockMarketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockMarketsMutation, { data, loading, error }] = useUnlockMarketsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlockMarketsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlockMarketsMutation,
    UnlockMarketsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlockMarketsMutation,
    UnlockMarketsMutationVariables
  >(UnlockMarketsDocument, options);
}
export type UnlockMarketsMutationHookResult = ReturnType<
  typeof useUnlockMarketsMutation
>;
export type UnlockMarketsMutationResult =
  Apollo.MutationResult<UnlockMarketsMutation>;
export type UnlockMarketsMutationOptions = Apollo.BaseMutationOptions<
  UnlockMarketsMutation,
  UnlockMarketsMutationVariables
>;
export const UnlockPastVideoDocument = gql`
  mutation UnlockPastVideo($id: ID!) {
    unlockPastVideo(id: $id) {
      id
      gradeId
      matchId
    }
  }
`;
export type UnlockPastVideoMutationFn = Apollo.MutationFunction<
  UnlockPastVideoMutation,
  UnlockPastVideoMutationVariables
>;

/**
 * __useUnlockPastVideoMutation__
 *
 * To run a mutation, you first call `useUnlockPastVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockPastVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockPastVideoMutation, { data, loading, error }] = useUnlockPastVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlockPastVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlockPastVideoMutation,
    UnlockPastVideoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlockPastVideoMutation,
    UnlockPastVideoMutationVariables
  >(UnlockPastVideoDocument, options);
}
export type UnlockPastVideoMutationHookResult = ReturnType<
  typeof useUnlockPastVideoMutation
>;
export type UnlockPastVideoMutationResult =
  Apollo.MutationResult<UnlockPastVideoMutation>;
export type UnlockPastVideoMutationOptions = Apollo.BaseMutationOptions<
  UnlockPastVideoMutation,
  UnlockPastVideoMutationVariables
>;
export const UnlockMatchGroupPlayerStatDocument = gql`
  mutation UnlockMatchGroupPlayerStat($id: ID!) {
    unlockMatchGroupPlayerStat(id: $id) {
      id
      groupPlayerStatId
      matchId
    }
  }
`;
export type UnlockMatchGroupPlayerStatMutationFn = Apollo.MutationFunction<
  UnlockMatchGroupPlayerStatMutation,
  UnlockMatchGroupPlayerStatMutationVariables
>;

/**
 * __useUnlockMatchGroupPlayerStatMutation__
 *
 * To run a mutation, you first call `useUnlockMatchGroupPlayerStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockMatchGroupPlayerStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockMatchGroupPlayerStatMutation, { data, loading, error }] = useUnlockMatchGroupPlayerStatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlockMatchGroupPlayerStatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlockMatchGroupPlayerStatMutation,
    UnlockMatchGroupPlayerStatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlockMatchGroupPlayerStatMutation,
    UnlockMatchGroupPlayerStatMutationVariables
  >(UnlockMatchGroupPlayerStatDocument, options);
}
export type UnlockMatchGroupPlayerStatMutationHookResult = ReturnType<
  typeof useUnlockMatchGroupPlayerStatMutation
>;
export type UnlockMatchGroupPlayerStatMutationResult =
  Apollo.MutationResult<UnlockMatchGroupPlayerStatMutation>;
export type UnlockMatchGroupPlayerStatMutationOptions =
  Apollo.BaseMutationOptions<
    UnlockMatchGroupPlayerStatMutation,
    UnlockMatchGroupPlayerStatMutationVariables
  >;
export const AddUserFirebaseRegistrationTokenDocument = gql`
  mutation AddUserFirebaseRegistrationToken($firebaseRegistrationToken: ID!) {
    addUserFirebaseRegistrationToken(
      firebaseRegistrationToken: $firebaseRegistrationToken
    )
  }
`;
export type AddUserFirebaseRegistrationTokenMutationFn =
  Apollo.MutationFunction<
    AddUserFirebaseRegistrationTokenMutation,
    AddUserFirebaseRegistrationTokenMutationVariables
  >;

/**
 * __useAddUserFirebaseRegistrationTokenMutation__
 *
 * To run a mutation, you first call `useAddUserFirebaseRegistrationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserFirebaseRegistrationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserFirebaseRegistrationTokenMutation, { data, loading, error }] = useAddUserFirebaseRegistrationTokenMutation({
 *   variables: {
 *      firebaseRegistrationToken: // value for 'firebaseRegistrationToken'
 *   },
 * });
 */
export function useAddUserFirebaseRegistrationTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserFirebaseRegistrationTokenMutation,
    AddUserFirebaseRegistrationTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserFirebaseRegistrationTokenMutation,
    AddUserFirebaseRegistrationTokenMutationVariables
  >(AddUserFirebaseRegistrationTokenDocument, options);
}
export type AddUserFirebaseRegistrationTokenMutationHookResult = ReturnType<
  typeof useAddUserFirebaseRegistrationTokenMutation
>;
export type AddUserFirebaseRegistrationTokenMutationResult =
  Apollo.MutationResult<AddUserFirebaseRegistrationTokenMutation>;
export type AddUserFirebaseRegistrationTokenMutationOptions =
  Apollo.BaseMutationOptions<
    AddUserFirebaseRegistrationTokenMutation,
    AddUserFirebaseRegistrationTokenMutationVariables
  >;
export const SaveRealityCheckDocument = gql`
  mutation SaveRealityCheck($input: SaveRealityCheckInput!) {
    saveRealityCheck(input: $input) {
      ...UserSettingsFields
    }
  }
  ${UserSettingsFieldsFragmentDoc}
`;
export type SaveRealityCheckMutationFn = Apollo.MutationFunction<
  SaveRealityCheckMutation,
  SaveRealityCheckMutationVariables
>;

/**
 * __useSaveRealityCheckMutation__
 *
 * To run a mutation, you first call `useSaveRealityCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRealityCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRealityCheckMutation, { data, loading, error }] = useSaveRealityCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveRealityCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveRealityCheckMutation,
    SaveRealityCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveRealityCheckMutation,
    SaveRealityCheckMutationVariables
  >(SaveRealityCheckDocument, options);
}
export type SaveRealityCheckMutationHookResult = ReturnType<
  typeof useSaveRealityCheckMutation
>;
export type SaveRealityCheckMutationResult =
  Apollo.MutationResult<SaveRealityCheckMutation>;
export type SaveRealityCheckMutationOptions = Apollo.BaseMutationOptions<
  SaveRealityCheckMutation,
  SaveRealityCheckMutationVariables
>;
export const SaveWalletHistoryIsShownDocument = gql`
  mutation SaveWalletHistoryIsShown($input: SaveWalletHistoryIsShownInput!) {
    saveWalletHistoryIsShown(input: $input) {
      histories {
        id
        isShown
      }
    }
  }
`;
export type SaveWalletHistoryIsShownMutationFn = Apollo.MutationFunction<
  SaveWalletHistoryIsShownMutation,
  SaveWalletHistoryIsShownMutationVariables
>;

/**
 * __useSaveWalletHistoryIsShownMutation__
 *
 * To run a mutation, you first call `useSaveWalletHistoryIsShownMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWalletHistoryIsShownMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWalletHistoryIsShownMutation, { data, loading, error }] = useSaveWalletHistoryIsShownMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveWalletHistoryIsShownMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveWalletHistoryIsShownMutation,
    SaveWalletHistoryIsShownMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveWalletHistoryIsShownMutation,
    SaveWalletHistoryIsShownMutationVariables
  >(SaveWalletHistoryIsShownDocument, options);
}
export type SaveWalletHistoryIsShownMutationHookResult = ReturnType<
  typeof useSaveWalletHistoryIsShownMutation
>;
export type SaveWalletHistoryIsShownMutationResult =
  Apollo.MutationResult<SaveWalletHistoryIsShownMutation>;
export type SaveWalletHistoryIsShownMutationOptions =
  Apollo.BaseMutationOptions<
    SaveWalletHistoryIsShownMutation,
    SaveWalletHistoryIsShownMutationVariables
  >;
export const SaveLimitationSettingsDocument = gql`
  mutation SaveLimitationSettings($input: SaveLimitationSettingsInput!) {
    saveLimitationSettings(input: $input) {
      ...UserSettingsFields
    }
  }
  ${UserSettingsFieldsFragmentDoc}
`;
export type SaveLimitationSettingsMutationFn = Apollo.MutationFunction<
  SaveLimitationSettingsMutation,
  SaveLimitationSettingsMutationVariables
>;

/**
 * __useSaveLimitationSettingsMutation__
 *
 * To run a mutation, you first call `useSaveLimitationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLimitationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLimitationSettingsMutation, { data, loading, error }] = useSaveLimitationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveLimitationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveLimitationSettingsMutation,
    SaveLimitationSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveLimitationSettingsMutation,
    SaveLimitationSettingsMutationVariables
  >(SaveLimitationSettingsDocument, options);
}
export type SaveLimitationSettingsMutationHookResult = ReturnType<
  typeof useSaveLimitationSettingsMutation
>;
export type SaveLimitationSettingsMutationResult =
  Apollo.MutationResult<SaveLimitationSettingsMutation>;
export type SaveLimitationSettingsMutationOptions = Apollo.BaseMutationOptions<
  SaveLimitationSettingsMutation,
  SaveLimitationSettingsMutationVariables
>;
export const SaveTimeoutDocument = gql`
  mutation SaveTimeout($input: SaveTimeoutInput!) {
    saveTimeout(input: $input) {
      ...UserSettingsFields
    }
  }
  ${UserSettingsFieldsFragmentDoc}
`;
export type SaveTimeoutMutationFn = Apollo.MutationFunction<
  SaveTimeoutMutation,
  SaveTimeoutMutationVariables
>;

/**
 * __useSaveTimeoutMutation__
 *
 * To run a mutation, you first call `useSaveTimeoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTimeoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTimeoutMutation, { data, loading, error }] = useSaveTimeoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTimeoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveTimeoutMutation,
    SaveTimeoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveTimeoutMutation, SaveTimeoutMutationVariables>(
    SaveTimeoutDocument,
    options,
  );
}
export type SaveTimeoutMutationHookResult = ReturnType<
  typeof useSaveTimeoutMutation
>;
export type SaveTimeoutMutationResult =
  Apollo.MutationResult<SaveTimeoutMutation>;
export type SaveTimeoutMutationOptions = Apollo.BaseMutationOptions<
  SaveTimeoutMutation,
  SaveTimeoutMutationVariables
>;
export const SaveSelfExclusionDocument = gql`
  mutation SaveSelfExclusion($input: SaveSelfExclusionInput!) {
    saveSelfExclusion(input: $input) {
      ...UserSettingsFields
    }
  }
  ${UserSettingsFieldsFragmentDoc}
`;
export type SaveSelfExclusionMutationFn = Apollo.MutationFunction<
  SaveSelfExclusionMutation,
  SaveSelfExclusionMutationVariables
>;

/**
 * __useSaveSelfExclusionMutation__
 *
 * To run a mutation, you first call `useSaveSelfExclusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSelfExclusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSelfExclusionMutation, { data, loading, error }] = useSaveSelfExclusionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSelfExclusionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSelfExclusionMutation,
    SaveSelfExclusionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveSelfExclusionMutation,
    SaveSelfExclusionMutationVariables
  >(SaveSelfExclusionDocument, options);
}
export type SaveSelfExclusionMutationHookResult = ReturnType<
  typeof useSaveSelfExclusionMutation
>;
export type SaveSelfExclusionMutationResult =
  Apollo.MutationResult<SaveSelfExclusionMutation>;
export type SaveSelfExclusionMutationOptions = Apollo.BaseMutationOptions<
  SaveSelfExclusionMutation,
  SaveSelfExclusionMutationVariables
>;
export const HandlePendingLimitationValueDocument = gql`
  mutation HandlePendingLimitationValue($input: HandlePendingValueInput!) {
    handlePendingLimitationValue(input: $input) {
      ...UserSettingsFields
    }
  }
  ${UserSettingsFieldsFragmentDoc}
`;
export type HandlePendingLimitationValueMutationFn = Apollo.MutationFunction<
  HandlePendingLimitationValueMutation,
  HandlePendingLimitationValueMutationVariables
>;

/**
 * __useHandlePendingLimitationValueMutation__
 *
 * To run a mutation, you first call `useHandlePendingLimitationValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandlePendingLimitationValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handlePendingLimitationValueMutation, { data, loading, error }] = useHandlePendingLimitationValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHandlePendingLimitationValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HandlePendingLimitationValueMutation,
    HandlePendingLimitationValueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HandlePendingLimitationValueMutation,
    HandlePendingLimitationValueMutationVariables
  >(HandlePendingLimitationValueDocument, options);
}
export type HandlePendingLimitationValueMutationHookResult = ReturnType<
  typeof useHandlePendingLimitationValueMutation
>;
export type HandlePendingLimitationValueMutationResult =
  Apollo.MutationResult<HandlePendingLimitationValueMutation>;
export type HandlePendingLimitationValueMutationOptions =
  Apollo.BaseMutationOptions<
    HandlePendingLimitationValueMutation,
    HandlePendingLimitationValueMutationVariables
  >;
export const CloseAccountDocument = gql`
  mutation CloseAccount($input: CloseAccountInput!) {
    closeAccount(input: $input) {
      cognitoUUID
      createdAt
      id
    }
  }
`;
export type CloseAccountMutationFn = Apollo.MutationFunction<
  CloseAccountMutation,
  CloseAccountMutationVariables
>;

/**
 * __useCloseAccountMutation__
 *
 * To run a mutation, you first call `useCloseAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeAccountMutation, { data, loading, error }] = useCloseAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseAccountMutation,
    CloseAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CloseAccountMutation,
    CloseAccountMutationVariables
  >(CloseAccountDocument, options);
}
export type CloseAccountMutationHookResult = ReturnType<
  typeof useCloseAccountMutation
>;
export type CloseAccountMutationResult =
  Apollo.MutationResult<CloseAccountMutation>;
export type CloseAccountMutationOptions = Apollo.BaseMutationOptions<
  CloseAccountMutation,
  CloseAccountMutationVariables
>;
export const CreateUserWithCognitoUuidDocument = gql`
  mutation CreateUserWithCognitoUUID($input: CreateUserWithCognitoUUIDInput!) {
    createUserWithCognitoUUID(input: $input) {
      ...MeFields
    }
  }
  ${MeFieldsFragmentDoc}
`;
export type CreateUserWithCognitoUuidMutationFn = Apollo.MutationFunction<
  CreateUserWithCognitoUuidMutation,
  CreateUserWithCognitoUuidMutationVariables
>;

/**
 * __useCreateUserWithCognitoUuidMutation__
 *
 * To run a mutation, you first call `useCreateUserWithCognitoUuidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWithCognitoUuidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWithCognitoUuidMutation, { data, loading, error }] = useCreateUserWithCognitoUuidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserWithCognitoUuidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserWithCognitoUuidMutation,
    CreateUserWithCognitoUuidMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserWithCognitoUuidMutation,
    CreateUserWithCognitoUuidMutationVariables
  >(CreateUserWithCognitoUuidDocument, options);
}
export type CreateUserWithCognitoUuidMutationHookResult = ReturnType<
  typeof useCreateUserWithCognitoUuidMutation
>;
export type CreateUserWithCognitoUuidMutationResult =
  Apollo.MutationResult<CreateUserWithCognitoUuidMutation>;
export type CreateUserWithCognitoUuidMutationOptions =
  Apollo.BaseMutationOptions<
    CreateUserWithCognitoUuidMutation,
    CreateUserWithCognitoUuidMutationVariables
  >;
export const UpdateUserPropertiesDocument = gql`
  mutation UpdateUserProperties($input: UpdateUserPropertiesInput!) {
    updateUserProperties(input: $input) {
      ...MeFields
    }
  }
  ${MeFieldsFragmentDoc}
`;
export type UpdateUserPropertiesMutationFn = Apollo.MutationFunction<
  UpdateUserPropertiesMutation,
  UpdateUserPropertiesMutationVariables
>;

/**
 * __useUpdateUserPropertiesMutation__
 *
 * To run a mutation, you first call `useUpdateUserPropertiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPropertiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPropertiesMutation, { data, loading, error }] = useUpdateUserPropertiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPropertiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPropertiesMutation,
    UpdateUserPropertiesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserPropertiesMutation,
    UpdateUserPropertiesMutationVariables
  >(UpdateUserPropertiesDocument, options);
}
export type UpdateUserPropertiesMutationHookResult = ReturnType<
  typeof useUpdateUserPropertiesMutation
>;
export type UpdateUserPropertiesMutationResult =
  Apollo.MutationResult<UpdateUserPropertiesMutation>;
export type UpdateUserPropertiesMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPropertiesMutation,
  UpdateUserPropertiesMutationVariables
>;
export const FaceIdAuthAcceptTermAndConditionDocument = gql`
  mutation FaceIdAuthAcceptTermAndCondition {
    faceIdAuthAcceptTermAndCondition {
      success
    }
  }
`;
export type FaceIdAuthAcceptTermAndConditionMutationFn =
  Apollo.MutationFunction<
    FaceIdAuthAcceptTermAndConditionMutation,
    FaceIdAuthAcceptTermAndConditionMutationVariables
  >;

/**
 * __useFaceIdAuthAcceptTermAndConditionMutation__
 *
 * To run a mutation, you first call `useFaceIdAuthAcceptTermAndConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFaceIdAuthAcceptTermAndConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [faceIdAuthAcceptTermAndConditionMutation, { data, loading, error }] = useFaceIdAuthAcceptTermAndConditionMutation({
 *   variables: {
 *   },
 * });
 */
export function useFaceIdAuthAcceptTermAndConditionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FaceIdAuthAcceptTermAndConditionMutation,
    FaceIdAuthAcceptTermAndConditionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FaceIdAuthAcceptTermAndConditionMutation,
    FaceIdAuthAcceptTermAndConditionMutationVariables
  >(FaceIdAuthAcceptTermAndConditionDocument, options);
}
export type FaceIdAuthAcceptTermAndConditionMutationHookResult = ReturnType<
  typeof useFaceIdAuthAcceptTermAndConditionMutation
>;
export type FaceIdAuthAcceptTermAndConditionMutationResult =
  Apollo.MutationResult<FaceIdAuthAcceptTermAndConditionMutation>;
export type FaceIdAuthAcceptTermAndConditionMutationOptions =
  Apollo.BaseMutationOptions<
    FaceIdAuthAcceptTermAndConditionMutation,
    FaceIdAuthAcceptTermAndConditionMutationVariables
  >;
export const CompleteUserSignInBonusDocument = gql`
  mutation CompleteUserSignInBonus {
    completeUserSignInBonus {
      success
    }
  }
`;
export type CompleteUserSignInBonusMutationFn = Apollo.MutationFunction<
  CompleteUserSignInBonusMutation,
  CompleteUserSignInBonusMutationVariables
>;

/**
 * __useCompleteUserSignInBonusMutation__
 *
 * To run a mutation, you first call `useCompleteUserSignInBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteUserSignInBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeUserSignInBonusMutation, { data, loading, error }] = useCompleteUserSignInBonusMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompleteUserSignInBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteUserSignInBonusMutation,
    CompleteUserSignInBonusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteUserSignInBonusMutation,
    CompleteUserSignInBonusMutationVariables
  >(CompleteUserSignInBonusDocument, options);
}
export type CompleteUserSignInBonusMutationHookResult = ReturnType<
  typeof useCompleteUserSignInBonusMutation
>;
export type CompleteUserSignInBonusMutationResult =
  Apollo.MutationResult<CompleteUserSignInBonusMutation>;
export type CompleteUserSignInBonusMutationOptions = Apollo.BaseMutationOptions<
  CompleteUserSignInBonusMutation,
  CompleteUserSignInBonusMutationVariables
>;
export const DepositDocument = gql`
  mutation Deposit($input: DepositInput!) {
    deposit(input: $input) {
      ...WalletFields
    }
  }
  ${WalletFieldsFragmentDoc}
`;
export type DepositMutationFn = Apollo.MutationFunction<
  DepositMutation,
  DepositMutationVariables
>;

/**
 * __useDepositMutation__
 *
 * To run a mutation, you first call `useDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [depositMutation, { data, loading, error }] = useDepositMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDepositMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DepositMutation,
    DepositMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DepositMutation, DepositMutationVariables>(
    DepositDocument,
    options,
  );
}
export type DepositMutationHookResult = ReturnType<typeof useDepositMutation>;
export type DepositMutationResult = Apollo.MutationResult<DepositMutation>;
export type DepositMutationOptions = Apollo.BaseMutationOptions<
  DepositMutation,
  DepositMutationVariables
>;
export const WithdrawDocument = gql`
  mutation Withdraw($input: WithdrawInput!) {
    withdraw(input: $input) {
      ...WalletFields
    }
  }
  ${WalletFieldsFragmentDoc}
`;
export type WithdrawMutationFn = Apollo.MutationFunction<
  WithdrawMutation,
  WithdrawMutationVariables
>;

/**
 * __useWithdrawMutation__
 *
 * To run a mutation, you first call `useWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawMutation, { data, loading, error }] = useWithdrawMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WithdrawMutation,
    WithdrawMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WithdrawMutation, WithdrawMutationVariables>(
    WithdrawDocument,
    options,
  );
}
export type WithdrawMutationHookResult = ReturnType<typeof useWithdrawMutation>;
export type WithdrawMutationResult = Apollo.MutationResult<WithdrawMutation>;
export type WithdrawMutationOptions = Apollo.BaseMutationOptions<
  WithdrawMutation,
  WithdrawMutationVariables
>;
export const EveryMatrixInitDepositDocument = gql`
  mutation EveryMatrixInitDeposit($input: EveryMatrixInitDepositInput!) {
    everyMatrixInitDeposit(input: $input)
  }
`;
export type EveryMatrixInitDepositMutationFn = Apollo.MutationFunction<
  EveryMatrixInitDepositMutation,
  EveryMatrixInitDepositMutationVariables
>;

/**
 * __useEveryMatrixInitDepositMutation__
 *
 * To run a mutation, you first call `useEveryMatrixInitDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEveryMatrixInitDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [everyMatrixInitDepositMutation, { data, loading, error }] = useEveryMatrixInitDepositMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEveryMatrixInitDepositMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EveryMatrixInitDepositMutation,
    EveryMatrixInitDepositMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EveryMatrixInitDepositMutation,
    EveryMatrixInitDepositMutationVariables
  >(EveryMatrixInitDepositDocument, options);
}
export type EveryMatrixInitDepositMutationHookResult = ReturnType<
  typeof useEveryMatrixInitDepositMutation
>;
export type EveryMatrixInitDepositMutationResult =
  Apollo.MutationResult<EveryMatrixInitDepositMutation>;
export type EveryMatrixInitDepositMutationOptions = Apollo.BaseMutationOptions<
  EveryMatrixInitDepositMutation,
  EveryMatrixInitDepositMutationVariables
>;
export const EveryMatrixInitWithdrawDocument = gql`
  mutation EveryMatrixInitWithdraw($input: EveryMatrixInitWithdrawInput!) {
    everyMatrixInitWithdraw(input: $input)
  }
`;
export type EveryMatrixInitWithdrawMutationFn = Apollo.MutationFunction<
  EveryMatrixInitWithdrawMutation,
  EveryMatrixInitWithdrawMutationVariables
>;

/**
 * __useEveryMatrixInitWithdrawMutation__
 *
 * To run a mutation, you first call `useEveryMatrixInitWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEveryMatrixInitWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [everyMatrixInitWithdrawMutation, { data, loading, error }] = useEveryMatrixInitWithdrawMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEveryMatrixInitWithdrawMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EveryMatrixInitWithdrawMutation,
    EveryMatrixInitWithdrawMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EveryMatrixInitWithdrawMutation,
    EveryMatrixInitWithdrawMutationVariables
  >(EveryMatrixInitWithdrawDocument, options);
}
export type EveryMatrixInitWithdrawMutationHookResult = ReturnType<
  typeof useEveryMatrixInitWithdrawMutation
>;
export type EveryMatrixInitWithdrawMutationResult =
  Apollo.MutationResult<EveryMatrixInitWithdrawMutation>;
export type EveryMatrixInitWithdrawMutationOptions = Apollo.BaseMutationOptions<
  EveryMatrixInitWithdrawMutation,
  EveryMatrixInitWithdrawMutationVariables
>;
export const EveryMatrixApproveWithdrawDocument = gql`
  mutation EveryMatrixApproveWithdraw(
    $input: EveryMatrixProcessWithdrawInput!
  ) {
    everyMatrixApproveWithdraw(input: $input)
  }
`;
export type EveryMatrixApproveWithdrawMutationFn = Apollo.MutationFunction<
  EveryMatrixApproveWithdrawMutation,
  EveryMatrixApproveWithdrawMutationVariables
>;

/**
 * __useEveryMatrixApproveWithdrawMutation__
 *
 * To run a mutation, you first call `useEveryMatrixApproveWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEveryMatrixApproveWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [everyMatrixApproveWithdrawMutation, { data, loading, error }] = useEveryMatrixApproveWithdrawMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEveryMatrixApproveWithdrawMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EveryMatrixApproveWithdrawMutation,
    EveryMatrixApproveWithdrawMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EveryMatrixApproveWithdrawMutation,
    EveryMatrixApproveWithdrawMutationVariables
  >(EveryMatrixApproveWithdrawDocument, options);
}
export type EveryMatrixApproveWithdrawMutationHookResult = ReturnType<
  typeof useEveryMatrixApproveWithdrawMutation
>;
export type EveryMatrixApproveWithdrawMutationResult =
  Apollo.MutationResult<EveryMatrixApproveWithdrawMutation>;
export type EveryMatrixApproveWithdrawMutationOptions =
  Apollo.BaseMutationOptions<
    EveryMatrixApproveWithdrawMutation,
    EveryMatrixApproveWithdrawMutationVariables
  >;
export const EveryMatrixRejectWithdrawDocument = gql`
  mutation EveryMatrixRejectWithdraw($input: EveryMatrixProcessWithdrawInput!) {
    everyMatrixRejectWithdraw(input: $input)
  }
`;
export type EveryMatrixRejectWithdrawMutationFn = Apollo.MutationFunction<
  EveryMatrixRejectWithdrawMutation,
  EveryMatrixRejectWithdrawMutationVariables
>;

/**
 * __useEveryMatrixRejectWithdrawMutation__
 *
 * To run a mutation, you first call `useEveryMatrixRejectWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEveryMatrixRejectWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [everyMatrixRejectWithdrawMutation, { data, loading, error }] = useEveryMatrixRejectWithdrawMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEveryMatrixRejectWithdrawMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EveryMatrixRejectWithdrawMutation,
    EveryMatrixRejectWithdrawMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EveryMatrixRejectWithdrawMutation,
    EveryMatrixRejectWithdrawMutationVariables
  >(EveryMatrixRejectWithdrawDocument, options);
}
export type EveryMatrixRejectWithdrawMutationHookResult = ReturnType<
  typeof useEveryMatrixRejectWithdrawMutation
>;
export type EveryMatrixRejectWithdrawMutationResult =
  Apollo.MutationResult<EveryMatrixRejectWithdrawMutation>;
export type EveryMatrixRejectWithdrawMutationOptions =
  Apollo.BaseMutationOptions<
    EveryMatrixRejectWithdrawMutation,
    EveryMatrixRejectWithdrawMutationVariables
  >;
export const GetCodeActivationEnabledDocument = gql`
  query GetCodeActivationEnabled {
    getCodeActivationEnabled {
      codeActivationEnabled {
        id
        isEnabled
      }
    }
  }
`;

/**
 * __useGetCodeActivationEnabledQuery__
 *
 * To run a query within a React component, call `useGetCodeActivationEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeActivationEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeActivationEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCodeActivationEnabledQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCodeActivationEnabledQuery,
    GetCodeActivationEnabledQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCodeActivationEnabledQuery,
    GetCodeActivationEnabledQueryVariables
  >(GetCodeActivationEnabledDocument, options);
}
export function useGetCodeActivationEnabledLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCodeActivationEnabledQuery,
    GetCodeActivationEnabledQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCodeActivationEnabledQuery,
    GetCodeActivationEnabledQueryVariables
  >(GetCodeActivationEnabledDocument, options);
}
export type GetCodeActivationEnabledQueryHookResult = ReturnType<
  typeof useGetCodeActivationEnabledQuery
>;
export type GetCodeActivationEnabledLazyQueryHookResult = ReturnType<
  typeof useGetCodeActivationEnabledLazyQuery
>;
export type GetCodeActivationEnabledQueryResult = Apollo.QueryResult<
  GetCodeActivationEnabledQuery,
  GetCodeActivationEnabledQueryVariables
>;
export const GetAddressDocument = gql`
  query GetAddress($postalCode: String!) {
    getAddress(postalCode: $postalCode) {
      postcode
      country
      city
      addressLine1
    }
  }
`;

/**
 * __useGetAddressQuery__
 *
 * To run a query within a React component, call `useGetAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressQuery({
 *   variables: {
 *      postalCode: // value for 'postalCode'
 *   },
 * });
 */
export function useGetAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAddressQuery,
    GetAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAddressQuery, GetAddressQueryVariables>(
    GetAddressDocument,
    options,
  );
}
export function useGetAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddressQuery,
    GetAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAddressQuery, GetAddressQueryVariables>(
    GetAddressDocument,
    options,
  );
}
export type GetAddressQueryHookResult = ReturnType<typeof useGetAddressQuery>;
export type GetAddressLazyQueryHookResult = ReturnType<
  typeof useGetAddressLazyQuery
>;
export type GetAddressQueryResult = Apollo.QueryResult<
  GetAddressQuery,
  GetAddressQueryVariables
>;
export const GetForceUpgradeAppVersionConfigDocument = gql`
  query GetForceUpgradeAppVersionConfig {
    getForceUpgradeAppVersionConfig {
      forceUpgradeConfig {
        id
        iosVersion
        androidVersion
      }
    }
  }
`;

/**
 * __useGetForceUpgradeAppVersionConfigQuery__
 *
 * To run a query within a React component, call `useGetForceUpgradeAppVersionConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetForceUpgradeAppVersionConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetForceUpgradeAppVersionConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetForceUpgradeAppVersionConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetForceUpgradeAppVersionConfigQuery,
    GetForceUpgradeAppVersionConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetForceUpgradeAppVersionConfigQuery,
    GetForceUpgradeAppVersionConfigQueryVariables
  >(GetForceUpgradeAppVersionConfigDocument, options);
}
export function useGetForceUpgradeAppVersionConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetForceUpgradeAppVersionConfigQuery,
    GetForceUpgradeAppVersionConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetForceUpgradeAppVersionConfigQuery,
    GetForceUpgradeAppVersionConfigQueryVariables
  >(GetForceUpgradeAppVersionConfigDocument, options);
}
export type GetForceUpgradeAppVersionConfigQueryHookResult = ReturnType<
  typeof useGetForceUpgradeAppVersionConfigQuery
>;
export type GetForceUpgradeAppVersionConfigLazyQueryHookResult = ReturnType<
  typeof useGetForceUpgradeAppVersionConfigLazyQuery
>;
export type GetForceUpgradeAppVersionConfigQueryResult = Apollo.QueryResult<
  GetForceUpgradeAppVersionConfigQuery,
  GetForceUpgradeAppVersionConfigQueryVariables
>;
export const SearchBonusConfigsDocument = gql`
  query SearchBonusConfigs($params: SearchBonusConfigsParams!) {
    searchBonusConfigs(params: $params) {
      configs {
        ...BonusConfigV2Fields
      }
      nextKey
    }
  }
  ${BonusConfigV2FieldsFragmentDoc}
`;

/**
 * __useSearchBonusConfigsQuery__
 *
 * To run a query within a React component, call `useSearchBonusConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBonusConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBonusConfigsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSearchBonusConfigsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchBonusConfigsQuery,
    SearchBonusConfigsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchBonusConfigsQuery,
    SearchBonusConfigsQueryVariables
  >(SearchBonusConfigsDocument, options);
}
export function useSearchBonusConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchBonusConfigsQuery,
    SearchBonusConfigsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchBonusConfigsQuery,
    SearchBonusConfigsQueryVariables
  >(SearchBonusConfigsDocument, options);
}
export type SearchBonusConfigsQueryHookResult = ReturnType<
  typeof useSearchBonusConfigsQuery
>;
export type SearchBonusConfigsLazyQueryHookResult = ReturnType<
  typeof useSearchBonusConfigsLazyQuery
>;
export type SearchBonusConfigsQueryResult = Apollo.QueryResult<
  SearchBonusConfigsQuery,
  SearchBonusConfigsQueryVariables
>;
export const GetCampaignsDocument = gql`
  query GetCampaigns($params: GetCampaignsParams!) {
    getCampaigns(params: $params) {
      campaigns {
        id
        key
        value {
          ...LocalizedStringFields
        }
        createdAt
        updatedAt
      }
    }
  }
  ${LocalizedStringFieldsFragmentDoc}
`;

/**
 * __useGetCampaignsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCampaignsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCampaignsQuery,
    GetCampaignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(
    GetCampaignsDocument,
    options,
  );
}
export function useGetCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCampaignsQuery,
    GetCampaignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(
    GetCampaignsDocument,
    options,
  );
}
export type GetCampaignsQueryHookResult = ReturnType<
  typeof useGetCampaignsQuery
>;
export type GetCampaignsLazyQueryHookResult = ReturnType<
  typeof useGetCampaignsLazyQuery
>;
export type GetCampaignsQueryResult = Apollo.QueryResult<
  GetCampaignsQuery,
  GetCampaignsQueryVariables
>;
export const GetCountryLanguagesDocument = gql`
  query GetCountryLanguages {
    getCountryLanguages {
      countryLanguage
    }
  }
`;

/**
 * __useGetCountryLanguagesQuery__
 *
 * To run a query within a React component, call `useGetCountryLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountryLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCountryLanguagesQuery,
    GetCountryLanguagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountryLanguagesQuery,
    GetCountryLanguagesQueryVariables
  >(GetCountryLanguagesDocument, options);
}
export function useGetCountryLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryLanguagesQuery,
    GetCountryLanguagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryLanguagesQuery,
    GetCountryLanguagesQueryVariables
  >(GetCountryLanguagesDocument, options);
}
export type GetCountryLanguagesQueryHookResult = ReturnType<
  typeof useGetCountryLanguagesQuery
>;
export type GetCountryLanguagesLazyQueryHookResult = ReturnType<
  typeof useGetCountryLanguagesLazyQuery
>;
export type GetCountryLanguagesQueryResult = Apollo.QueryResult<
  GetCountryLanguagesQuery,
  GetCountryLanguagesQueryVariables
>;
export const GetListYoutubeVideosDocument = gql`
  query GetListYoutubeVideos {
    listYoutubeVideos {
      embedUrl
      thumbnails {
        default
        medium
        high
      }
    }
  }
`;

/**
 * __useGetListYoutubeVideosQuery__
 *
 * To run a query within a React component, call `useGetListYoutubeVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListYoutubeVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListYoutubeVideosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetListYoutubeVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetListYoutubeVideosQuery,
    GetListYoutubeVideosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetListYoutubeVideosQuery,
    GetListYoutubeVideosQueryVariables
  >(GetListYoutubeVideosDocument, options);
}
export function useGetListYoutubeVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetListYoutubeVideosQuery,
    GetListYoutubeVideosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetListYoutubeVideosQuery,
    GetListYoutubeVideosQueryVariables
  >(GetListYoutubeVideosDocument, options);
}
export type GetListYoutubeVideosQueryHookResult = ReturnType<
  typeof useGetListYoutubeVideosQuery
>;
export type GetListYoutubeVideosLazyQueryHookResult = ReturnType<
  typeof useGetListYoutubeVideosLazyQuery
>;
export type GetListYoutubeVideosQueryResult = Apollo.QueryResult<
  GetListYoutubeVideosQuery,
  GetListYoutubeVideosQueryVariables
>;
export const ListMarketsByMatchDocument = gql`
  query ListMarketsByMatch($id: ID!, $params: ListMarketsParams!) {
    listMarketsByMatchV2(id: $id, params: $params) {
      ...ListMarketsByMatchOutputFields
    }
  }
  ${ListMarketsByMatchOutputFieldsFragmentDoc}
`;

/**
 * __useListMarketsByMatchQuery__
 *
 * To run a query within a React component, call `useListMarketsByMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMarketsByMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMarketsByMatchQuery({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useListMarketsByMatchQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListMarketsByMatchQuery,
    ListMarketsByMatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListMarketsByMatchQuery,
    ListMarketsByMatchQueryVariables
  >(ListMarketsByMatchDocument, options);
}
export function useListMarketsByMatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMarketsByMatchQuery,
    ListMarketsByMatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListMarketsByMatchQuery,
    ListMarketsByMatchQueryVariables
  >(ListMarketsByMatchDocument, options);
}
export type ListMarketsByMatchQueryHookResult = ReturnType<
  typeof useListMarketsByMatchQuery
>;
export type ListMarketsByMatchLazyQueryHookResult = ReturnType<
  typeof useListMarketsByMatchLazyQuery
>;
export type ListMarketsByMatchQueryResult = Apollo.QueryResult<
  ListMarketsByMatchQuery,
  ListMarketsByMatchQueryVariables
>;
export const ListMarketsByMatchV3Document = gql`
  query ListMarketsByMatchV3($id: ID!, $params: ListMarketsParams!) {
    listMarketsByMatchV3(id: $id, params: $params) {
      ...ListMarketsByMatchOutputFieldsV3
    }
  }
  ${ListMarketsByMatchOutputFieldsV3FragmentDoc}
`;

/**
 * __useListMarketsByMatchV3Query__
 *
 * To run a query within a React component, call `useListMarketsByMatchV3Query` and pass it any options that fit your needs.
 * When your component renders, `useListMarketsByMatchV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMarketsByMatchV3Query({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useListMarketsByMatchV3Query(
  baseOptions: Apollo.QueryHookOptions<
    ListMarketsByMatchV3Query,
    ListMarketsByMatchV3QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListMarketsByMatchV3Query,
    ListMarketsByMatchV3QueryVariables
  >(ListMarketsByMatchV3Document, options);
}
export function useListMarketsByMatchV3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMarketsByMatchV3Query,
    ListMarketsByMatchV3QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListMarketsByMatchV3Query,
    ListMarketsByMatchV3QueryVariables
  >(ListMarketsByMatchV3Document, options);
}
export type ListMarketsByMatchV3QueryHookResult = ReturnType<
  typeof useListMarketsByMatchV3Query
>;
export type ListMarketsByMatchV3LazyQueryHookResult = ReturnType<
  typeof useListMarketsByMatchV3LazyQuery
>;
export type ListMarketsByMatchV3QueryResult = Apollo.QueryResult<
  ListMarketsByMatchV3Query,
  ListMarketsByMatchV3QueryVariables
>;
export const ListMarketResultsByMatchDocument = gql`
  query listMarketResultsByMatch($id: ID!, $settledAtAfter: AWSDateTime) {
    listMarketResultsByMatch(id: $id, settledAtAfter: $settledAtAfter) {
      marketGroups {
        ...InplayMarketGroupFields
      }
    }
  }
  ${InplayMarketGroupFieldsFragmentDoc}
`;

/**
 * __useListMarketResultsByMatchQuery__
 *
 * To run a query within a React component, call `useListMarketResultsByMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMarketResultsByMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMarketResultsByMatchQuery({
 *   variables: {
 *      id: // value for 'id'
 *      settledAtAfter: // value for 'settledAtAfter'
 *   },
 * });
 */
export function useListMarketResultsByMatchQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListMarketResultsByMatchQuery,
    ListMarketResultsByMatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListMarketResultsByMatchQuery,
    ListMarketResultsByMatchQueryVariables
  >(ListMarketResultsByMatchDocument, options);
}
export function useListMarketResultsByMatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMarketResultsByMatchQuery,
    ListMarketResultsByMatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListMarketResultsByMatchQuery,
    ListMarketResultsByMatchQueryVariables
  >(ListMarketResultsByMatchDocument, options);
}
export type ListMarketResultsByMatchQueryHookResult = ReturnType<
  typeof useListMarketResultsByMatchQuery
>;
export type ListMarketResultsByMatchLazyQueryHookResult = ReturnType<
  typeof useListMarketResultsByMatchLazyQuery
>;
export type ListMarketResultsByMatchQueryResult = Apollo.QueryResult<
  ListMarketResultsByMatchQuery,
  ListMarketResultsByMatchQueryVariables
>;
export const ListMyBetsDocument = gql`
  query ListMyBets($matchId: ID!, $marketId: ID) {
    listMyBets(matchId: $matchId, marketId: $marketId) {
      bets {
        ...BetHistoryFields
      }
      totalReturns
      totalBets
      profit
    }
  }
  ${BetHistoryFieldsFragmentDoc}
`;

/**
 * __useListMyBetsQuery__
 *
 * To run a query within a React component, call `useListMyBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyBetsQuery({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useListMyBetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListMyBetsQuery,
    ListMyBetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMyBetsQuery, ListMyBetsQueryVariables>(
    ListMyBetsDocument,
    options,
  );
}
export function useListMyBetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMyBetsQuery,
    ListMyBetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMyBetsQuery, ListMyBetsQueryVariables>(
    ListMyBetsDocument,
    options,
  );
}
export type ListMyBetsQueryHookResult = ReturnType<typeof useListMyBetsQuery>;
export type ListMyBetsLazyQueryHookResult = ReturnType<
  typeof useListMyBetsLazyQuery
>;
export type ListMyBetsQueryResult = Apollo.QueryResult<
  ListMyBetsQuery,
  ListMyBetsQueryVariables
>;
export const ListMyBetsV2Document = gql`
  query listMyBetsV2($matchId: ID!, $marketId: ID) {
    listMyBetsV2(matchId: $matchId, marketId: $marketId) {
      bets {
        ...BetHistoryFields
      }
      totalBonusReturns
      totalBonusBets
      bonusProfit
      totalCashReturns
      totalCashBets
      cashProfit
      totalBetCount
      totalCorrectBetCount
      totalUnconfirmedBetCount
    }
  }
  ${BetHistoryFieldsFragmentDoc}
`;

/**
 * __useListMyBetsV2Query__
 *
 * To run a query within a React component, call `useListMyBetsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useListMyBetsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyBetsV2Query({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useListMyBetsV2Query(
  baseOptions: Apollo.QueryHookOptions<
    ListMyBetsV2Query,
    ListMyBetsV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMyBetsV2Query, ListMyBetsV2QueryVariables>(
    ListMyBetsV2Document,
    options,
  );
}
export function useListMyBetsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMyBetsV2Query,
    ListMyBetsV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMyBetsV2Query, ListMyBetsV2QueryVariables>(
    ListMyBetsV2Document,
    options,
  );
}
export type ListMyBetsV2QueryHookResult = ReturnType<
  typeof useListMyBetsV2Query
>;
export type ListMyBetsV2LazyQueryHookResult = ReturnType<
  typeof useListMyBetsV2LazyQuery
>;
export type ListMyBetsV2QueryResult = Apollo.QueryResult<
  ListMyBetsV2Query,
  ListMyBetsV2QueryVariables
>;
export const GetInplayMarketDocument = gql`
  query GetInplayMarket($id: ID!) {
    getInplayMarket(id: $id) {
      ...InplayMarketFields
    }
  }
  ${InplayMarketFieldsFragmentDoc}
`;

/**
 * __useGetInplayMarketQuery__
 *
 * To run a query within a React component, call `useGetInplayMarketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInplayMarketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInplayMarketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInplayMarketQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInplayMarketQuery,
    GetInplayMarketQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInplayMarketQuery, GetInplayMarketQueryVariables>(
    GetInplayMarketDocument,
    options,
  );
}
export function useGetInplayMarketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInplayMarketQuery,
    GetInplayMarketQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInplayMarketQuery,
    GetInplayMarketQueryVariables
  >(GetInplayMarketDocument, options);
}
export type GetInplayMarketQueryHookResult = ReturnType<
  typeof useGetInplayMarketQuery
>;
export type GetInplayMarketLazyQueryHookResult = ReturnType<
  typeof useGetInplayMarketLazyQuery
>;
export type GetInplayMarketQueryResult = Apollo.QueryResult<
  GetInplayMarketQuery,
  GetInplayMarketQueryVariables
>;
export const GetUnlockMarketsDocument = gql`
  query GetUnlockMarkets($id: ID!) {
    getUnlockMarkets(id: $id) {
      matchId
      isUnlockedMarkets
      marketGemPrice
      isUnlockedPastVideo
      pastVideoGemPrice
      hidePastVideo
    }
  }
`;

/**
 * __useGetUnlockMarketsQuery__
 *
 * To run a query within a React component, call `useGetUnlockMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnlockMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnlockMarketsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnlockMarketsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUnlockMarketsQuery,
    GetUnlockMarketsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnlockMarketsQuery, GetUnlockMarketsQueryVariables>(
    GetUnlockMarketsDocument,
    options,
  );
}
export function useGetUnlockMarketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnlockMarketsQuery,
    GetUnlockMarketsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUnlockMarketsQuery,
    GetUnlockMarketsQueryVariables
  >(GetUnlockMarketsDocument, options);
}
export type GetUnlockMarketsQueryHookResult = ReturnType<
  typeof useGetUnlockMarketsQuery
>;
export type GetUnlockMarketsLazyQueryHookResult = ReturnType<
  typeof useGetUnlockMarketsLazyQuery
>;
export type GetUnlockMarketsQueryResult = Apollo.QueryResult<
  GetUnlockMarketsQuery,
  GetUnlockMarketsQueryVariables
>;
export const SearchMatchesDocument = gql`
  query SearchMatches($params: SearchMatchesParams!) {
    searchMatches(params: $params) {
      matches {
        ...MatchFields
      }
      nextKey
    }
  }
  ${MatchFieldsFragmentDoc}
`;

/**
 * __useSearchMatchesQuery__
 *
 * To run a query within a React component, call `useSearchMatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMatchesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSearchMatchesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchMatchesQuery,
    SearchMatchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchMatchesQuery, SearchMatchesQueryVariables>(
    SearchMatchesDocument,
    options,
  );
}
export function useSearchMatchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchMatchesQuery,
    SearchMatchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchMatchesQuery, SearchMatchesQueryVariables>(
    SearchMatchesDocument,
    options,
  );
}
export type SearchMatchesQueryHookResult = ReturnType<
  typeof useSearchMatchesQuery
>;
export type SearchMatchesLazyQueryHookResult = ReturnType<
  typeof useSearchMatchesLazyQuery
>;
export type SearchMatchesQueryResult = Apollo.QueryResult<
  SearchMatchesQuery,
  SearchMatchesQueryVariables
>;
export const SearchMatchesBySportDocument = gql`
  query SearchMatchesBySport(
    $sportId: ID
    $startKey: String
    $size: Int!
    $country: UserCountryCodeIso1Alpha2!
  ) {
    searchMatches(
      params: {
        sportId: $sportId
        startKey: $startKey
        size: $size
        country: $country
      }
    ) {
      matches {
        ...MatchFields
      }
      nextKey
    }
  }
  ${MatchFieldsFragmentDoc}
`;

/**
 * __useSearchMatchesBySportQuery__
 *
 * To run a query within a React component, call `useSearchMatchesBySportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMatchesBySportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMatchesBySportQuery({
 *   variables: {
 *      sportId: // value for 'sportId'
 *      startKey: // value for 'startKey'
 *      size: // value for 'size'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useSearchMatchesBySportQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchMatchesBySportQuery,
    SearchMatchesBySportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchMatchesBySportQuery,
    SearchMatchesBySportQueryVariables
  >(SearchMatchesBySportDocument, options);
}
export function useSearchMatchesBySportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchMatchesBySportQuery,
    SearchMatchesBySportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchMatchesBySportQuery,
    SearchMatchesBySportQueryVariables
  >(SearchMatchesBySportDocument, options);
}
export type SearchMatchesBySportQueryHookResult = ReturnType<
  typeof useSearchMatchesBySportQuery
>;
export type SearchMatchesBySportLazyQueryHookResult = ReturnType<
  typeof useSearchMatchesBySportLazyQuery
>;
export type SearchMatchesBySportQueryResult = Apollo.QueryResult<
  SearchMatchesBySportQuery,
  SearchMatchesBySportQueryVariables
>;
export const GetMatchDocument = gql`
  query GetMatch($id: ID!) {
    getMatch(id: $id) {
      ...MatchFields
    }
  }
  ${MatchFieldsFragmentDoc}
`;

/**
 * __useGetMatchQuery__
 *
 * To run a query within a React component, call `useGetMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMatchQuery(
  baseOptions: Apollo.QueryHookOptions<GetMatchQuery, GetMatchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMatchQuery, GetMatchQueryVariables>(
    GetMatchDocument,
    options,
  );
}
export function useGetMatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMatchQuery,
    GetMatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMatchQuery, GetMatchQueryVariables>(
    GetMatchDocument,
    options,
  );
}
export type GetMatchQueryHookResult = ReturnType<typeof useGetMatchQuery>;
export type GetMatchLazyQueryHookResult = ReturnType<
  typeof useGetMatchLazyQuery
>;
export type GetMatchQueryResult = Apollo.QueryResult<
  GetMatchQuery,
  GetMatchQueryVariables
>;
export const GetMatchPlayerStatsDocument = gql`
  query GetMatchPlayerStats($id: ID!) {
    getMatchPlayerStats(id: $id) {
      playerStatTable {
        ...MatchPlayerStatsFields
      }
      isUnlocked
      gemPrice
    }
  }
  ${MatchPlayerStatsFieldsFragmentDoc}
`;

/**
 * __useGetMatchPlayerStatsQuery__
 *
 * To run a query within a React component, call `useGetMatchPlayerStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchPlayerStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchPlayerStatsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMatchPlayerStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMatchPlayerStatsQuery,
    GetMatchPlayerStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMatchPlayerStatsQuery,
    GetMatchPlayerStatsQueryVariables
  >(GetMatchPlayerStatsDocument, options);
}
export function useGetMatchPlayerStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMatchPlayerStatsQuery,
    GetMatchPlayerStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMatchPlayerStatsQuery,
    GetMatchPlayerStatsQueryVariables
  >(GetMatchPlayerStatsDocument, options);
}
export type GetMatchPlayerStatsQueryHookResult = ReturnType<
  typeof useGetMatchPlayerStatsQuery
>;
export type GetMatchPlayerStatsLazyQueryHookResult = ReturnType<
  typeof useGetMatchPlayerStatsLazyQuery
>;
export type GetMatchPlayerStatsQueryResult = Apollo.QueryResult<
  GetMatchPlayerStatsQuery,
  GetMatchPlayerStatsQueryVariables
>;
export const GetMatchGeniusSportsMediaDocument = gql`
  query GetMatchGeniusSportsMedia($matchId: ID!) {
    getMatchGeniusSportsMedia(matchId: $matchId) {
      videoStreaming {
        videoStreamingUrl
      }
      tracker {
        liveTrackerUrl
      }
    }
  }
`;

/**
 * __useGetMatchGeniusSportsMediaQuery__
 *
 * To run a query within a React component, call `useGetMatchGeniusSportsMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchGeniusSportsMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchGeniusSportsMediaQuery({
 *   variables: {
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useGetMatchGeniusSportsMediaQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMatchGeniusSportsMediaQuery,
    GetMatchGeniusSportsMediaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMatchGeniusSportsMediaQuery,
    GetMatchGeniusSportsMediaQueryVariables
  >(GetMatchGeniusSportsMediaDocument, options);
}
export function useGetMatchGeniusSportsMediaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMatchGeniusSportsMediaQuery,
    GetMatchGeniusSportsMediaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMatchGeniusSportsMediaQuery,
    GetMatchGeniusSportsMediaQueryVariables
  >(GetMatchGeniusSportsMediaDocument, options);
}
export type GetMatchGeniusSportsMediaQueryHookResult = ReturnType<
  typeof useGetMatchGeniusSportsMediaQuery
>;
export type GetMatchGeniusSportsMediaLazyQueryHookResult = ReturnType<
  typeof useGetMatchGeniusSportsMediaLazyQuery
>;
export type GetMatchGeniusSportsMediaQueryResult = Apollo.QueryResult<
  GetMatchGeniusSportsMediaQuery,
  GetMatchGeniusSportsMediaQueryVariables
>;
export const GetMatchSummaryDocument = gql`
  query GetMatchSummary($matchId: ID!) {
    getMatchSummary(matchId: $matchId) {
      ...MatchSummaryFields
    }
  }
  ${MatchSummaryFieldsFragmentDoc}
`;

/**
 * __useGetMatchSummaryQuery__
 *
 * To run a query within a React component, call `useGetMatchSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchSummaryQuery({
 *   variables: {
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useGetMatchSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMatchSummaryQuery,
    GetMatchSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMatchSummaryQuery, GetMatchSummaryQueryVariables>(
    GetMatchSummaryDocument,
    options,
  );
}
export function useGetMatchSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMatchSummaryQuery,
    GetMatchSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMatchSummaryQuery,
    GetMatchSummaryQueryVariables
  >(GetMatchSummaryDocument, options);
}
export type GetMatchSummaryQueryHookResult = ReturnType<
  typeof useGetMatchSummaryQuery
>;
export type GetMatchSummaryLazyQueryHookResult = ReturnType<
  typeof useGetMatchSummaryLazyQuery
>;
export type GetMatchSummaryQueryResult = Apollo.QueryResult<
  GetMatchSummaryQuery,
  GetMatchSummaryQueryVariables
>;
export const ListMatchGradesDocument = gql`
  query ListMatchGrades($params: ListMatchGradesParams) {
    listMatchGrades(params: $params) {
      ...GradeFields
    }
  }
  ${GradeFieldsFragmentDoc}
`;

/**
 * __useListMatchGradesQuery__
 *
 * To run a query within a React component, call `useListMatchGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMatchGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMatchGradesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useListMatchGradesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListMatchGradesQuery,
    ListMatchGradesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMatchGradesQuery, ListMatchGradesQueryVariables>(
    ListMatchGradesDocument,
    options,
  );
}
export function useListMatchGradesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMatchGradesQuery,
    ListMatchGradesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListMatchGradesQuery,
    ListMatchGradesQueryVariables
  >(ListMatchGradesDocument, options);
}
export type ListMatchGradesQueryHookResult = ReturnType<
  typeof useListMatchGradesQuery
>;
export type ListMatchGradesLazyQueryHookResult = ReturnType<
  typeof useListMatchGradesLazyQuery
>;
export type ListMatchGradesQueryResult = Apollo.QueryResult<
  ListMatchGradesQuery,
  ListMatchGradesQueryVariables
>;
export const ListAllMatchCategoriesDocument = gql`
  query ListAllMatchCategories {
    listAllMatchCategories {
      ...CategoryFields
    }
  }
  ${CategoryFieldsFragmentDoc}
`;

/**
 * __useListAllMatchCategoriesQuery__
 *
 * To run a query within a React component, call `useListAllMatchCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllMatchCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllMatchCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAllMatchCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAllMatchCategoriesQuery,
    ListAllMatchCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListAllMatchCategoriesQuery,
    ListAllMatchCategoriesQueryVariables
  >(ListAllMatchCategoriesDocument, options);
}
export function useListAllMatchCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAllMatchCategoriesQuery,
    ListAllMatchCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListAllMatchCategoriesQuery,
    ListAllMatchCategoriesQueryVariables
  >(ListAllMatchCategoriesDocument, options);
}
export type ListAllMatchCategoriesQueryHookResult = ReturnType<
  typeof useListAllMatchCategoriesQuery
>;
export type ListAllMatchCategoriesLazyQueryHookResult = ReturnType<
  typeof useListAllMatchCategoriesLazyQuery
>;
export type ListAllMatchCategoriesQueryResult = Apollo.QueryResult<
  ListAllMatchCategoriesQuery,
  ListAllMatchCategoriesQueryVariables
>;
export const GetMeDocument = gql`
  query GetMe {
    me {
      ...MeFields
    }
  }
  ${MeFieldsFragmentDoc}
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  );
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  );
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<
  GetMeQuery,
  GetMeQueryVariables
>;
export const GetMeV2Document = gql`
  query GetMeV2 {
    meV2 {
      ...MeFields
    }
  }
  ${MeFieldsFragmentDoc}
`;

/**
 * __useGetMeV2Query__
 *
 * To run a query within a React component, call `useGetMeV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetMeV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeV2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetMeV2Query(
  baseOptions?: Apollo.QueryHookOptions<GetMeV2Query, GetMeV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeV2Query, GetMeV2QueryVariables>(
    GetMeV2Document,
    options,
  );
}
export function useGetMeV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMeV2Query,
    GetMeV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeV2Query, GetMeV2QueryVariables>(
    GetMeV2Document,
    options,
  );
}
export type GetMeV2QueryHookResult = ReturnType<typeof useGetMeV2Query>;
export type GetMeV2LazyQueryHookResult = ReturnType<typeof useGetMeV2LazyQuery>;
export type GetMeV2QueryResult = Apollo.QueryResult<
  GetMeV2Query,
  GetMeV2QueryVariables
>;
export const GetMyVerificationStatusDocument = gql`
  query GetMyVerificationStatus {
    myIdVerificationStatus {
      ...MyVerificationStatusFields
    }
  }
  ${MyVerificationStatusFieldsFragmentDoc}
`;

/**
 * __useGetMyVerificationStatusQuery__
 *
 * To run a query within a React component, call `useGetMyVerificationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyVerificationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyVerificationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyVerificationStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyVerificationStatusQuery,
    GetMyVerificationStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyVerificationStatusQuery,
    GetMyVerificationStatusQueryVariables
  >(GetMyVerificationStatusDocument, options);
}
export function useGetMyVerificationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyVerificationStatusQuery,
    GetMyVerificationStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyVerificationStatusQuery,
    GetMyVerificationStatusQueryVariables
  >(GetMyVerificationStatusDocument, options);
}
export type GetMyVerificationStatusQueryHookResult = ReturnType<
  typeof useGetMyVerificationStatusQuery
>;
export type GetMyVerificationStatusLazyQueryHookResult = ReturnType<
  typeof useGetMyVerificationStatusLazyQuery
>;
export type GetMyVerificationStatusQueryResult = Apollo.QueryResult<
  GetMyVerificationStatusQuery,
  GetMyVerificationStatusQueryVariables
>;
export const GetMyVerificationStatusV2Document = gql`
  query GetMyVerificationStatusV2 {
    myIdVerificationStatusV2 {
      ...MyVerificationStatusV2Fields
    }
  }
  ${MyVerificationStatusV2FieldsFragmentDoc}
`;

/**
 * __useGetMyVerificationStatusV2Query__
 *
 * To run a query within a React component, call `useGetMyVerificationStatusV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetMyVerificationStatusV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyVerificationStatusV2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetMyVerificationStatusV2Query(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyVerificationStatusV2Query,
    GetMyVerificationStatusV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyVerificationStatusV2Query,
    GetMyVerificationStatusV2QueryVariables
  >(GetMyVerificationStatusV2Document, options);
}
export function useGetMyVerificationStatusV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyVerificationStatusV2Query,
    GetMyVerificationStatusV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyVerificationStatusV2Query,
    GetMyVerificationStatusV2QueryVariables
  >(GetMyVerificationStatusV2Document, options);
}
export type GetMyVerificationStatusV2QueryHookResult = ReturnType<
  typeof useGetMyVerificationStatusV2Query
>;
export type GetMyVerificationStatusV2LazyQueryHookResult = ReturnType<
  typeof useGetMyVerificationStatusV2LazyQuery
>;
export type GetMyVerificationStatusV2QueryResult = Apollo.QueryResult<
  GetMyVerificationStatusV2Query,
  GetMyVerificationStatusV2QueryVariables
>;
export const GetVerificationUrlDocument = gql`
  query GetVerificationUrl($params: GetVerificationUrlParams) {
    getVerificationUrl(params: $params)
  }
`;

/**
 * __useGetVerificationUrlQuery__
 *
 * To run a query within a React component, call `useGetVerificationUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVerificationUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVerificationUrlQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetVerificationUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVerificationUrlQuery,
    GetVerificationUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVerificationUrlQuery,
    GetVerificationUrlQueryVariables
  >(GetVerificationUrlDocument, options);
}
export function useGetVerificationUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVerificationUrlQuery,
    GetVerificationUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVerificationUrlQuery,
    GetVerificationUrlQueryVariables
  >(GetVerificationUrlDocument, options);
}
export type GetVerificationUrlQueryHookResult = ReturnType<
  typeof useGetVerificationUrlQuery
>;
export type GetVerificationUrlLazyQueryHookResult = ReturnType<
  typeof useGetVerificationUrlLazyQuery
>;
export type GetVerificationUrlQueryResult = Apollo.QueryResult<
  GetVerificationUrlQuery,
  GetVerificationUrlQueryVariables
>;
export const GetMySettingsDocument = gql`
  query GetMySettings {
    mySettings {
      ...UserSettingsFields
    }
  }
  ${UserSettingsFieldsFragmentDoc}
`;

/**
 * __useGetMySettingsQuery__
 *
 * To run a query within a React component, call `useGetMySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMySettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMySettingsQuery,
    GetMySettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMySettingsQuery, GetMySettingsQueryVariables>(
    GetMySettingsDocument,
    options,
  );
}
export function useGetMySettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMySettingsQuery,
    GetMySettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMySettingsQuery, GetMySettingsQueryVariables>(
    GetMySettingsDocument,
    options,
  );
}
export type GetMySettingsQueryHookResult = ReturnType<
  typeof useGetMySettingsQuery
>;
export type GetMySettingsLazyQueryHookResult = ReturnType<
  typeof useGetMySettingsLazyQuery
>;
export type GetMySettingsQueryResult = Apollo.QueryResult<
  GetMySettingsQuery,
  GetMySettingsQueryVariables
>;
export const GetMeAsStreamerDocument = gql`
  query GetMeAsStreamer {
    meAsStreamer {
      ...StreamerDetailFields
    }
  }
  ${StreamerDetailFieldsFragmentDoc}
`;

/**
 * __useGetMeAsStreamerQuery__
 *
 * To run a query within a React component, call `useGetMeAsStreamerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeAsStreamerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeAsStreamerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeAsStreamerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMeAsStreamerQuery,
    GetMeAsStreamerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeAsStreamerQuery, GetMeAsStreamerQueryVariables>(
    GetMeAsStreamerDocument,
    options,
  );
}
export function useGetMeAsStreamerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMeAsStreamerQuery,
    GetMeAsStreamerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMeAsStreamerQuery,
    GetMeAsStreamerQueryVariables
  >(GetMeAsStreamerDocument, options);
}
export type GetMeAsStreamerQueryHookResult = ReturnType<
  typeof useGetMeAsStreamerQuery
>;
export type GetMeAsStreamerLazyQueryHookResult = ReturnType<
  typeof useGetMeAsStreamerLazyQuery
>;
export type GetMeAsStreamerQueryResult = Apollo.QueryResult<
  GetMeAsStreamerQuery,
  GetMeAsStreamerQueryVariables
>;
export const GetMyGamstopStatusDocument = gql`
  query GetMyGamstopStatus {
    myGamstopStatus
  }
`;

/**
 * __useGetMyGamstopStatusQuery__
 *
 * To run a query within a React component, call `useGetMyGamstopStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyGamstopStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyGamstopStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyGamstopStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyGamstopStatusQuery,
    GetMyGamstopStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyGamstopStatusQuery,
    GetMyGamstopStatusQueryVariables
  >(GetMyGamstopStatusDocument, options);
}
export function useGetMyGamstopStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyGamstopStatusQuery,
    GetMyGamstopStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyGamstopStatusQuery,
    GetMyGamstopStatusQueryVariables
  >(GetMyGamstopStatusDocument, options);
}
export type GetMyGamstopStatusQueryHookResult = ReturnType<
  typeof useGetMyGamstopStatusQuery
>;
export type GetMyGamstopStatusLazyQueryHookResult = ReturnType<
  typeof useGetMyGamstopStatusLazyQuery
>;
export type GetMyGamstopStatusQueryResult = Apollo.QueryResult<
  GetMyGamstopStatusQuery,
  GetMyGamstopStatusQueryVariables
>;
export const SearchMyBetHistoryDocument = gql`
  query SearchMyBetHistory($params: MyBetsHistoryParams!) {
    searchMyBetsHistory(params: $params) {
      bets {
        ...BetHistoryFields
      }
      nextKey
    }
  }
  ${BetHistoryFieldsFragmentDoc}
`;

/**
 * __useSearchMyBetHistoryQuery__
 *
 * To run a query within a React component, call `useSearchMyBetHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMyBetHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMyBetHistoryQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSearchMyBetHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchMyBetHistoryQuery,
    SearchMyBetHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchMyBetHistoryQuery,
    SearchMyBetHistoryQueryVariables
  >(SearchMyBetHistoryDocument, options);
}
export function useSearchMyBetHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchMyBetHistoryQuery,
    SearchMyBetHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchMyBetHistoryQuery,
    SearchMyBetHistoryQueryVariables
  >(SearchMyBetHistoryDocument, options);
}
export type SearchMyBetHistoryQueryHookResult = ReturnType<
  typeof useSearchMyBetHistoryQuery
>;
export type SearchMyBetHistoryLazyQueryHookResult = ReturnType<
  typeof useSearchMyBetHistoryLazyQuery
>;
export type SearchMyBetHistoryQueryResult = Apollo.QueryResult<
  SearchMyBetHistoryQuery,
  SearchMyBetHistoryQueryVariables
>;
export const GetMyOnfidoBreakdownReasoningDocument = gql`
  query GetMyOnfidoBreakdownReasoning {
    myOnfidoBreakdownReasoning {
      reason {
        document {
          ...DocumentField
        }
        facialSimilarityMotion {
          ...FacialSimilarityVideoField
        }
        facialSimilarityVideo {
          ...FacialSimilarityVideoField
        }
        proofOfAddress {
          ...ProofOfAddressField
        }
        identityEnhanced
        watchlistAml
      }
    }
  }
  ${DocumentFieldFragmentDoc}
  ${FacialSimilarityVideoFieldFragmentDoc}
  ${ProofOfAddressFieldFragmentDoc}
`;

/**
 * __useGetMyOnfidoBreakdownReasoningQuery__
 *
 * To run a query within a React component, call `useGetMyOnfidoBreakdownReasoningQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOnfidoBreakdownReasoningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOnfidoBreakdownReasoningQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyOnfidoBreakdownReasoningQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyOnfidoBreakdownReasoningQuery,
    GetMyOnfidoBreakdownReasoningQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyOnfidoBreakdownReasoningQuery,
    GetMyOnfidoBreakdownReasoningQueryVariables
  >(GetMyOnfidoBreakdownReasoningDocument, options);
}
export function useGetMyOnfidoBreakdownReasoningLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyOnfidoBreakdownReasoningQuery,
    GetMyOnfidoBreakdownReasoningQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyOnfidoBreakdownReasoningQuery,
    GetMyOnfidoBreakdownReasoningQueryVariables
  >(GetMyOnfidoBreakdownReasoningDocument, options);
}
export type GetMyOnfidoBreakdownReasoningQueryHookResult = ReturnType<
  typeof useGetMyOnfidoBreakdownReasoningQuery
>;
export type GetMyOnfidoBreakdownReasoningLazyQueryHookResult = ReturnType<
  typeof useGetMyOnfidoBreakdownReasoningLazyQuery
>;
export type GetMyOnfidoBreakdownReasoningQueryResult = Apollo.QueryResult<
  GetMyOnfidoBreakdownReasoningQuery,
  GetMyOnfidoBreakdownReasoningQueryVariables
>;
export const GetMyOnfidoBreakdownReasoningV2Document = gql`
  query GetMyOnfidoBreakdownReasoningV2 {
    myOnfidoBreakdownReasoningV2 {
      document
      face
      address
      aml
    }
  }
`;

/**
 * __useGetMyOnfidoBreakdownReasoningV2Query__
 *
 * To run a query within a React component, call `useGetMyOnfidoBreakdownReasoningV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOnfidoBreakdownReasoningV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOnfidoBreakdownReasoningV2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetMyOnfidoBreakdownReasoningV2Query(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyOnfidoBreakdownReasoningV2Query,
    GetMyOnfidoBreakdownReasoningV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyOnfidoBreakdownReasoningV2Query,
    GetMyOnfidoBreakdownReasoningV2QueryVariables
  >(GetMyOnfidoBreakdownReasoningV2Document, options);
}
export function useGetMyOnfidoBreakdownReasoningV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyOnfidoBreakdownReasoningV2Query,
    GetMyOnfidoBreakdownReasoningV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyOnfidoBreakdownReasoningV2Query,
    GetMyOnfidoBreakdownReasoningV2QueryVariables
  >(GetMyOnfidoBreakdownReasoningV2Document, options);
}
export type GetMyOnfidoBreakdownReasoningV2QueryHookResult = ReturnType<
  typeof useGetMyOnfidoBreakdownReasoningV2Query
>;
export type GetMyOnfidoBreakdownReasoningV2LazyQueryHookResult = ReturnType<
  typeof useGetMyOnfidoBreakdownReasoningV2LazyQuery
>;
export type GetMyOnfidoBreakdownReasoningV2QueryResult = Apollo.QueryResult<
  GetMyOnfidoBreakdownReasoningV2Query,
  GetMyOnfidoBreakdownReasoningV2QueryVariables
>;
export const GetProfileDocument = gql`
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      ...BetTargetProfileFields
    }
  }
  ${BetTargetProfileFieldsFragmentDoc}
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options,
  );
}
export function useGetProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options,
  );
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<
  typeof useGetProfileLazyQuery
>;
export type GetProfileQueryResult = Apollo.QueryResult<
  GetProfileQuery,
  GetProfileQueryVariables
>;
export const GetMyProfileImageUploadUrlDocument = gql`
  query GetMyProfileImageUploadUrl {
    myProfileImageUploadUrl {
      key
      url
    }
  }
`;

/**
 * __useGetMyProfileImageUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetMyProfileImageUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProfileImageUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProfileImageUploadUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProfileImageUploadUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyProfileImageUploadUrlQuery,
    GetMyProfileImageUploadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyProfileImageUploadUrlQuery,
    GetMyProfileImageUploadUrlQueryVariables
  >(GetMyProfileImageUploadUrlDocument, options);
}
export function useGetMyProfileImageUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyProfileImageUploadUrlQuery,
    GetMyProfileImageUploadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyProfileImageUploadUrlQuery,
    GetMyProfileImageUploadUrlQueryVariables
  >(GetMyProfileImageUploadUrlDocument, options);
}
export type GetMyProfileImageUploadUrlQueryHookResult = ReturnType<
  typeof useGetMyProfileImageUploadUrlQuery
>;
export type GetMyProfileImageUploadUrlLazyQueryHookResult = ReturnType<
  typeof useGetMyProfileImageUploadUrlLazyQuery
>;
export type GetMyProfileImageUploadUrlQueryResult = Apollo.QueryResult<
  GetMyProfileImageUploadUrlQuery,
  GetMyProfileImageUploadUrlQueryVariables
>;
export const GetGlobalSettingDocument = gql`
  query GetGlobalSetting($params: GlobalSettingParams!) {
    globalSettings(params: $params) {
      maxBetUU
      maxBetLimitPerBet
      maxBetLimitPerMarket
      minimumBetWager
      minDeposit
      maxDeposit
      minWithDraw
      maxWithDraw
      availableCountries
      countryCodes
      latestVersion
      myCountryCode
      bonusConfig {
        ...BonusConfigFields
      }
    }
  }
  ${BonusConfigFieldsFragmentDoc}
`;

/**
 * __useGetGlobalSettingQuery__
 *
 * To run a query within a React component, call `useGetGlobalSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalSettingQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGlobalSettingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGlobalSettingQuery,
    GetGlobalSettingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGlobalSettingQuery, GetGlobalSettingQueryVariables>(
    GetGlobalSettingDocument,
    options,
  );
}
export function useGetGlobalSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGlobalSettingQuery,
    GetGlobalSettingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGlobalSettingQuery,
    GetGlobalSettingQueryVariables
  >(GetGlobalSettingDocument, options);
}
export type GetGlobalSettingQueryHookResult = ReturnType<
  typeof useGetGlobalSettingQuery
>;
export type GetGlobalSettingLazyQueryHookResult = ReturnType<
  typeof useGetGlobalSettingLazyQuery
>;
export type GetGlobalSettingQueryResult = Apollo.QueryResult<
  GetGlobalSettingQuery,
  GetGlobalSettingQueryVariables
>;
export const CheckIsValidUkPostcodeDocument = gql`
  query checkIsValidUkPostcode($input: CheckIsValidPostCodeInput!) {
    checkIsValidUkPostcode(input: $input)
  }
`;

/**
 * __useCheckIsValidUkPostcodeQuery__
 *
 * To run a query within a React component, call `useCheckIsValidUkPostcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIsValidUkPostcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIsValidUkPostcodeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckIsValidUkPostcodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckIsValidUkPostcodeQuery,
    CheckIsValidUkPostcodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckIsValidUkPostcodeQuery,
    CheckIsValidUkPostcodeQueryVariables
  >(CheckIsValidUkPostcodeDocument, options);
}
export function useCheckIsValidUkPostcodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckIsValidUkPostcodeQuery,
    CheckIsValidUkPostcodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckIsValidUkPostcodeQuery,
    CheckIsValidUkPostcodeQueryVariables
  >(CheckIsValidUkPostcodeDocument, options);
}
export type CheckIsValidUkPostcodeQueryHookResult = ReturnType<
  typeof useCheckIsValidUkPostcodeQuery
>;
export type CheckIsValidUkPostcodeLazyQueryHookResult = ReturnType<
  typeof useCheckIsValidUkPostcodeLazyQuery
>;
export type CheckIsValidUkPostcodeQueryResult = Apollo.QueryResult<
  CheckIsValidUkPostcodeQuery,
  CheckIsValidUkPostcodeQueryVariables
>;
export const GetMyIntercomUserHashDocument = gql`
  query GetMyIntercomUserHash($platform: IntercomPlatform!) {
    myIntercomUserHash(platform: $platform)
  }
`;

/**
 * __useGetMyIntercomUserHashQuery__
 *
 * To run a query within a React component, call `useGetMyIntercomUserHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyIntercomUserHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyIntercomUserHashQuery({
 *   variables: {
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useGetMyIntercomUserHashQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyIntercomUserHashQuery,
    GetMyIntercomUserHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyIntercomUserHashQuery,
    GetMyIntercomUserHashQueryVariables
  >(GetMyIntercomUserHashDocument, options);
}
export function useGetMyIntercomUserHashLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyIntercomUserHashQuery,
    GetMyIntercomUserHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyIntercomUserHashQuery,
    GetMyIntercomUserHashQueryVariables
  >(GetMyIntercomUserHashDocument, options);
}
export type GetMyIntercomUserHashQueryHookResult = ReturnType<
  typeof useGetMyIntercomUserHashQuery
>;
export type GetMyIntercomUserHashLazyQueryHookResult = ReturnType<
  typeof useGetMyIntercomUserHashLazyQuery
>;
export type GetMyIntercomUserHashQueryResult = Apollo.QueryResult<
  GetMyIntercomUserHashQuery,
  GetMyIntercomUserHashQueryVariables
>;
export const GetIsReviewerPhoneNumberDocument = gql`
  query GetIsReviewerPhoneNumber($phoneNumber: String!) {
    checkIsReviewerPhoneNumber(phoneNumber: $phoneNumber)
  }
`;

/**
 * __useGetIsReviewerPhoneNumberQuery__
 *
 * To run a query within a React component, call `useGetIsReviewerPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsReviewerPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsReviewerPhoneNumberQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useGetIsReviewerPhoneNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIsReviewerPhoneNumberQuery,
    GetIsReviewerPhoneNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIsReviewerPhoneNumberQuery,
    GetIsReviewerPhoneNumberQueryVariables
  >(GetIsReviewerPhoneNumberDocument, options);
}
export function useGetIsReviewerPhoneNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIsReviewerPhoneNumberQuery,
    GetIsReviewerPhoneNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIsReviewerPhoneNumberQuery,
    GetIsReviewerPhoneNumberQueryVariables
  >(GetIsReviewerPhoneNumberDocument, options);
}
export type GetIsReviewerPhoneNumberQueryHookResult = ReturnType<
  typeof useGetIsReviewerPhoneNumberQuery
>;
export type GetIsReviewerPhoneNumberLazyQueryHookResult = ReturnType<
  typeof useGetIsReviewerPhoneNumberLazyQuery
>;
export type GetIsReviewerPhoneNumberQueryResult = Apollo.QueryResult<
  GetIsReviewerPhoneNumberQuery,
  GetIsReviewerPhoneNumberQueryVariables
>;
export const GetReviewerTemporaryCodeDocument = gql`
  query GetReviewerTemporaryCode($phoneNumber: String!) {
    getReviewerTemporaryCode(phoneNumber: $phoneNumber)
  }
`;

/**
 * __useGetReviewerTemporaryCodeQuery__
 *
 * To run a query within a React component, call `useGetReviewerTemporaryCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReviewerTemporaryCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReviewerTemporaryCodeQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useGetReviewerTemporaryCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReviewerTemporaryCodeQuery,
    GetReviewerTemporaryCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetReviewerTemporaryCodeQuery,
    GetReviewerTemporaryCodeQueryVariables
  >(GetReviewerTemporaryCodeDocument, options);
}
export function useGetReviewerTemporaryCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReviewerTemporaryCodeQuery,
    GetReviewerTemporaryCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReviewerTemporaryCodeQuery,
    GetReviewerTemporaryCodeQueryVariables
  >(GetReviewerTemporaryCodeDocument, options);
}
export type GetReviewerTemporaryCodeQueryHookResult = ReturnType<
  typeof useGetReviewerTemporaryCodeQuery
>;
export type GetReviewerTemporaryCodeLazyQueryHookResult = ReturnType<
  typeof useGetReviewerTemporaryCodeLazyQuery
>;
export type GetReviewerTemporaryCodeQueryResult = Apollo.QueryResult<
  GetReviewerTemporaryCodeQuery,
  GetReviewerTemporaryCodeQueryVariables
>;
export const GetTermAndConditionEnabledDocument = gql`
  query GetTermAndConditionEnabled($params: GetTermAndConditionEnabledParams!) {
    getTermAndConditionEnabled(params: $params) {
      termAndCondition {
        ...TermAndConditionFields
      }
    }
  }
  ${TermAndConditionFieldsFragmentDoc}
`;

/**
 * __useGetTermAndConditionEnabledQuery__
 *
 * To run a query within a React component, call `useGetTermAndConditionEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermAndConditionEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermAndConditionEnabledQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetTermAndConditionEnabledQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTermAndConditionEnabledQuery,
    GetTermAndConditionEnabledQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTermAndConditionEnabledQuery,
    GetTermAndConditionEnabledQueryVariables
  >(GetTermAndConditionEnabledDocument, options);
}
export function useGetTermAndConditionEnabledLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTermAndConditionEnabledQuery,
    GetTermAndConditionEnabledQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTermAndConditionEnabledQuery,
    GetTermAndConditionEnabledQueryVariables
  >(GetTermAndConditionEnabledDocument, options);
}
export type GetTermAndConditionEnabledQueryHookResult = ReturnType<
  typeof useGetTermAndConditionEnabledQuery
>;
export type GetTermAndConditionEnabledLazyQueryHookResult = ReturnType<
  typeof useGetTermAndConditionEnabledLazyQuery
>;
export type GetTermAndConditionEnabledQueryResult = Apollo.QueryResult<
  GetTermAndConditionEnabledQuery,
  GetTermAndConditionEnabledQueryVariables
>;
export const SearchMyWalletHistoryDocument = gql`
  query SearchMyWalletHistory($params: MyWalletsHistoryParams!) {
    searchMyWalletHistory(params: $params) {
      history {
        ...WalletHistoryFields
        ...MatchWalletHistoryFields
        ... on BalanceWalletHistory {
          id
          text
          transactionText
        }
      }
      nextKey
    }
  }
  ${WalletHistoryFieldsFragmentDoc}
  ${MatchWalletHistoryFieldsFragmentDoc}
`;

/**
 * __useSearchMyWalletHistoryQuery__
 *
 * To run a query within a React component, call `useSearchMyWalletHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMyWalletHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMyWalletHistoryQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSearchMyWalletHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchMyWalletHistoryQuery,
    SearchMyWalletHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchMyWalletHistoryQuery,
    SearchMyWalletHistoryQueryVariables
  >(SearchMyWalletHistoryDocument, options);
}
export function useSearchMyWalletHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchMyWalletHistoryQuery,
    SearchMyWalletHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchMyWalletHistoryQuery,
    SearchMyWalletHistoryQueryVariables
  >(SearchMyWalletHistoryDocument, options);
}
export type SearchMyWalletHistoryQueryHookResult = ReturnType<
  typeof useSearchMyWalletHistoryQuery
>;
export type SearchMyWalletHistoryLazyQueryHookResult = ReturnType<
  typeof useSearchMyWalletHistoryLazyQuery
>;
export type SearchMyWalletHistoryQueryResult = Apollo.QueryResult<
  SearchMyWalletHistoryQuery,
  SearchMyWalletHistoryQueryVariables
>;
export const GetMyTransactionSummaryDocument = gql`
  query GetMyTransactionSummary($params: MyTransactionSummaryParams!) {
    getMyTransactionSummary(params: $params) {
      totalDeposits
      totalWithdrawals
      totalCashReturns
      totalCashBets
      totalBonusReturns
      totalBonusBets
      nextKey
    }
  }
`;

/**
 * __useGetMyTransactionSummaryQuery__
 *
 * To run a query within a React component, call `useGetMyTransactionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyTransactionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyTransactionSummaryQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetMyTransactionSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyTransactionSummaryQuery,
    GetMyTransactionSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyTransactionSummaryQuery,
    GetMyTransactionSummaryQueryVariables
  >(GetMyTransactionSummaryDocument, options);
}
export function useGetMyTransactionSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyTransactionSummaryQuery,
    GetMyTransactionSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyTransactionSummaryQuery,
    GetMyTransactionSummaryQueryVariables
  >(GetMyTransactionSummaryDocument, options);
}
export type GetMyTransactionSummaryQueryHookResult = ReturnType<
  typeof useGetMyTransactionSummaryQuery
>;
export type GetMyTransactionSummaryLazyQueryHookResult = ReturnType<
  typeof useGetMyTransactionSummaryLazyQuery
>;
export type GetMyTransactionSummaryQueryResult = Apollo.QueryResult<
  GetMyTransactionSummaryQuery,
  GetMyTransactionSummaryQueryVariables
>;
export const GetMyNetDepositsDocument = gql`
  query GetMyNetDeposits($params: MyNetDepositParams!) {
    myNetDeposit(params: $params) {
      netDeposit
      netDepositsBySource {
        netDeposit
        source
        totalDeposit
        totalWithdrawal
        isAvailable
      }
      totalDeposit
      totalWithdrawal
    }
  }
`;

/**
 * __useGetMyNetDepositsQuery__
 *
 * To run a query within a React component, call `useGetMyNetDepositsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyNetDepositsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyNetDepositsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetMyNetDepositsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyNetDepositsQuery,
    GetMyNetDepositsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyNetDepositsQuery, GetMyNetDepositsQueryVariables>(
    GetMyNetDepositsDocument,
    options,
  );
}
export function useGetMyNetDepositsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyNetDepositsQuery,
    GetMyNetDepositsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyNetDepositsQuery,
    GetMyNetDepositsQueryVariables
  >(GetMyNetDepositsDocument, options);
}
export type GetMyNetDepositsQueryHookResult = ReturnType<
  typeof useGetMyNetDepositsQuery
>;
export type GetMyNetDepositsLazyQueryHookResult = ReturnType<
  typeof useGetMyNetDepositsLazyQuery
>;
export type GetMyNetDepositsQueryResult = Apollo.QueryResult<
  GetMyNetDepositsQuery,
  GetMyNetDepositsQueryVariables
>;
export const GetEveryMatrixPendingWithdrawalTransactionCodeDocument = gql`
  query GetEveryMatrixPendingWithdrawalTransactionCode {
    everyMatrixGetPendingWithdrawalTransactionCode {
      amount
      createdAt
      transactionCode
    }
  }
`;

/**
 * __useGetEveryMatrixPendingWithdrawalTransactionCodeQuery__
 *
 * To run a query within a React component, call `useGetEveryMatrixPendingWithdrawalTransactionCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEveryMatrixPendingWithdrawalTransactionCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEveryMatrixPendingWithdrawalTransactionCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEveryMatrixPendingWithdrawalTransactionCodeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEveryMatrixPendingWithdrawalTransactionCodeQuery,
    GetEveryMatrixPendingWithdrawalTransactionCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEveryMatrixPendingWithdrawalTransactionCodeQuery,
    GetEveryMatrixPendingWithdrawalTransactionCodeQueryVariables
  >(GetEveryMatrixPendingWithdrawalTransactionCodeDocument, options);
}
export function useGetEveryMatrixPendingWithdrawalTransactionCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEveryMatrixPendingWithdrawalTransactionCodeQuery,
    GetEveryMatrixPendingWithdrawalTransactionCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEveryMatrixPendingWithdrawalTransactionCodeQuery,
    GetEveryMatrixPendingWithdrawalTransactionCodeQueryVariables
  >(GetEveryMatrixPendingWithdrawalTransactionCodeDocument, options);
}
export type GetEveryMatrixPendingWithdrawalTransactionCodeQueryHookResult =
  ReturnType<typeof useGetEveryMatrixPendingWithdrawalTransactionCodeQuery>;
export type GetEveryMatrixPendingWithdrawalTransactionCodeLazyQueryHookResult =
  ReturnType<typeof useGetEveryMatrixPendingWithdrawalTransactionCodeLazyQuery>;
export type GetEveryMatrixPendingWithdrawalTransactionCodeQueryResult =
  Apollo.QueryResult<
    GetEveryMatrixPendingWithdrawalTransactionCodeQuery,
    GetEveryMatrixPendingWithdrawalTransactionCodeQueryVariables
  >;
export const OnStreamingContentChatMessageUpdatedDocument = gql`
  subscription OnStreamingContentChatMessageUpdated(
    $matchId: ID!
    $contentId: ID
  ) {
    streamingContentChatMessageUpdated(
      contentId: $contentId
      matchId: $matchId
    ) {
      ...StreamingContentChatMessageFields
    }
  }
  ${StreamingContentChatMessageFieldsFragmentDoc}
`;

/**
 * __useOnStreamingContentChatMessageUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnStreamingContentChatMessageUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnStreamingContentChatMessageUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnStreamingContentChatMessageUpdatedSubscription({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useOnStreamingContentChatMessageUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnStreamingContentChatMessageUpdatedSubscription,
    OnStreamingContentChatMessageUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnStreamingContentChatMessageUpdatedSubscription,
    OnStreamingContentChatMessageUpdatedSubscriptionVariables
  >(OnStreamingContentChatMessageUpdatedDocument, options);
}
export type OnStreamingContentChatMessageUpdatedSubscriptionHookResult =
  ReturnType<typeof useOnStreamingContentChatMessageUpdatedSubscription>;
export type OnStreamingContentChatMessageUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<OnStreamingContentChatMessageUpdatedSubscription>;
export const OnLiveStreamingContentUpdatedDocument = gql`
  subscription OnLiveStreamingContentUpdated {
    liveStreamingContentUpdated {
      ...StreamingContentFields
    }
  }
  ${StreamingContentFieldsFragmentDoc}
`;

/**
 * __useOnLiveStreamingContentUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnLiveStreamingContentUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnLiveStreamingContentUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnLiveStreamingContentUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnLiveStreamingContentUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnLiveStreamingContentUpdatedSubscription,
    OnLiveStreamingContentUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnLiveStreamingContentUpdatedSubscription,
    OnLiveStreamingContentUpdatedSubscriptionVariables
  >(OnLiveStreamingContentUpdatedDocument, options);
}
export type OnLiveStreamingContentUpdatedSubscriptionHookResult = ReturnType<
  typeof useOnLiveStreamingContentUpdatedSubscription
>;
export type OnLiveStreamingContentUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<OnLiveStreamingContentUpdatedSubscription>;
export const OnInplayMarketUpdatedDocument = gql`
  subscription OnInplayMarketUpdated($matchId: ID) {
    inplayMarketsUpdated(matchId: $matchId) {
      matchId
      markets {
        ...InplayMarketWithoutTargetProfileFields
      }
    }
  }
  ${InplayMarketWithoutTargetProfileFieldsFragmentDoc}
`;

/**
 * __useOnInplayMarketUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnInplayMarketUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnInplayMarketUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnInplayMarketUpdatedSubscription({
 *   variables: {
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useOnInplayMarketUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnInplayMarketUpdatedSubscription,
    OnInplayMarketUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnInplayMarketUpdatedSubscription,
    OnInplayMarketUpdatedSubscriptionVariables
  >(OnInplayMarketUpdatedDocument, options);
}
export type OnInplayMarketUpdatedSubscriptionHookResult = ReturnType<
  typeof useOnInplayMarketUpdatedSubscription
>;
export type OnInplayMarketUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<OnInplayMarketUpdatedSubscription>;
export const OnInplayMarketUpdatedV2Document = gql`
  subscription OnInplayMarketUpdatedV2($matchId: ID) {
    inplayMarketsUpdatedV2(matchId: $matchId) {
      matchId
      marketGroups {
        groupTag {
          ...GroupTagFields
        }
        fourwDao
        sumoDao
        yadokariDao
        markets {
          ...InplayMarketWithoutTargetProfileFields
        }
      }
    }
  }
  ${GroupTagFieldsFragmentDoc}
  ${InplayMarketWithoutTargetProfileFieldsFragmentDoc}
`;

/**
 * __useOnInplayMarketUpdatedV2Subscription__
 *
 * To run a query within a React component, call `useOnInplayMarketUpdatedV2Subscription` and pass it any options that fit your needs.
 * When your component renders, `useOnInplayMarketUpdatedV2Subscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnInplayMarketUpdatedV2Subscription({
 *   variables: {
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useOnInplayMarketUpdatedV2Subscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnInplayMarketUpdatedV2Subscription,
    OnInplayMarketUpdatedV2SubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnInplayMarketUpdatedV2Subscription,
    OnInplayMarketUpdatedV2SubscriptionVariables
  >(OnInplayMarketUpdatedV2Document, options);
}
export type OnInplayMarketUpdatedV2SubscriptionHookResult = ReturnType<
  typeof useOnInplayMarketUpdatedV2Subscription
>;
export type OnInplayMarketUpdatedV2SubscriptionResult =
  Apollo.SubscriptionResult<OnInplayMarketUpdatedV2Subscription>;
export const OnMatchUpdatedDocument = gql`
  subscription OnMatchUpdated {
    matchesUpdated {
      matches {
        finishAt
        id
        isCanceled
        isLive
        isOpen
        ivsStatus
        liveAt
        liveEndAt
        liveStartedAt
        liveStatus
        mediaSourceService
        mediaUrls {
          ...MatchMediaUrlFields
        }
        simplifiedSummary {
          ...SimplifiedMatchSummaryFields
        }
        shortTitle {
          ...LocalizedStringFields
        }
        title {
          ...LocalizedStringFields
        }
      }
    }
  }
  ${MatchMediaUrlFieldsFragmentDoc}
  ${SimplifiedMatchSummaryFieldsFragmentDoc}
  ${LocalizedStringFieldsFragmentDoc}
`;

/**
 * __useOnMatchUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnMatchUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnMatchUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnMatchUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnMatchUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnMatchUpdatedSubscription,
    OnMatchUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnMatchUpdatedSubscription,
    OnMatchUpdatedSubscriptionVariables
  >(OnMatchUpdatedDocument, options);
}
export type OnMatchUpdatedSubscriptionHookResult = ReturnType<
  typeof useOnMatchUpdatedSubscription
>;
export type OnMatchUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<OnMatchUpdatedSubscription>;
export const OnVerificationStatusUpdatedDocument = gql`
  subscription onVerificationStatusUpdated($id: ID!) {
    verificationStatusUpdated(id: $id) {
      id
      status
      statusFirstStep
      statusSecondStep
      reportStatus {
        document
        facial_similarity_motion
        facial_similarity_video
        identity_enhanced
        proof_of_address
        watch_list
      }
      submittedAt
      completedAt
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useOnVerificationStatusUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnVerificationStatusUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnVerificationStatusUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnVerificationStatusUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnVerificationStatusUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnVerificationStatusUpdatedSubscription,
    OnVerificationStatusUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnVerificationStatusUpdatedSubscription,
    OnVerificationStatusUpdatedSubscriptionVariables
  >(OnVerificationStatusUpdatedDocument, options);
}
export type OnVerificationStatusUpdatedSubscriptionHookResult = ReturnType<
  typeof useOnVerificationStatusUpdatedSubscription
>;
export type OnVerificationStatusUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<OnVerificationStatusUpdatedSubscription>;
export const OnWalletUpdatedDocument = gql`
  subscription onWalletUpdated($id: ID!) {
    walletUpdated(id: $id) {
      id
      updatedAt
    }
  }
`;

/**
 * __useOnWalletUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnWalletUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnWalletUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnWalletUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnWalletUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnWalletUpdatedSubscription,
    OnWalletUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnWalletUpdatedSubscription,
    OnWalletUpdatedSubscriptionVariables
  >(OnWalletUpdatedDocument, options);
}
export type OnWalletUpdatedSubscriptionHookResult = ReturnType<
  typeof useOnWalletUpdatedSubscription
>;
export type OnWalletUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<OnWalletUpdatedSubscription>;
