import isString from 'lodash/isString';
import { RegisterParams, validateRegisterParams } from '../../types/formParams';
import isValidEmail from '../form/isValidEmail';
import { DateTime } from 'luxon';
import JungleAppError, { JungleAppErrorCode } from '../core/JungleAppError';
import {
  Maybe,
  UserCountryCodeIso1Alpha2,
} from '../../graphql/__generated__/graphqlApi';
import { TCustomFunction } from '../context/LangContext';

export const countries = [
  {
    key: '+44',
    caption: 'United Kingdom',
    countryCode: UserCountryCodeIso1Alpha2.Gb,
  },
  {
    key: '+353',
    caption: 'Ireland',
    countryCode: UserCountryCodeIso1Alpha2.Ie,
  },
];

export const getAvailableCountries = (
  country: string,
): UserCountryCodeIso1Alpha2 => {
  if (country === countryCode.Ireland) {
    return UserCountryCodeIso1Alpha2.Ie;
  }
  if (country === countryCode.UnitedState) {
    return UserCountryCodeIso1Alpha2.Us;
  }
  return UserCountryCodeIso1Alpha2.Gb;
};

export const countryCode = {
  UnitedKingdom: '+44',
  UnitedState: '+1',
  Ireland: '+353',
};

export const getCountryCode = (country: string | undefined) => {
  const countryItem = countries.find(item => item.countryCode === country);
  if (!countryItem) {
    return countryCode.UnitedKingdom;
  }
  return countryItem.key;
};

export const getLabelPostalCode = (
  country: string | undefined,
  t: TCustomFunction,
) => {
  if (country === countryCode.UnitedState) return t('formPropsZipCode');
  if (country === countryCode.Ireland) return t('formPropsEirCode');
  return t('formPropsPostalCode');
};

export const getLabelCountry = (country?: Maybe<UserCountryCodeIso1Alpha2>) => {
  if (country === UserCountryCodeIso1Alpha2.Gb) return 'United Kingdom';
  if (country === UserCountryCodeIso1Alpha2.Us) return 'United States';
  if (country === UserCountryCodeIso1Alpha2.Ie) return 'Ireland';
  if (country === UserCountryCodeIso1Alpha2.Il) return 'Israel';
  if (country === UserCountryCodeIso1Alpha2.Ua) return 'Ukraine';
  if (country === UserCountryCodeIso1Alpha2.Jp) return 'Japan';
  if (country === UserCountryCodeIso1Alpha2.Vn) return 'Vietnam';
  return '';
};

export const stateProvinceUS = [
  { key: 'AK', caption: 'AK' },
  { key: 'AL', caption: 'AL' },
  { key: 'AZ', caption: 'AZ' },
  { key: 'AR', caption: 'AR' },
  { key: 'CA', caption: 'CA' },
  { key: 'CO', caption: 'CO' },
  { key: 'CT', caption: 'CT' },
  { key: 'DE', caption: 'DE' },
  { key: 'DC', caption: 'DC' },
  { key: 'FL', caption: 'FL' },
  { key: 'GA', caption: 'GA' },
  { key: 'GU', caption: 'GU' },
  { key: 'HI', caption: 'HI' },
  { key: 'ID', caption: 'ID' },
  { key: 'IL', caption: 'IL' },
  { key: 'IN', caption: 'IN' },
  { key: 'IA', caption: 'IA' },
  { key: 'KS', caption: 'KS' },
  { key: 'KY', caption: 'KY' },
  { key: 'LA', caption: 'LA' },
  { key: 'ME', caption: 'ME' },
  { key: 'MH', caption: 'MH' },
  { key: 'MD', caption: 'MD' },
  { key: 'MA', caption: 'MA' },
  { key: 'MI', caption: 'MI' },
  { key: 'MN', caption: 'MN' },
  { key: 'MS', caption: 'MS' },
  { key: 'MO', caption: 'MO' },
  { key: 'MT', caption: 'MT' },
  { key: 'NE', caption: 'NE' },
  { key: 'NV', caption: 'NV' },
  { key: 'NH', caption: 'NH' },
  { key: 'NJ', caption: 'NJ' },
  { key: 'NM', caption: 'NM' },
  { key: 'NY', caption: 'NY' },
  { key: 'NC', caption: 'NC' },
  { key: 'ND', caption: 'ND' },
  { key: 'OH', caption: 'OH' },
  { key: 'OK', caption: 'OK' },
  { key: 'OR', caption: 'OR' },
  { key: 'PW', caption: 'PW' },
  { key: 'PA', caption: 'PA' },
  { key: 'PR', caption: 'PR' },
  { key: 'RI', caption: 'RI' },
  { key: 'SC', caption: 'SC' },
  { key: 'SD', caption: 'SD' },
  { key: 'TN', caption: 'TN' },
  { key: 'TX', caption: 'TX' },
  { key: 'UT', caption: 'UT' },
  { key: 'VI', caption: 'VI' },
  { key: 'VT', caption: 'VT' },
  { key: 'VA', caption: 'VA' },
  { key: 'WA', caption: 'WA' },
  { key: 'WV', caption: 'WV' },
  { key: 'WI', caption: 'WI' },
  { key: 'WY', caption: 'WY' },
  { key: 'ZZ', caption: 'ZZ' },
];

export const isValidStreetAddress = (address?: string) => {
  if (!isString(address)) {
    return false;
  }
  const streetAddressPattern = /^[a-zA-Z0-9\s,'.-]*$/;
  return (
    streetAddressPattern.test(address) &&
    address.length >= 5 &&
    address.length <= 100
  );
};

export const validateLastName = (input: string) => {
  const text = input.replace(/\s/g, '');
  return text.length === 1;
};
export const validateParams = (input?: string) => {
  return !input || input.trim() === '';
};

export const isValidCityAddress = (cityAddress: string) => {
  const cityAddressPattern = /^[a-zA-Z\s-]*$/;
  return (
    cityAddressPattern.test(cityAddress) &&
    cityAddress.length >= 2 &&
    cityAddress.length <= 100
  );
};

export const checkUserAge = (dob: string, t: TCustomFunction) => {
  const age = DateTime.fromFormat(dob, 'yyyy-MM-dd').diffNow('years').years;
  if (Math.floor(Math.abs(age)) >= 18) {
    return undefined;
  }
  return t('formErrorsInvalidDob');
};

type KeyType = Omit<RegisterParams, 'subscribeNewsletter'>;

export const checkValidateField = (
  params: RegisterParams,
  t: TCustomFunction,
  paramKey?: keyof RegisterParams,
) => {
  const stringErr: {
    key: string;
    value?: string;
  } = {
    key: '',
    value: '',
  };
  let isAllValid = true;
  const keys: (keyof KeyType)[] = [
    'firstName',
    'lastName',
    'dob',
    'email',
    'postalCode',
    'addressLine1',
    'city',
  ];
  keys.forEach(key => {
    if ((!paramKey || paramKey === key) && validateParams(params[key])) {
      stringErr.key = key;
      stringErr.value = t('formErrorsRequired');
      isAllValid = false;
    }
  });
  if (
    (!paramKey || paramKey === 'email') &&
    params.email &&
    !isValidEmail(params.email)
  ) {
    stringErr.key = 'email';
    stringErr.value = t('formErrorsInvalidEmail', {
      defaultValue: 'Enter valid email',
    });
    isAllValid = false;
  }
  const keysAddress: (keyof Pick<
    RegisterParams,
    'addressLine1' | 'addressLine2'
  >)[] = ['addressLine1', 'addressLine2'];
  keysAddress.forEach(key => {
    if (
      paramKey === key &&
      !!params[key] &&
      !isValidStreetAddress(params[key])
    ) {
      stringErr.key = key;
      stringErr.value = t('formErrorsInvalidAddress');
      isAllValid = false;
    }
  });
  if (paramKey === 'city' && params.city && !isValidCityAddress(params.city)) {
    stringErr.key = 'city';
    stringErr.value = t('formErrorsInvalidCity');
    isAllValid = false;
  }
  if (
    paramKey === 'lastName' &&
    params.lastName &&
    validateLastName(params.lastName)
  ) {
    stringErr.key = 'lastName';
    stringErr.value = t('formErrorsInvalidText');
    isAllValid = false;
  }
  return {
    isAllValid,
    stringErr,
  };
};

export const checkFilledValidation = (
  params: RegisterParams,
  errors: { [Key in keyof RegisterParams]?: string },
) => {
  const isValidAddressLine2 =
    !params.addressLine2 || isValidStreetAddress(params.addressLine2);
  return (
    !!params.email &&
    !!params.addressLine1 &&
    !!params.city &&
    isValidEmail(params.email) &&
    isValidStreetAddress(params.addressLine1) &&
    isValidAddressLine2 &&
    isValidCityAddress(params.city) &&
    validateRegisterParams(params) &&
    Object.values(errors).reduce((acc, next) => acc && next === undefined, true)
  );
};

export interface ActionParams {
  onShowGoogleForm: () => void;
}

export const composeErrorMessages = (
  t: TCustomFunction,
  jungleAppError: JungleAppError,
  actionParams?: ActionParams,
  isSignUp?: boolean,
): {
  title: string;
  message: string | undefined;
  save: string | undefined;
  cancel: string | undefined;
  action: (() => void) | undefined;
} => {
  let title: string;
  let message: string | undefined;
  let save: string | undefined;
  let cancel: string | undefined;
  let action: (() => void) | undefined;

  switch (jungleAppError.errorType) {
    case JungleAppErrorCode.GENERAL__USER_IS_UNDER_GAMBLING_AGES:
      title = t('signUpUserIsUnderGamblingAges');
      break;
    case JungleAppErrorCode.API__LIMITATION__BEFORE_SELF_EXCLUSION_PERIOD:
      title = t('signUpSelfExclusionBeforeEndsAtTitle');
      message = t('signUpSelfExclusionBeforeEndsAtMessage');
      break;
    case JungleAppErrorCode.API__LIMITATION__AFTER_SELF_EXCLUSION_PERIOD:
      title = t('signUpSelfExclusionAfterEndsAtTitle');
      message = t('signUpSelfExclusionAfterEndsAtMessage');
      save = t('signUpSelfExclusionAfterEndsAtSave');
      cancel = t('signUpSelfExclusionAfterEndsAtCancel');
      action = actionParams?.onShowGoogleForm;
      break;
    case JungleAppErrorCode.API__LIMITATION__UNDER_GAMSTOP_EXCLUSION_PERIOD:
      title = t('signUpGamstopExclusionTitle');
      message = t('signUpGamstopExclusionMessage');
      break;
    case JungleAppErrorCode.API__AUTH__INVALID_REFERRAL_CODE:
      title = t('signUpInvalidReferralCodeTitle');
      message = t('signUpInvalidReferralCodeMessage');
      break;
    case JungleAppErrorCode.API__AUTH__INVALID_REFERRAL_CODE_DIFFERENT_COUNTRY:
      title = t('signUpInvalidReferralCodeTitle');
      message = t('signUpInvalidReferralCodeDifferentCountryMessage');
      break;
    case JungleAppErrorCode.API__AUTH__ACCOUNT_HAS_ALREADY_BEEN_CREATED:
      title = isSignUp
        ? t('signUpSameNamePostalCodeDateOfBirthTitle')
        : t('editAccountDuplicateAccountTitle');
      message = isSignUp
        ? t('signUpSameNamePostalCodeDateOfBirthMessage')
        : t('editAccountDuplicateAccountMessage');
      break;
    case JungleAppErrorCode.API__UNHANDLED_BETS_CASH:
      title = t('closeAccountUnableCloseTitle');
      message = t('closeAccountUnableCloseMessage');
      break;
    case JungleAppErrorCode.API__BLOCKED_VPN:
    case JungleAppErrorCode.API__INVALID_ACCESS:
      title = t('blockedVpnTitle');
      message = t('blockedVpnMessage');
      break;
    default:
      title = t('unknownTitle');
      message = jungleAppError.message ?? jungleAppError.errorType;
      break;
  }

  return { title: title, message: message, save, cancel, action };
};
