import {
  Maybe,
  RealityCheckFrequency,
  RealityCheckFrequency as GqlTimeLimitationTermType,
} from '../../graphql/__generated__/graphqlApi';
import { TimeLimitationTerm } from '../../types/preferences';

export const timeRealityCheckToNumber = {
  FREQUENCY_10_MINUTES: 600,
  FREQUENCY_30_MINUTES: 1800,
  FREQUENCY_1_HOURS: 3600,
};

export const sessionExpireTimeChecks = 1800;

export const toTimeLimitationTermType = (
  localType: Maybe<RealityCheckFrequency> | undefined,
): TimeLimitationTerm | undefined => {
  return localType === GqlTimeLimitationTermType.Frequency_10Minutes
    ? '10mins'
    : localType === GqlTimeLimitationTermType.Frequency_30Minutes
    ? '30mins'
    : localType === GqlTimeLimitationTermType.Frequency_1Hours
    ? '1hour'
    : undefined;
};

export const getTimerRealityChecks = (countTime: number) => {
  const hours = Math.floor(countTime / 3600);
  const minutes = Math.floor((countTime - hours * 3600) / 60);
  const seconds = countTime - hours * 3600 - minutes * 60;

  let hoursDisplay = hours.toString();
  let minutesDisplay = minutes.toString();
  let secondsDisplay = seconds.toString();

  if (hours < 10) {
    hoursDisplay = '0' + hours;
  }
  if (minutes < 10) {
    minutesDisplay = '0' + minutes;
  }
  if (seconds < 10) {
    secondsDisplay = '0' + seconds;
  }
  if (hours > 0)
    return hoursDisplay + ':' + minutesDisplay + ':' + secondsDisplay;
  if (minutes > 0) return '00' + ':' + minutesDisplay + ':' + secondsDisplay;
  return '00' + ':' + '00' + ':' + secondsDisplay;
};
